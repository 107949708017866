import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  CanvasSvg,
  CircularSVG,
  CircularTrailSVG,
  Percentage,
} from './circular-progress.style';
import { Props } from './circular-progress.type';

export default function CircularProgress({ progress }: Props) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);
  const [parentWidth, setParenteWidth] = useState(60);

  const temp = progress / 2;

  useEffect(() => {
    setTimeout(() => {
      const inter = setInterval(() => {
        setValue((v) => {
          if (v < progress) {
            return v + 1;
          } else if (v > progress){
            return v - 1;
          } else {
            clearInterval(inter);
            return v;
          }
        });
      }, temp);
    }, 800);
  }, [progress]);

  useLayoutEffect(() => {
    setParenteWidth((v) => {
      if (parentRef && parentRef.current) return parentRef.current.offsetWidth;
      else return v;
    });
  }, [parentRef]);

  return (
    <div
      ref={parentRef}
      style={{ width: '100%', height:`fit-content`, background: 'transparent', position: 'relative' }}
    >
      <CanvasSvg width={parentWidth} height={parentWidth} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="gradientBackground">
            <stop offset="0%" stopColor="#48AF77" />
            <stop offset="100%" stopColor="#2E80C3" />
          </linearGradient>
        </defs>
        <CircularTrailSVG widthRef={parentWidth} />
        <CircularSVG widthRef={parentWidth} completed={value}  stroke={'url(#gradientBackground)'}></CircularSVG>
      </CanvasSvg>
      <Percentage>{value}%</Percentage>
    </div>
  );
}
