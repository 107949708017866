import { ReactNode } from 'react';
import {IconSubTitleStyle } from '../icon-subtitle/icon-subtitle.style';

type Props = {
  children: ReactNode
}

export default function SubtitleOne({children}: Props){
  return(
    <IconSubTitleStyle>
      {children}
    </IconSubTitleStyle>
  )
}
