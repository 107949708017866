export const formatoData = (data: string) => {
  const [date, ] = data.split('T');
  const [year, month, day] = date.split('-');

  return day + '/' + month + '/' + year;
};

const meses = [
  '-',
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

export const formatoMesAno = (data: string) => {
  const [date, ] = data.split(' ');
  const [ , month, year] = date.split('/');


  return meses[parseInt(month)] + '/' + year;
};
