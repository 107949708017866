import styled from 'styled-components';

export const LoginLayoutStyled = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.layout.color.white};
  position: relative;

  > *:first-child {
    width: 30%;
    height: auto;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    overflow: auto;

    .logomobile {
      display: none;
    }

    label {
      margin: 15px 0;
    }

    h2 {
      font-size: ${(props) => props.theme.layout.head.medium};
      font-weight: 500;
      color: #1c4584;
      margin: 0 0 50px 0;
    }

    /* > * {
      padding: 5% 0
    } */

    .button {
      display: flex;
      justify-content: flex-end;
    }

    button {
      width: auto;
      text-align: end;
    }

    p.acessoSenha {
      margin: 0 0 60px 0;
      font-size: 12px;
      a {
        color: ${(props) => props.theme.layout.color.main_colors.blue_300};
        font-weight: 600;
      }
    }

    p.primeiroInst {
      font-size: 14px;
    }

    #span {
      color: #1c4584;
    }
  }

  .right {
    width: 40%;
    /* height: 100%; */
    padding: 100px 5% 60px 5%;
    background: ${(props) => props.theme.layout.color.background.dark_blue};
    color: ${(props) => props.theme.layout.color.white};
    border-radius: 0 0 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .boxIcons {
      width: 75%;

      .destaque {
        font-size: ${(props) => props.theme.layout.text.large};
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .lineLogin {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;

    > *:first-child {
      margin: 0 7%;
      width: 86%;
      /* min-height: 600px; */
      padding: 0;
      .space {
        display: none;
      }
      .logomobile {
        display: block;
        margin: 45px 0 65px;
        align-self: center;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 40px 0;
        display: block !important;
      }
      label {
        margin: 15px 0 !important;
      }
      button {
        margin: 35px 0 65px 0;
      }
    }

    .right {
      display: none;
      width: 90%;
      /* height: 50%; */
      /* min-height: 700px; */
      padding: 60px 5% 60px 5%;
      border-radius: 0;

      img.logoDesktop {
        display: none;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .right {
      .boxIcons {
        width: 100% !important;

        .destaque {
          text-align: left !important;
        }
      }
    }
  }
`;

export const ContentTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* height: 20%; */
  max-width: 450px;
  text-align: center;
  margin: 20px 0 20px 0;

  p {
    margin: 0 0 1rem 0;
    ${(props) => props.theme.layout.text.medium};
  }
  @media screen and (max-width: 480px) {
    text-align: left;
    align-items: flex-start;
  }
`;

export const SitesButtons = styled.div`

 :nth-child(2) {
  padding-top: 20px;
  margin-top: 20px;
 }

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.layout.color.complementares.gray_600};

  div {
    display: flex;
    align-items: center;
  }

  div.boxMeu {
    margin: 10px 0;
    min-width: 213px;
  }

  span.nameButton {
    display: block;
    padding: 0 10px;
    font-size: 18px;
    /* margin-left: 10px; */
  }

  img:first-child {
    margin: 0 0 0 5px;
  }

  img:last-child {
    margin: 0 5px 0 0;
  }
  p {
    width: 100%;
    margin: 15px 10px 20px 0;
    font-size: 12px;
    line-height: 16px;
  }

  + div {
    border-top: 1px solid #cccccc;
  }

  @media screen and (max-width: 780px) {
    .buttonMobile {
      margin: -35px 0 -65px;
    }
    + div {
      margin-top: 20px;
      padding-top: 40px;
    }
  }
`;
