import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import PageTitle from "../../components/page's_title/page-title";
import {
  CalendarIcon,
  CalendarStart,
  CalendarEnd,
  // DownloadIcon,
  PrinterIcon,
} from '../../assets/menu_icons';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import {
  DadosUsuario,
  DivDateInputs,
  SubTitle,
} from './pagamento-por-periodo.style';
import Input from '../../components/inputs/input';
import BasicBox from '../../components/box_card/box-card';
import Table from '../../components/table/table';
import AppButton from '../../components/button/button';
import SubTitulo1 from '../../components/fonts_components/sub-headers/subtitulo1';
import { layout } from '../../themes/layout';
import {
  ContainerTable,
  HeaderTable,
} from '../../components/inputs/input.style';
import RadioUnits from '../../components/inputs/input-radio';
import NextStepIcon from '../../components/button/buttons_icons_animations/next-step';
import { useAuth } from '../../Contexts/AuthContext';
import BuscaClienteSubunidade, { IBuscaUnidade } from '../../util/buscaClienteSubunidade';
import BuscaPagamentoPorPeriodo, {
  IPagamentoPorPeriodo,
} from '../../util/pagamentosPorPeriodo';
import { pagamentoPorPeriodoSchema } from './pagameneto-por-periodo.schema';
import { ValidationError } from 'yup';
import ErrorMessage from '../../components/error_message/error-message';
import { formatoData } from '../../util/formatoData';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import { useReactToPrint } from 'react-to-print';
import soter_logo from '../../assets/lobo_mob_header.svg';
import ReadableValue from '../../util/transformaEmMoeda';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';

export function PeriodPayment() {

  const { apartamentosPagamento, setApartamentosPagamento } = useClienteSubunidade()
  const [pagamentos, setPagamentos] = useState<IPagamentoPorPeriodo | null>(
    null,
  );
  const [unidadeSelecionada, setUnidadeSelecionada] = useState({
    nomeEmpreendimento: '',
    codEmpreendimento: '',
    idEmpreendimento: 0,
    codUnidade: '',
    unidade: '',
    subUnidade: '',
    numVenda: 0,
    urlEmpreendimento: '',
  });
  const [errorPagamentos, setErrorPagamentos] = useState(false);
  const [data, setData] = useState({
    dateStart: '',
    dateEnd: '',
    numVenda: 0,
  });
  const [error, setError] = useState({
    dateStart: '',
    dateEnd: '',
    numVenda: '',
  });
  const [waitingReq, setWaitingReq] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);

  const { access_token, user } = useAuth();

  useEffect(() => {
    setLoadingUnits(true);
    BuscaClienteSubunidade(
      user?.codcolcfo ?? '0',
      user?.codcfo ?? '0',
      102,
      access_token,
    )
      .then((res) => {
        if (res.length > 0) {
          setLoadingUnits(false);
          return setApartamentosPagamento(res as IBuscaUnidade);
        }

        throw new Error();
      })
      .catch(() => {
        setLoadingUnits(false);
        return setApartamentosPagamento([])});
  }, []);

  const handleSend = useCallback(async () => {
    try {
      await pagamentoPorPeriodoSchema.validate(data, { abortEarly: false });
      setLoadingReq(true);
      setErrorPagamentos(false);

      const unidade = apartamentosPagamento.find(
        (item) => item.numVenda === data.numVenda,
      );
      if (unidade) setUnidadeSelecionada(unidade);

      const res = await BuscaPagamentoPorPeriodo({
        codcolcfo: user.codcolcfo ?? '0',
        codcfo: user.codcfo ?? '0',
        numVenda: data.numVenda,
        access_token: access_token,
        dataInicio: data.dateStart,
        dataFim: data.dateEnd,
      });

      setWaitingReq(false);
      if (res.length > 0) {
        setPagamentos(res);
        return setLoadingReq(false);
      }
      setErrorPagamentos(true);
      throw new Error();
    } catch (yupError: unknown) {
      setLoadingReq(false);
      if (yupError instanceof ValidationError) {
        yupError.inner.forEach((error: ValidationError) => {
          const key = error.path;
          const value = error.message;
          if (key) {
            setError((prevData) => ({
              ...prevData,
              [key]: value,
            }));
          }
        });
      } else {
        return data.numVenda && setErrorPagamentos(true);
      }
    }
  }, [data]);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setWaitingReq(true);
      if (target.name !== 'unidadeSelecionada') {
        setError((prevError) => ({
          ...prevError,
          [target.name]: '',
        }));
        setData((prevData) => {
          return {
            ...prevData,
            [target.name]: target.value,
          };
        });
      } else {
        setError((prevError) => ({
          ...prevError,
          numVenda: '',
        }));

        setData((prevData) => ({
          ...prevData,
          numVenda: parseInt(target.value),
        }));
      }
    },
    [setData, data, apartamentosPagamento, setApartamentosPagamento],
  );

  const TotalPagamentos = () => {
    return (
      (pagamentos &&
        pagamentos.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.valorpago;
        }, 0)) ||
      0
    );
  };

  // lógica para imprimir React-to-print // início
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('Some cool text from the parent');

  const handleAfterPrint = React.useCallback(() => {
    return
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    return
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText('Loading new text...');

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  function fnBrowserDetect(){

    const userAgent = navigator.userAgent;
    let isMacOS = false;

    if(userAgent.match(/Mac OS/)){
        isMacOS = true;
      }
    return isMacOS;
  }


  const checkSystem =  () => {
    if(fnBrowserDetect()){
      return alert(`Você está usando o sistema operacional Mac OS.
      Para imprimir ou salvar a tabela em PDF solicite a impressão pelo próprio navegador.`)
    }
    return handlePrint();
  }


  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'pagamentoporperiodo',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: false,
  });

  React.useEffect(() => {
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);
  // lógica para imprimir React-to-print // fim

  return (
    <DividerGrid quantity={Quantity.three}>
      <PageTitle>
        <span>Pagamentos por período</span>
        <img src={CalendarIcon} alt="ícone de uma chave" />
      </PageTitle>
      <SubTitulo1
        color={
          error.numVenda == ''
            ? layout.color.complementares.gray_600
            : layout.color.alert.red_alert
        }
        gridArea={'2 / 1 / 3 / 4'}
        gridAreaMobile={'1 / 1 / 2 / 4'}
      >
          <span className='disapear-for-print'>
           Selecione o período e o empreendimento
          </span>
      </SubTitulo1>

      <DivDateInputs gridArea="3 / 1 / 6 / 2" gridAreaMobile="2 / 1 / 3 / 4">
        <Input
          type={'text'}
          name={'dateStart'}
          placeholder={'Data inicial'}
          icon={<img src={CalendarStart} alt="ícone de um calendário" />}
          onChange={handleChange}
          errorText={error.dateStart}
          onFocus={({ target }: ChangeEvent<HTMLInputElement>) => {
            target.type = 'date';
          }}
          // onBlur={({ target }: ChangeEvent<HTMLInputElement>) => {
          //   target.type = 'text';
          // }}
        />
        <Input
          type={'text'}
          name={'dateEnd'}
          placeholder="Data final"
          icon={<img src={CalendarEnd} alt="ícone de um calendário" />}
          onChange={handleChange}
          errorText={error.dateEnd}
          onFocus={({ target }: ChangeEvent<HTMLInputElement>) => {
            target.type = 'date';
          }}
          // onBlur={({ target }: ChangeEvent<HTMLInputElement>) => {
          //   target.type = 'text';
          // }}
        />
      </DivDateInputs>

      <BasicBox
        blue
        gridArea={'3 / 2 / 6 / 4'}
        gridAreaMobile={'3 / 1 / 5 / 4'}
      >
        {!apartamentosPagamento.length ? (
          loadingUnits ? (
            <p style={{ color: '#fff', padding: '0 0 0 15px' }}>
              Carregando unidades{' '}
              <span style={{ marginLeft: '15px' }}>
                <LoadingIcon />
              </span>
            </p>
          ) : (
            <p style={{ fontSize: '14px', lineHeight: '18px', color: 'white', marginLeft: '15px', marginRight: '25px' }}>
              No momento, o seu plano está passando por atualizações. Qualquer dúvida, entre em contato.
            </p>
          )
        ) : (
          <>
            <ContainerTable>
              <HeaderTable>
                <p className="header">Empreendimento</p>
                <p className="header">Bloco</p>
                <p className="header">Unidade</p>
              </HeaderTable>

              {apartamentosPagamento.map((unit) => (
                <RadioUnits
                  key={unit.numVenda}
                  name="unidadeSelecionada"
                  value={unit.numVenda}
                  onChange={handleChange}
                >
                  <p>{unit.nomeEmpreendimento}</p>
                  <p>{unit.unidade}</p>
                  <p>{unit.subUnidade}</p>
                </RadioUnits>
              ))}
            </ContainerTable>
          </>
        )}
      </BasicBox>

      <div
        className="gridButton"
        style={{
          gridArea: '6 / 3 / 7 / 3',
        }}
      >
        <AppButton text="atualizar" onClick={handleSend} className="atEnd">
          <NextStepIcon />
        </AppButton>
      </div>

      {loadingReq ? (
        <p>
          Carregando
          <span style={{ marginLeft: '15px' }}>
            <LoadingIcon />
          </span>
        </p>
      ) : null}
      {waitingReq ? null : (
        <BasicBox
          ref={componentRef}
          gridArea="7 / 1 / 7 / 4"
          gridAreaMobile="7 / 1 / 7 / 4"
        >
          <div className="onlyForPrint headerPrint">
            <div>
              <img
                src={soter_logo}
                alt="logo"
                style={{ width: '120px', margin: '0px 0px 0px 15px' }}
              />
            </div>
            <div>Pagamento por período</div>
            <div></div>
          </div>
          <DadosUsuario>
            <div className="dados">
              <div className="dadoscol1">
                <p className="_cliente">
                  <span>Cliente:</span> {user.nome}
                </p>
                <p className="_conjuge">
                  <span>Cônjuge:</span>
                </p>
              </div>
              <div>
                <p className="_empreendimento">
                  <span>Empreendimento:</span>{' '}
                  {unidadeSelecionada.nomeEmpreendimento}
                </p>
                <p className="_bloco">
                  <span>Bloco:</span> {unidadeSelecionada.unidade}
                </p>
                <p className="_unidade">
                  <span>Unidade:</span> {unidadeSelecionada.subUnidade}
                </p>
              </div>
              <div className="icones">
                {loading && <LoadingIcon />}
                {/* <img
                  onClick={checkSystem}
                  src={DownloadIcon}
                  alt="ícone de uma nuvem"
                  style={{
                    marginRight: '20px',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  }}
                /> */}
                <img
                  onClick={checkSystem}
                  src={PrinterIcon}
                  alt="ícone de uma impressora"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className="onlyForPrint" />
            </div>
            <div className="data_e_valor">
              <p className="_periodo">
                <span>Período:</span>{' '}
                {data.dateStart ? formatoData(data.dateStart) : ''}{' '}
                {data.dateStart && data.dateEnd ? 'à' : null}{' '}
                {data.dateEnd ? formatoData(data.dateEnd) : ''}{' '}
              </p>
            </div>
          </DadosUsuario>
          <SubTitle>
            <SubTitulo1 color={layout.color.main_colors.main}>
              Pagamentos
            </SubTitulo1>
          </SubTitle>
          {errorPagamentos ? (
            <ErrorMessage>
              Nenhum pagamento foi encontrado nesse período.
            </ErrorMessage>
          ) : data.numVenda && pagamentos ? (
            <Table
              headers={['Data pagamento', 'Valor pago(R$)']}
              values={pagamentos.map((e) => {
                const arr = [
                  e.dataPagamento ? formatoData(e.dataPagamento) : '',
                  e.valorpago ? ReadableValue(e.valorpago) : '',
                ];
                return arr;
              })}
              footers={['Total', ReadableValue(TotalPagamentos())]}
            />
          ) : (
            <p>Clique no botão atualizar para puxar os pagamentos.</p>
          )}
        </BasicBox>
      )}
    </DividerGrid>
  );
}
