import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IAcompanhamentoObra {
  id: number;
  codColigada: number;
  codEmpreendimento: number;
  Acabamento: number;
  Revestimento: number;
  Alvenaria: number;
  Estrutura: number;
  Fundacao: number;
  PreparoTerreno: number;
  TotalFinalizado: number;
  DataAlteracao: string;
  PrevEntrega: string;
  UsuarioAlteracao: string;
  Embed: string;
  Links: {Links: string[], Periodo: string }[]
}

export default async function AcompanhamentoDeObra(
  idEmpreendimento: number,
  access_token: string,
): Promise<IAcompanhamentoObra> {
  try {
    const urlAcompanhamentoObra = `ClienteVenda/BuscaAcompanhamentoObra?_idEmpreendimento=${idEmpreendimento}`;

    const acompanhamentoObraAPI: AxiosResponse<IAcompanhamentoObra> =
      await axiosInstance.get(urlAcompanhamentoObra, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    return acompanhamentoObraAPI.data;
  } catch (e) {
    return {
      id: 0,
      codColigada: 0,
      codEmpreendimento: 0,
      Acabamento: 0.0,
      Revestimento: 0.0,
      Alvenaria: 0.0,
      Estrutura: 0.0,
      Fundacao: 0.0,
      PreparoTerreno: 0.0,
      TotalFinalizado: 0.0,
      DataAlteracao: '',
      PrevEntrega: '',
      UsuarioAlteracao: '',
      Embed: '',
      Links:[{Links:[], Periodo:'' }]
    };
  }
}
