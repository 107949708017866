import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AppButton from '../../components/button/button';
import Input from '../../components/inputs/input';
import PageTitle from "../../components/page's_title/page-title";
import { assistanceSchema } from './assistanceSchema';
import { ValidationError } from 'yup';

import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../module/axios.module';
import { ContentTextStyled, LoginLayoutStyled } from '../login/login.style';
import lineLogin from '../../assets/line-login.svg';
import soter_logo from '../../assets/logo_login_branca.svg';
import soterLogoMobile from '../../assets/logo_mobile.svg';
import ArrowLogin from '../../assets/buttons-icons/arrow-login.svg';
import Select from '../../components/inputs/input-select';
import { Empreendimento } from '../../Contexts/AuthContext.type';
import { useAuth } from '../../Contexts/AuthContext';
import CheckBox from '../../components/inputs/input-checkbox';

type Bloco = {
  [key: string]: string[];
};

export default function AssistenciaTecnica() {
  const { getAssistenciaDados, assistenceToken, userIp, getUserIp } = useAuth();

  const [data, setData] = useState({
    email: '',
    empreendimento: '',
    bloco: '',
    unidade: '',
  });
  const [error, setError] = useState({
    email: '',
    empreendimento: '',
    bloco: '',
    unidade: '',
  });
  const [errorApi, setErrorApi] = useState('');

  const [worked, setWorked] = useState('');
  const [waitingReq, setWaitingReq] = useState(false); //usado para sinalizar carregamento dos empreendimentos
  const [loadingEmail, setLoadingEmail] = useState(false); //usado para sinalizar status do envio de email com link
  const [allowUnidade, setAllowUnidade] = useState(false);
  const [allowSubunidade, setAllowSubunidade] = useState(false);
  const [empreendimentosSoter, setEmpreendimentosSoter] = useState<string[]>(
    [],
  );
  const [blocos, setBlocos] = useState<Bloco>({});
  const [subUnidades, setSubUnidades] = useState<string[]>([]);
  const [dadosUnidades, setDadosUnidades] = useState<Empreendimento[]>([]);
  const [checked, setChecked] = useState(false); // condicional para habilitar botão de envio
  const [preChecked, setPreChecked] = useState(true); // condicional para liberar botão em primeira renderização antes de preencher form

  const handleChange = useCallback(
    ({
      target,
    }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      setError((prevError) => ({
        ...prevError,
        [target.name]: '',
      }));

      if (!checked) setPreChecked(false);

      setData((prevData) => {
        return {
          ...prevData,
          [target.name]: target.value,
        };
      });
    },
    [setData, data],
  );

  const handleChangeEmpreendimento = useCallback(
    ({
      target,
    }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      setError((prevError) => ({
        ...prevError,
        [target.name]: '',
      }));

      if (!checked) setPreChecked(false);

      setData((prevData) => {
        return {
          ...prevData,
          [target.name]: target.value,
        };
      });
      data.bloco = '';
      data.unidade = '';
      setAllowUnidade(true);
      setAllowSubunidade(false);
    },
    [setData, data],
  );

  const handleChangeUnidade = useCallback(
    ({
      target,
    }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      setError((prevError) => ({
        ...prevError,
        [target.name]: '',
      }));

      if (!checked) setPreChecked(false);

      setData((prevData) => {
        return {
          ...prevData,
          [target.name]: target.value,
        };
      });
      FiltraSubunidades(target.value);
      setAllowSubunidade(true);
      data.bloco = '';
    },
    [setData, data],
  );

  const handleSend = useCallback(async () => {
    setErrorApi(() => '');

    try {
      const { email, empreendimento, bloco, unidade } = data;

      const id = dadosUnidades.find(
        (element) => element.nomeEmpreendimento == empreendimento,
      )?.idEmpreendimento;
      const codUnidade = dadosUnidades.find(
        (element) => element.unidade == bloco,
      )?.codUnidade;

      await assistanceSchema.validate(data, { abortEarly: false });
      setLoadingEmail(true);

      const ip = localStorage.getItem('@Soter:UserIp') || userIp || getUserIp();

      const res: AxiosResponse<Empreendimento[] | []> = await axiosInstance.get(
        `/Empreendimento/BuscaAssistencia?_idEmpreendimento=${String(
          id,
        )}&_numUnid=${String(
          codUnidade,
        )}&_numSubUnid=${unidade}&_ip=${ip}&_email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${
              assistenceToken ? assistenceToken.access_token : ''
            }`,
          },
        },
      );

      // função de envio de email entra aqui
      if (res) {
        const sendEmail = await axiosInstance.post(
          'https://soter-six.vercel.app/api/cliente/assistenciaTecnica',
          {
            email,
            empreendimento,
            bloco,
            unidade,
            linkAssistencia: res.data,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        setLoadingEmail(false);
        if (sendEmail.status === 200) {
          return setWorked(
            'O link de acesso foi enviado para e-mail informado!',
          );
        }
      }

      throw new AxiosError();
    } catch (yupError: unknown) {
      if (yupError instanceof ValidationError) {
        yupError.inner.forEach((error: ValidationError) => {
          const key = error.path;
          const value = error.message;
          if (key) {
            setError((prevData) => ({
              ...prevData,
              [key]: value,
            }));
          }
        });
      }
      if (yupError instanceof AxiosError) {
        console.log(yupError);
        setErrorApi(
          `Erro no envio da solicitação. Por favor entre em contato no e-mail civil@soter.com.br`,
        );
      }

      setLoadingEmail(false);
    }
  }, [data]);

  const FiltraSubunidades = (unidade: string) => {
    const subunidades = dadosUnidades.filter(
      (element: Empreendimento) => element.unidade == unidade,
    );
    const sub = subunidades.map((sub) => sub.subUnidade);
    setSubUnidades(sub);
  };

  useEffect(() => {
    setWaitingReq(true);
    getAssistenciaDados()
      .then((r: undefined | Empreendimento[]) => {
        if (r === undefined) {
          return null;
        }
        const empreendimentos: string[] = [];
        const blocos: Bloco = {};
        for (let i = 0; i < r.length; i++) {
          const nome: string = r[i].nomeEmpreendimento;
          const unidade: string = r[i].unidade;
          if (!empreendimentos.includes(nome)) {
            empreendimentos.push(nome);
            blocos[nome] = [r[i].unidade];
          } else {
            if (!blocos[nome].includes(unidade))
              blocos[nome].push(r[i].unidade);
          }
        }
        setEmpreendimentosSoter(empreendimentos);
        setBlocos(blocos);
        setDadosUnidades(r);
        setWaitingReq(false);
      })
      .catch((error: string) => {
        console.error('Erro ao obter dados:', error);
      });
  }, []);

  return (
    <LoginLayoutStyled>
      <div>
        <img
          width="173"
          className="logomobile"
          src={soterLogoMobile}
          alt="logo"
        />
        <div className="space" style={{ marginTop: '100px' }}></div>
        <div>
          <PageTitle>
            <span>Assistência Técnica</span>
          </PageTitle>

          <div>
            <div>
              <Input
                name="email"
                editable
                type={'email'}
                placeholder="E-mail"
                onChange={handleChange}
                value={data.email}
                errorText={error.email}
              />
            </div>
            <div>
              <Select
                name="empreendimento"
                placeholder="Empreendimento"
                onChange={handleChangeEmpreendimento}
                value={data.empreendimento}
                errorText={error.empreendimento}
                options={empreendimentosSoter}
              />
            </div>
            <div style={{ display: 'flex', marginTop: '-15px' }}>
              <Select
                disabled={!allowUnidade}
                name="bloco"
                placeholder="Bloco"
                onChange={handleChangeUnidade}
                value={data.bloco}
                errorText={error.empreendimento}
                options={blocos[data.empreendimento]}
              />
              <div style={{ width: '50%', marginLeft: '30px' }}>
                <Select
                  disabled={!allowSubunidade}
                  name="unidade"
                  placeholder="Unidade"
                  onChange={handleChange}
                  value={data.unidade}
                  errorText={error.unidade}
                  options={subUnidades}
                />
              </div>
            </div>

            <CheckBox checked={checked} setChecked={setChecked}>
              Li e aceito a política de privacidade da Soter.{' '}
              <a
                target="_blank"
                href="https://www.soter.com.br/politica-de-privacidade-2"
              >
                <span style={{ color: '#2E80C3', fontWeight: '600' }}>
                  (Leia-me)
                </span>
              </a>
            </CheckBox>
            <div
              style={{
                minHeight: '30px',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              {waitingReq ? (
                <>
                  <p>
                    <LoadingIcon /> Carregando empreendimentos
                  </p>{' '}
                </>
              ) : null}
              {loadingEmail ? (
                <span style={{ marginLeft: '15px', display: 'block' }}>
                  <LoadingIcon />
                </span>
              ) : errorApi ? (
                <p
                  style={{
                    color: '#d00020',
                    padding: '10px 0',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  {errorApi}
                </p>
              ) : worked ? (
                <p
                  style={{
                    color: '#48af77',
                    padding: '10px 0',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  {worked}
                </p>
              ) : null}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '50px',
              }}
            >
              {checked || preChecked ? (
                <div className="button">
                  <AppButton
                    onClick={handleSend}
                    login
                    text="enviar"
                    animation="moving"
                  >
                    <img src={ArrowLogin} alt="seta para direita" />
                  </AppButton>
                </div>
              ) : (
                <div
                  style={{ filter: 'grayscale(.7)', opacity: '.5' }}
                  className="button"
                >
                  <AppButton login text="enviar" animation="moving" disabled>
                    <img src={ArrowLogin} alt="seta para direita" />
                  </AppButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="right">
        <img className="lineLogin" src={lineLogin} />
        <img className="logoDesktop" src={soter_logo} alt="logo" />
        <ContentTextStyled>
          <p>
            Bem-vindo à <span className="destaque">Assistência Técnica.</span>
          </p>

          <p>
            Este canal foi desenvolvido para facilitar o seu atendimento, caso a
            sua unidade necessite de análise, revisão ou reparo.
          </p>
          <p>
            Preencha os campos ao lado para receber o link de acesso na sua
            caixa de e-mail.
          </p>
        </ContentTextStyled>
      </div>
    </LoginLayoutStyled>
  );
}
