import styled from 'styled-components';

export const Div = styled.div`
display: flex;
flex-direction: column;
grid-area: 2 / 1 / 3 /3;

>:first-child{
  margin-bottom: 25px;
}

span.instructions{
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  margin: 30px 0 25px;
  display: block;
}

button{
  align-self: end;
}
`
