import React from 'react';
import { Props } from './card-etapa.type';
import { CardEtapaStyled, Title } from './card-etapa.style';
import CircularProgress from '../circular_progress/circular-progress';


export default function CardEtapaObra({progress, iconAddress, title}:Props) {


  return (
    <CardEtapaStyled >
      <CircularProgress progress={progress} />
      <img src={iconAddress} alt={"Ícone da etapa" + title} />
      <Title>{title}</Title>
    </CardEtapaStyled>
  )
}
