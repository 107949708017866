import styled from 'styled-components'

export const DadosUsuario = styled.div`
display: grid;
grid-template-columns: 50% 50%;
grid-template-rows: auto;
// grid-template-areas: "1fr ." "1fr 1fr" "1fr 1fr" "1fr 1fr" ". ." "2fr" "1fr 1fr" "1fr 1fr" "1fr 1fr" ". ." "2fr" "1fr 1fr" "1fr 1fr" "1fr 1fr" ;
grid-gap: 12px;

grid-area: 2 / 1/ 3 /3;

p{
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  span{
    font-weight: 600;
  }
}

@media screen and (max-width:780px){
  grid-template-columns: 100%;
  grid-template-rows: auto;

}

`
