import styled from 'styled-components';

interface IProp {
  small: boolean
}

export const NavStyled = styled.div<IProp>`
  width: 26.57%;
  height: 100vh;
  display:flex;
  flex-direction:column;
  align-items: strecth;
  justify-content: start;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;

  background: ${props => props.theme.layout.color.background.dark_blue};
  color: ${props => props.theme.layout.color.white};

   scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .menuContainer {
    position: relative;
    width: 100%;
  }

  .logoGrande {
    display: flex;
  }

  .logoReduzida {
    display: none;
  }


  @media (max-width: 1024px) {
    width: 11.72%;
    .logoGrande {
      display: none;
    }
    .logoReduzida {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 1280px) {
    align-items: center;
    .logoGrande {
      display: flex;
      // width: 370px;
    }
  }

`

export const LogoContainer = styled.div`
height: 15%;
max-height: 160px;
display: flex;
justify-content: center;
align-items: center;
padding: 42px 0;
`
