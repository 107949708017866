import React from 'react';

export type Props = {
  children: React.ReactNode
  quantity: Quantity
  size?: Size
};

export enum Size {
  half,
  one_third_start,
  one_third_end
}

export enum Quantity {
  one,
  two,
  three
}


export type ItemOnGrid = {
  gridArea?: string
  gridAreaMobile?: string
  className?: string
}
