import styled from 'styled-components';

export const PageLayoutStyled = styled.div`
  width: 100%;
  height: auto;
  h2 {
    color: ${(props) => props.theme.layout.color.main_colors.main};
  }
`;
export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 166px;
  padding-left: 26.57vw;
  width: calc(100% - 26.57vw);

  > :last-child {
    position: relative;
  }

  @media (max-width: 1024px) {
    padding-left: 11.72vw;
    width: calc(100% - 11.72vw);
  }

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }

  @media print {
    padding: 0;
    width: 100%;
    position: relative;
  }
`;
