import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import PageTitle from "../../components/page's_title/page-title";
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import { DadosUsuario, SubTitle } from './ficha-financeira.style';
import BasicBox from '../../components/box_card/box-card';
import Table from '../../components/table/table';
// import DownloadIcon from '../../assets/menu_icons/download-icon.svg';
import PrinterIcon from '../../assets/menu_icons/printer-icon.svg';
import SubTitulo1 from '../../components/fonts_components/sub-headers/subtitulo1';
import { layout } from '../../themes/layout';
import DollarSign from '../../assets/menu_icons/dollar-icon.svg';
import {
  ContainerTable,
  HeaderTable,
} from '../../components/inputs/input.style';
import RadioUnits from '../../components/inputs/input-radio';
import { useAuth } from '../../Contexts/AuthContext';
import IsUserBlocked from '../../util/isUserBlocked';
import BuscaClienteSubunidade, {
  IBuscaUnidade,
} from '../../util/buscaClienteSubunidade';
import BuscaParcelasUnidade, {
  IBuscaParcela,
  IFichaFinanceira,
} from '../../util/fichaFinanceira';
import { formatoData } from '../../util/formatoData';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import AppButton from '../../components/button/button';
import NextStepIcon from '../../components/button/buttons_icons_animations/next-step';
import { useReactToPrint } from 'react-to-print';
import soter_logo from '../../assets/lobo_mob_header.svg';
import ReadableValue from '../../util/transformaEmMoeda';
import BuscaDadosVenda from '../../util/DadosDaVenda';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';

export function FinancialStatement() {
  const { apartamentosFicha, setApartamentosFicha } = useClienteSubunidade();
  const [parcelas, setParcelas] = useState<IFichaFinanceira>({
    atrasadas: [],
    emAberto: [],
    quitadas: [],
  });
  const [errorParcelas, setErrorParcelas] = useState(false);
  const [userBlocked, setUserBlocked] = useState(false);
  const { access_token, user } = useAuth();
  const [data, setData] = useState({
    nomeEmpreendimento: '',
    codEmpreendimento: '',
    idEmpreendimento: 0,
    codUnidade: '',
    unidade: '',
    subUnidade: '',
    numVenda: 0,
    urlEmpreendimento: '',
  });
  const [dadosVenda, setDadosVenda] = useState({
    dataVenda: '',
    cliente: '',
    conjuge: '',
    valorVenda: 0,
  });
  const [submitError, setSubmitError] = useState({
    numVenda: '',
  });
  const [waitingReq, setWaitingReq] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);

  function fnBrowserDetect() {
    const userAgent = navigator.userAgent;
    let isMacOS = false;

    if (userAgent.match(/Mac OS/)) {
      isMacOS = true;
    }
    return isMacOS;
  }

  useEffect(() => {
    IsUserBlocked(access_token)
      .then((res) => setUserBlocked(res.isBlocked))
      .catch(() => setUserBlocked(true));

    BuscaClienteSubunidade(
      user?.codcolcfo ? user.codcolcfo : '0',
      user?.codcfo ? user.codcfo : '0',
      101,
      access_token,
    )
      .then((res) => {
        if (res.length > 0) {
          return setApartamentosFicha(res as IBuscaUnidade);
        }

        throw new Error();
      })
      .catch(() => setApartamentosFicha([]));
  }, []);

  const handleSend = useCallback(async () => {
    try {
      await yup
        .object()
        .shape({
          numVenda: yup
            .number()
            .required('Selecione um empreendimento.')
            .moreThan(0),
        })
        .validate(data, { abortEarly: false });

      setLoadingReq(true);
      setErrorParcelas(false);

      BuscaParcelasUnidade(
        user.codcolcfo ?? '0',
        user.codcfo ?? '0',
        data.numVenda,
        access_token,
      )
        .then((res) => {
          if (
            res.quitadas.length > 0 ||
            res.emAberto.length > 0 ||
            res.atrasadas.length > 0
          ) {
            setLoadingReq(false);
            setWaitingReq(false);
            return setParcelas(res);
          }
          throw new Error();
        })
        .catch(() => {
          setLoadingReq(false);
          return data.numVenda && setErrorParcelas(true);
        });

      BuscaDadosVenda(
        user.codcolcfo ?? '0',
        user.codcfo ?? '0',
        data.numVenda,
        access_token,
      )
        .then((res) => {
          if (res) {
            return setDadosVenda({
              dataVenda: formatoData(res.dataVenda),
              cliente: res.cliente,
              conjuge: res.conjuge,
              valorVenda: res.valorVenda,
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          setLoadingReq(false);
          return data.numVenda && setErrorParcelas(true);
        });
    } catch (yupError: unknown) {
      setErrorParcelas(true);
      setLoadingReq(false);
      if (yupError instanceof yup.ValidationError) {
        yupError.inner.forEach((error: yup.ValidationError) => {
          const key = error.path;
          const value = error.message;
          if (key) {
            setSubmitError((prevData) => ({
              ...prevData,
              [key]: value,
            }));
          }
        });
      } else {
        return data.numVenda && setErrorParcelas(true);
      }
    }
  }, [data]);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setSubmitError({ numVenda: '' });
      setWaitingReq(true);
      const res = apartamentosFicha.find(
        (item) => item.numVenda === parseInt(target.value),
      );

      if (res) {
        setData(res);
        setErrorParcelas(false);
      }
    },
    [apartamentosFicha, setData, data],
  );

  const TotalPagamentos = (arr: IBuscaParcela, key: string) => {
    return arr.reduce((accumulator, currentValue): number => {
      const value: number =
        key === 'Atrasada'
          ? currentValue['valordevido']
          : key === 'Quitada'
          ? currentValue.valorpago
          : key === 'Em Aberto'
          ? currentValue.valordevido
          : 0;
      return accumulator + value;
    }, 0);
  };

  // lógica para imprimir React-to-print // início
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('Some cool text from the parent');

  const handleAfterPrint = React.useCallback(() => {
    return;
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    return;
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText('Loading new text...');

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const checkSystem = () => {
    if (fnBrowserDetect()) {
      return alert(`Você está usando o sistema operacional Mac OS.
      Para imprimir ou salvar a tabela em PDF solicite a impressão pelo próprio navegador.`);
    }
    return handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle:
      'fichafinanceira' +
      '-' +
      data.nomeEmpreendimento +
      '-' +
      data.unidade +
      '-' +
      data.subUnidade,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  React.useEffect(() => {
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);
  // lógica para imprimir React-to-print // fim

  return (
    <>
      <DividerGrid quantity={Quantity.three}>
        <PageTitle>
          <span>Ficha Financeira</span>
          <img src={DollarSign} alt="Cifrão" />
        </PageTitle>
        {userBlocked ? (
          <p>Não há itens disponíveis no momento.</p>
        ) : (
          <>
            <SubTitulo1
              color={
                submitError.numVenda == ''
                  ? layout.color.complementares.gray_600
                  : layout.color.alert.red_alert
              }
              gridArea={'2 / 1 / 3 / 2'}
              gridAreaMobile={'1 / 1 / 2 / 4'}
            >
              <span className="disapear-for-print">
                Selecione o empreendimento
              </span>
            </SubTitulo1>

            <BasicBox
              blue
              gridArea={'3 / 1 / 4 / 3'}
              gridAreaMobile={'2 / 1 / 3 / 4'}
            >
              {!apartamentosFicha.length ? (
                loadingReq ? (
                  <p style={{ color: '#fff', padding: '0 0 0 15px' }}>
                    Carregando unidades{' '}
                    <span style={{ marginLeft: '15px' }}>
                      <LoadingIcon />
                    </span>
                  </p>
                ) : (
                  <p style={{ fontSize: '14px', lineHeight: '18px', color: 'white', marginLeft: '25px', marginRight: '25px' }}>
                    No momento, o seu plano está passando por atualizações. Qualquer dúvida, entre em contato.
                  </p>
                )
              ) : (
                <>
                  <ContainerTable>
                    <HeaderTable>
                      <p className="header">Empreendimento</p>
                      <p className="header">Bloco</p>
                      <p className="header">Unidade</p>
                    </HeaderTable>

                    {apartamentosFicha.map((unit) => (
                      <RadioUnits
                        key={unit.numVenda}
                        name="unidadeSelecionada"
                        value={unit.numVenda}
                        onChange={handleChange}
                      >
                        <p>{unit.nomeEmpreendimento}</p>
                        <p>{unit.unidade}</p>
                        <p>{unit.subUnidade}</p>
                      </RadioUnits>
                    ))}
                  </ContainerTable>
                </>
              )}
            </BasicBox>

            <div
              className="gridButton"
              style={{ height: '50px', gridArea: '4 / 3 / 5 / 4' }}
            >
              <AppButton
                text="atualizar"
                onClick={handleSend}
                className="atEnd"
              >
                <NextStepIcon />
              </AppButton>
            </div>

            {loadingReq ? (
              <p style={{ gridArea: '5 / 1 / 6 / 4' }}>
                Carregando
                <span style={{ marginLeft: '15px' }}>
                  <LoadingIcon />
                </span>
              </p>
            ) : null}

            {waitingReq ? null : (
              <BasicBox
                toPrint={true}
                ref={componentRef}
                gridArea="5 / 1 / 6 / 4"
                gridAreaMobile="5 / 1 / 6 / 4"
              >
                <div className="onlyForPrint headerPrint">
                  <div>
                    <img
                      src={soter_logo}
                      alt="logo"
                      style={{ width: '120px', margin: '0px 0px 0px 15px' }}
                    />
                  </div>
                  <div>Ficha Financeira</div>
                  <div></div>
                </div>
                <DadosUsuario>
                  <div className="dados">
                    <div className="dadoscol1">
                      <p className="_cliente">
                        <span>Cliente:</span> {user?.nome}
                      </p>
                      <p className="_conjuge">
                        {dadosVenda.conjuge ? (
                          <>
                            <span>Cônjuge:</span> {dadosVenda.conjuge}{' '}
                          </>
                        ) : null}
                      </p>
                    </div>
                    <div>
                      <p className="_empreendimento">
                        <span>Empreendimento:</span> {data.nomeEmpreendimento}
                      </p>
                      <p className="_bloco">
                        <span>Bloco:</span> {data.unidade}
                      </p>
                      <p className="_unidade">
                        <span>Unidade:</span> {data.subUnidade}
                      </p>
                    </div>
                    <div className="icones">
                      {loading && <LoadingIcon />}
                      {/* <img
                        onClick={checkSystem}
                        // onClick={handlePrint}
                        src={DownloadIcon}
                        alt="ícone de uma nuvem"
                        style={{
                          marginRight: '20px',
                          marginLeft: '20px',
                          cursor: 'pointer',
                        }}
                      /> */}
                      <img
                        onClick={checkSystem}
                        // onClick={handlePrint}
                        src={PrinterIcon}
                        alt="ícone de uma impressora"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="onlyForPrint" />
                  </div>
                  <div className="data_e_valor">
                    <p className="_data">
                      {dadosVenda.dataVenda ? (
                        <>
                          <span>Data da venda:</span> {dadosVenda.dataVenda}{' '}
                        </>
                      ) : null}
                    </p>
                    <p className="_valor">
                      {dadosVenda.valorVenda ? (
                        <>
                          {' '}
                          <span>Valor da compra(R$):</span>{' '}
                          {ReadableValue(dadosVenda.valorVenda)}{' '}
                        </>
                      ) : null}
                    </p>
                  </div>
                </DadosUsuario>

                {data.numVenda ? (
                  errorParcelas ? (
                    <p>
                      Carregando{' '}
                      <span style={{ marginLeft: '15px' }}>
                        <LoadingIcon />
                      </span>
                    </p>
                  ) : (
                    <>
                      {parcelas.quitadas && parcelas.quitadas.length ? (
                        <>
                          <SubTitle>
                            <SubTitulo1 color={layout.color.main_colors.main}>
                              Parcelas pagas
                            </SubTitulo1>
                          </SubTitle>

                          <Table
                            headers={[
                              'Vencimento',
                              'Valor pago(R$)',
                              'Data pagamento ¹',
                            ]}
                            values={parcelas.quitadas.map((e) => {
                              const arr = [
                                formatoData(e.dataVencimento),
                                e.valorpago,
                                e.dataPagamento
                                  ? formatoData(e.dataPagamento)
                                  : '',
                              ];
                              const res = arr.map((e) => {
                                if (typeof e === 'number')
                                  return ReadableValue(e);
                                if (e == null) return '';
                                return e;
                              });
                              return res;
                            })}
                            footers={[
                              'Total',
                              '',
                              ReadableValue(
                                TotalPagamentos(parcelas.quitadas, 'Quitada'),
                              ),
                            ]}
                          />
                          <p style={{ fontSize: '0.8rem' }}>
                            ¹ A data de pagamento corresponde à data de quitação
                            da parcela em nossos sistemas.
                          </p>

                          <p
                            style={{
                              fontSize: '0.8rem',
                              margin: '10px 0 15px',
                            }}
                          >
                            ¹ Quitações de pagamentos feitos através de cheque
                            estão sujeitos ao cancelamento caso não haja a
                            respectiva compensação e liberação dos valores na
                            conta corrente da SOTER.
                          </p>
                        </>
                      ) : null}

                      {parcelas.atrasadas && parcelas.atrasadas.length ? (
                        <>
                          <SubTitle>
                            <SubTitulo1 color={layout.color.main_colors.main}>
                              Parcelas vencidas
                            </SubTitulo1>
                          </SubTitle>
                          <Table
                            headers={[
                              'Vencimento',
                              'Valor Atualizado(R$)',
                              'Encargos(R$)',
                              'Valor Total da Parcela(R$)',
                            ]}
                            values={parcelas.atrasadas.map((e) => {
                              const arr = [
                                formatoData(e.dataVencimento),
                                e.valorAtual,
                                e.valorEncargo,
                                e.valordevido,
                              ];
                              const res = arr.map((e) => {
                                if (typeof e === 'number')
                                  return ReadableValue(e);
                                if (e == null) return '';
                                return e;
                              });
                              return res;
                            })}
                            footers={[
                              'Total',
                              '',
                              '',
                              ReadableValue(
                                TotalPagamentos(parcelas.atrasadas, 'Atrasada'),
                              ),
                            ]}
                          />
                        </>
                      ) : null}

                      {parcelas.emAberto && parcelas.emAberto.length ? (
                        <>
                          <SubTitle>
                            <SubTitulo1 color={layout.color.main_colors.main}>
                              Parcelas a vencer
                            </SubTitulo1>
                          </SubTitle>
                          <Table
                            headers={[
                              'Vencimento',
                              'Valor Atualizado(R$)',
                              'Descapitalização²(R$)',
                              'Valor Total da Parcela(R$)',
                            ]}
                            values={parcelas.emAberto.map((e) => {
                              const arr = [
                                formatoData(e.dataVencimento),
                                e.valorAtual,
                                e.valorDescapitalizacao,
                                e.valordevido,
                              ];
                              const res = arr.map((e) => {
                                if (typeof e === 'number')
                                  return ReadableValue(e);
                                if (e == null) return '';
                                return e;
                              });
                              return res;
                            })}
                            footers={[
                              'Total',
                              '',
                              '',
                              ReadableValue(
                                TotalPagamentos(parcelas.emAberto, 'Em Aberto'),
                              ),
                            ]}
                          />

                          <p style={{ fontSize: '0.8rem' }}>
                            ² A descapitalização dos juros é realizada
                            diariamente.
                          </p>
                        </>
                      ) : null}
                    </>
                  )
                ) : (
                  <p style={{ marginTop: '20px' }}>
                    Escolha uma unidade para puxar a ficha financeira
                  </p>
                )}

                {parcelas.atrasadas.length || parcelas.emAberto.length ? (
                  <p style={{ marginTop: '50px' }}>
                    <span style={{ fontWeight: 800 }}>
                      Saldo devedor total(R$):
                    </span>{' '}
                    {ReadableValue(
                      TotalPagamentos(parcelas.atrasadas, 'Atrasada') +
                        TotalPagamentos(parcelas.emAberto, 'Em Aberto'),
                    )}
                  </p>
                ) : null}

                {parcelas.atrasadas.length ||
                parcelas.emAberto.length ||
                parcelas.quitadas.length ? (
                  <p style={{ marginTop: '10px' }}>
                    Importante: Todas as informações exibidas estão sujeitas a
                    alterações e/ou confirmações.
                  </p>
                ) : null}
              </BasicBox>
            )}
          </>
        )}
      </DividerGrid>
    </>
  );
}
