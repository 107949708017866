import React from 'react';
import { AcompanhamentoStyled } from './acompanhamento-obra.style';
import CardEtapaObra from './card_etapa/card-etapa';
import TotalProgress from './total_progress/total-progress';
import {
  PrepTerreno,
  Fundacao,
  Estrutura,
  Alvenaria,
  Revestimento,
  Acabamento,
} from '../../assets/steps_progress_illustrations';
import { Props } from './acompanhamento-obra.type';

export default function AcompanhamentoObra({
  terrenoProgress = 0,
  fundacaoProgress = 0,
  estruturaProgress = 0,
  alvenariaProgress = 0,
  revestimentoProgress = 0,
  acabamentoProgress = 0,
  totalProgress = 0,
}: Props) {
  return (
  <>
    <AcompanhamentoStyled>
      <CardEtapaObra
        progress={terrenoProgress}
        iconAddress={PrepTerreno}
        title={'Preparação do terreno'}
      />
      <CardEtapaObra progress={fundacaoProgress} iconAddress={Fundacao} title={'Fundação'} />
      <CardEtapaObra
        progress={estruturaProgress}
        iconAddress={Estrutura}
        title={'Estrutura'}
      />
      <CardEtapaObra
        progress={alvenariaProgress}
        iconAddress={Alvenaria}
        title={'Alvenaria'}
      />
      <CardEtapaObra
        progress={revestimentoProgress}
        iconAddress={Revestimento}
        title={'Revestimento'}
      />
      <CardEtapaObra
        progress={acabamentoProgress}
        iconAddress={Acabamento}
        title={'Acabamento'}
      />
    </AcompanhamentoStyled>
    <TotalProgress progress={totalProgress} />
  </>
  );
}
