import styled from 'styled-components';
import { ItemOnGrid } from '../../components/divider_grid/divider_grid.type';

export const DadosImovel = styled.div`
p{
  /* margin-bottom: 5px; */
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  span{
    font-weight: 600;
  }
}
`
export const SelectStyled = styled.select`
  width: auto;
  min-width: 170px;
  height: 48px;
  padding: 5px;
  border-bottom: 1px solid #A0A4A5;
  font-size: ${(props) => props.theme.layout.text.large};
  color: ${(props) => props.theme.layout.color.complementares.gray_600};
  line-height: 22px;
  font-weight: 500;
  background: transparent;
  box-sizing: border-box;

  `

export const PageTitleStyled = styled.h2<ItemOnGrid>`
  // padding: 3% 0;
  width: 100%;
  grid-area: 1 / 1 / 2 / -1;


  ${(props) => {
    const {
      theme: { layout },
    } = props;

    return `
  display: flex;
  justify-content: space-between;
  ${layout.head.headline3}
  svg{
    color: ${layout.color.main_colors.blue_300};
  }

`;
  }}
`;


export const TituloGaleriaDeFotos = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;

.titulo{
  width: 75%;
  span{
    margin-bottom: 30px;
  }
}
.select{
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 25px;
  padding-top: 5px;
  p{
    width: 100%;
    text-align: end;
    position: relative;
      top: 5px;
      left: 0;
      font-size: ${(props) => props.theme.layout.text.xSmall};
      line-height: 10px;
      color: ${(props) => props.theme.layout.color.complementares.green};
  }
}

@media screen and (max-width: 768px){
  .titulo, .select {
  width: 100%;
}

.titulo{
  span{
    margin-bottom: 15px;
  }
}

.select {
  margin-bottom: 25px;
}
}
`
