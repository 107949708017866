import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IDadosVenda {
  idlan: number;
  numVenda: number;
  dataVenda: string;
  bloco: string;
  unidade: string;
  empreendimento: string;
  cliente: string;
  conjuge: string;
  valorVenda: number;
  percentualPago: number;
  percentualDevido: number;
  status: null;
}

export default async function BuscaDadosVenda(
  codcolcfo: string,
  codcfo: string,
  numVenda: number,
  access_token: string,
): Promise<IDadosVenda | void> {
  try {
    const urlDadosDaVenda = `ClienteVenda/DadosClienteVenda?_codcolcfo=${codcolcfo}&_codcfo=${codcfo}&_numVenda=${numVenda}`;

    const dadosDaVenda: AxiosResponse<IDadosVenda> =
      await axiosInstance.get(urlDadosDaVenda, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    if (!dadosDaVenda)
      throw new Error('Nenhuma unidade foi encontrada.');

    return dadosDaVenda.data;
  } catch (e) {
    return
  }
}
