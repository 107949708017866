import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export type IBoletoPDF = string;

// export type IBuscaBoletos = IBoleto[];

export default async function BoletoPDF(
  codcoligada: number,
  idLan: number,
  access_token: string,
): Promise<IBoletoPDF> {
  try {
    const urlBoletoPDF = `ClienteVenda/BuscaBoletoPdf?_codColigada=${codcoligada}&_idlan=${idLan}`;

    const boletoPDFAPI: AxiosResponse<IBoletoPDF> = await axiosInstance.get(
      urlBoletoPDF,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );

    return boletoPDFAPI.data;
  } catch (e) {
    return '';
  }
}
