import styled from 'styled-components';

export const TempoWidgetStyle = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;

  .weather-box {
    margin: 0 25px 0 0;
    h5 {
      font-size: 34px;
      font-weight: 600;
      display:flex;
      span {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin: 5px 0 0 5px;
      }
    }
    .weather-icon {
      margin: -12px 0 0 38px;
    }
  }

  .date-box {
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    h5 {
      font-size: 14px;
      font-weight: 500;
    }
  }
`
