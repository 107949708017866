import styled from 'styled-components';
import { TTable } from './table.type';

export const TableFrame = styled.div`
  width: 100%;
  overflow-x: auto;

  .boxHandAnimation {
    padding: 20px 10px;
    display: none;
  }

  .boxHandAnimationIlus {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .boxHandAnimationText {
    margin: 15px 0 -5px;
    font-size: 11px;
    color: #d9d9d8;
  }

  img.arrowHand {
    margin: 0 0 0 12px;
  }
  img.handAnimation {
    /* animation: mymove 2s 3 forwards; */
    animation: mymove 2s infinite;
  }

  @keyframes mymove {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(40deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @media (max-width: 768px) {
    .boxHandAnimation {
      display: flex;
    }
  }

  @media print and (max-width: 768px) {
    overflow-x: unset;
    .boxHandAnimation {
      display: none;
    }
  }
`;

export const TableStyled = styled.table<TTable>`
  width: 100%;
  position: relative ;

  tbody,
  thead,
  tfoot {
    overflow-x: auto;
    display: block;
    font-size: 0.75rem;
    position: relative ;

    tr {
      display: flex;
      padding: 10px 20px;
      border-radius: ${(props) => (props.blue ? '0' : '0 5px')};
      font-weight: 400;
      position: relative;
      page-break-inside: avoid;
      margin: 12px 0;
      width: 100%;
      box-sizing: border-box ;
    }

    td {
      height: auto;
      display: inline-block ;
      width: ${(props) => {
        const num = Number(props.length);
        return (`calc(100% / ${num})`)}
      };
    }
    td:not(:first-child){
      text-align: center ;
    }
    td:last-child {
      text-align: right ;
    }

    td:last-child img {
      margin-right: 25px;
    }
    button {
      background: transparent;
    }
  }


  tr:not(:first-child) {
    background-color: ${(props) => (props.blue ? 'transparent' : '#fff')};
    border: ${(props) =>
      props.blue
        ? 'none'
        : '1px solid' + props.theme.layout.color.complementares.gray_200};
    color: ${(props) =>
      props.blue ? '#fff' : props.theme.layout.color.complementares.gray_800};
    &:hover {
      background: ${(props) =>
        props.blue
          ? 'rgba(255,255,255, 0.1) '
          : props.theme.layout.color.complementares.gray_200};
    }
    font-weight: ${(props) => (props.blue ? '300' : '400')};
  }

  .headersRow {
    background: ${(props) =>
      props.blue
        ? 'transparent'
        : props.theme.layout.color.background.blue} !important;
    color: white !important;
    margin: 0;
    height: auto;
  }
  .headersRow th {
    display: inline-block;
    font-weight: 600;
    text-align: left;
    line-height: 18px ;
    width: ${(props) => {
        const num = Number(props.length);
        return (`calc(100% / ${num})`)}
      };
  }
  .headersRow th:not(:first-child){
      text-align: center ;
    }
    .headersRow th:last-child {
      text-align: right ;
    }


  tfoot tr {
    display: block;
    background-color: ${(props) =>
      props.theme.layout.color.complementares.gray_200};
    border: ${(props) =>
      '1px solid' + props.theme.layout.color.complementares.gray_200};
    color: #363d40;
    margin-bottom: 30px;
    page-break-inside: avoid;
    font-weight: 500 !important ;

    &:hover {
      background: ${(props) =>
        props.theme.layout.color.complementares.gray_600};
      color: ${(props) => props.theme.layout.color.complementares.gray_200};
    }
    font-weight: ${(props) => (props.blue ? '300' : '400')};
    margin-top: 12px;
  }

  @media (max-width: 768px) {
    min-width: 680px;
  }

  @media print {
    min-width: unset;
    tbody,
    thead,
    tfoot {
      height: auto;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      tr{
        display: block ;
        page-break-inside: avoid;
      }
    }

    tbody::-webkit-scrollbar,
    thead::-webkit-scrollbar,
    tfoot::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    th {
      /* justify-content: end; */
      text-align: left;
    }

  }
`;
