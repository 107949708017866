import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';
import BuscaClienteSubunidade from './buscaClienteSubunidade';

export interface IBoletoAvulso {
  id: number;
  id_empreendimento: number;
  descricao: string;
  nomeArquivoExibicao: string;
  nomeArquivo: string;
  dtInicial: string;
  dtFinal: string;
  empreendimento: string;
  unidade: string;
  subunidade: string;
}

export type IBuscaBoletosAvulsos = IBoletoAvulso[];

async function BuscaBoletosAvulsosPorUnidade(
  id_empreendimento: number,
  unidade: string,
  subunidade: string,
  access_token: string,
) {

  try {
    const urlBoletosAvulsos = `ClienteVenda/BuscaBoletoAvulso?_id_empreendimento=${id_empreendimento}&_unidade=${unidade}&_subunidade=${subunidade}`;
    const boletosAvulsosAPI: AxiosResponse<IBuscaBoletosAvulsos | []> =
      await axiosInstance.get(urlBoletosAvulsos, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    boletosAvulsosAPI.data.forEach((e) => {
      e.dtInicial = e.dtInicial.split('T')[0];
      e.dtFinal = e.dtFinal.split('T')[0];
    });

    if (boletosAvulsosAPI.data.length == 0)
      throw new Error('Nenhum valor foi encontrado.');

    return boletosAvulsosAPI.data;
  } catch (e) {
    return [];
  }
}

export default async function BuscaBoletoAvulso(
  codcolcfo: string,
  codcfo: string,
  access_token: string,
): Promise<IBuscaBoletosAvulsos | []> {
  try {
    const apartamentos = await BuscaClienteSubunidade(
      codcolcfo,
      codcfo,
      103,
      access_token,
    );
    if (apartamentos.length === 0)
      throw new Error('Nenhum imóvel para essa função.');

    let res = [] as IBuscaBoletosAvulsos;
      //atualizado dia 16/01 para puxar no campo unidade o valor 'codUnidade'. nova demanda
    for (let i = 0; i < apartamentos.length; i++) {
      const docsPerUnits = await BuscaBoletosAvulsosPorUnidade(
        apartamentos[i].idEmpreendimento,
        apartamentos[i].codUnidade,
        apartamentos[i].subUnidade,
        access_token,
      );

      res = [...res, ...docsPerUnits];
    }

    return res;
  } catch (e) {
    return [];
  }
}
