import * as yup from 'yup';

const assistanceSchema = yup.object().shape({
  email: yup
    .string()
    .email('Insira um e-mail válido.')
    .required('Campo obrigatório'),
  empreendimento: yup
    .string()
    .required('Campo obrigatório'),
  bloco: yup.string().required('Campo obrigatório'),
  unidade: yup.string().required('Campo obrigatório'),
});

export { assistanceSchema };
