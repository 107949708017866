import React, { useEffect, useState } from 'react';
import { ArrowsIcon } from '../../assets/menu_icons';
import BasicBox from '../../components/box_card/box-card';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import PageTitle from "../../components/page's_title/page-title";
import Table from '../../components/table/table';
import { useAuth } from '../../Contexts/AuthContext';
import BuscaIndices, { ResBuscaIndices } from '../../util/buscaIndices';

export function IndicesReajuste() {
  const [indices, setIndices] = useState<
    ResBuscaIndices | Record<string, never>
  >();
  const [errorIndices, setErrorIndices] = useState(false);

  const { access_token, user } = useAuth();

  useEffect(() => {
    BuscaIndices(user.codcolcfo ?? '0', user.codcfo ?? '0', access_token)
      .then((res) => {
        if (res.igpm.length > 0 || res.incc.length > 0) {
          const newRes = {
            igpm: res.igpm.reverse(),
            incc: res.incc.reverse(),
          }
          return setIndices(newRes);
        }

        throw new Error();
      })
      .catch(() => setErrorIndices(true));
  }, []);

  return (
    <DividerGrid quantity={Quantity.two}>
      <PageTitle>
        <span>Índices</span>
        <img src={ArrowsIcon} alt="Ícone de setas opostas" />
      </PageTitle>
      {errorIndices ? (
        <p>
          Carregando unidades{' '}
          <span style={{ marginLeft: '15px' }}>
            <LoadingIcon />
          </span>
        </p>
      ) : (
        <>
          {indices?.igpm ? (
            <BasicBox gridAreaMobile="1 / 1 / 2 / 3">
              <Table
                headers={['Data', 'Índice', 'Variação %']}
                values={indices?.igpm.map((value) => [
                  value.Data,
                  value.Indice,
                  `${String(value.Valor.toFixed(2)).replace('.', ',')}`,
                ])}
              ></Table>
            </BasicBox>
          ) : null}
          {indices?.incc ? (
            <BasicBox gridAreaMobile="2 / 1 / 3 / 3">
              <Table
                headers={['Data', 'Índice', 'Variação %']}
                values={indices?.incc.map((value) => [
                  value.Data,
                  value.Indice,
                  `${String(value.Valor.toFixed(2)).replace('.', ',')}`,
                ])}
              ></Table>
            </BasicBox>
          ) : null}
        </>
      )}
    </DividerGrid>
  );
}
