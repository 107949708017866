import {
  ContentHeaderLogout,
  ContentPortal,
  ContentPortalMobile,
  HeaderStyled,
  ModalUser,
  HoverArrow,
} from './header.style';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import { HouseIcon, SignoutIcon } from '../../assets/menu_icons';
import AdmAcess from '../adm_acess_badget/adm_acess_badget';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';
import { User } from '../../Contexts/AuthContext.type';

import soter_logo from '../../assets/lobo_mob_header.svg';
import user_icon from '../../assets/user_icons/user.svg';
import arrow_right from '../../assets/user_icons/arrow_right.svg';
import arrow_down from '../../assets/user_icons/arrow_down.svg';

export default function Header() {
  const { setOpen } = useClienteSubunidade();
  const { signOut, user, users, ChangePerfil } = useAuth();
  const [showUserModal, setShowUserModal] = useState(false);
  const navigate = useNavigate();
  const admAcess = user?.acessoAdministrativo ?? false;

  const handlePerfil = async (userCodcolcfo: string, userCodcfo: string) => {
    await ChangePerfil(userCodcolcfo, userCodcfo);
    navigate('/');
  };

  const HandleSignOut = () => {
    signOut();
  };

  return (
    <HeaderStyled>
      {admAcess && <AdmAcess>Acesso administrativo</AdmAcess>}
      <ContentPortalMobile>
        <Link to="/" onClick={() => setOpen(false)}>
          <img
            src={soter_logo}
            alt="logo"
            style={{ width: '120px', margin: '0 0 0 15px' }}
          />
        </Link>
      </ContentPortalMobile>

      <ContentHeaderLogout>
        <img src={HouseIcon} alt="Ícone" />
        <Link to="/">
          <h2>Meu Soter</h2>
        </Link>
      </ContentHeaderLogout>
      <div style={{ display: 'flex', columnGap: '20px' }}>
        <ContentPortal
          onMouseEnter={() => {
            setShowUserModal(true);
          }}
          onMouseLeave={() => {
            setShowUserModal(false);
          }}
        >
          <div>
            <Link to="/meu-perfil" onClick={() => setOpen(false)}>
              <h3>
                {user?.nome.split(' ')[0]}{' '}
                {user?.nome.split(' ')[user?.nome.split(' ').length - 1]}
              </h3>
              <span style={{ cursor: 'pointer' }}>Meu Perfil</span>
            </Link>
          </div>
        </ContentPortal>

        {users.length > 1 ? (
          <>
            <HoverArrow
              showUserModal={showUserModal}
              onMouseEnter={() => {
                setShowUserModal(true);
              }}
              onMouseLeave={() => {
                setShowUserModal(false);
              }}
            >
              <img src={arrow_down} />
            </HoverArrow>
            <ModalUser
              showUserModal={showUserModal}
              onMouseEnter={() => {
                setShowUserModal(true);
              }}
              onMouseLeave={() => {
                setShowUserModal(false);
              }}
            >
              <div>
                <ul>
                  {users?.map((optionalUser: User) =>
                    optionalUser?.id !== user?.id ? (
                      <li
                        key={optionalUser.id}
                        onClick={() =>
                          handlePerfil(
                            optionalUser.codcolcfo,
                            optionalUser.codcfo,
                          )
                        }
                      >
                        <div>
                          <img src={user_icon} />
                          <span>{optionalUser.nome}</span>
                        </div>
                        <div>
                          <img src={arrow_right} />
                        </div>
                      </li>
                    ) : null,
                  )}
                </ul>
              </div>
            </ModalUser>
          </>
        ) : null}

        <ContentHeaderLogout
          style={{ cursor: 'pointer' }}
          onClick={HandleSignOut}
        >
          <img src={SignoutIcon} alt="Ícone" />
          <p>Sair</p>
        </ContentHeaderLogout>
      </div>
    </HeaderStyled>
  );
}
