import styled from "styled-components";

export const HeaderStyled = styled.div`
  width: 73.43%;
  height: 126px;
  border-radius: 0 0 0 90px ;
  background-color: ${props => props.theme.layout.color.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.03);

  h2 {
    font-size: 16px;
    color: ${props => props.theme.layout.color.main_colors.main};
  }

  h3 {
    font-size: 16px;
  }

  p{
    font-size: ${props => props.theme.layout.text.large};
  }

  span {
    font-size: ${props => props.theme.layout.text.small};
    color: ${props => props.theme.layout.color.complementares.gray_400}
  }

  img {
    margin-right: 10px
  }

  @media (max-width: 1024px) {
    width: 88.28%;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-height: unset;

    h3 {
      font-size: 14px;
    }
    span {
      font-size: 10px !important;
    }
  }

  @media print and (max-width: 768px){
    display: none;
  }

  `
export const ContentPortal = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 0 0 0 0;
  }

  a {
    color:${props => props.theme.layout.color.complementares.gray_800};
    transition: all .3s ease;
  }

  a:hover {
    color:${props => props.theme.layout.color.main_colors.blue_300};
  }

  span {
    ${props => props.theme.layout.text.small};
  }

  @media (max-width: 768px) {
    div {
      text-align: right;
      margin: 0 !important;
    }
  }

`
interface IProps {
  showUserModal?: boolean;
}

export const ModalUser = styled.div<IProps>`
    position: absolute;
    top: 84px;
    background-color: #FFF;
    border-radius: 0 0 0 15px;
    padding: 10px;
    transition: all .3s ease;
    transform: ${props => props.showUserModal ? `translateY(0%)` : `translateY(-10%)`};
    ${props => props.showUserModal ? `opacity: 1` : `opacity: 0`};
    ${props => props.showUserModal ? `visibility: visible` : `visibility: hidden`};

  img {
    margin-right: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:20px 0;
    border-bottom: 1px solid #CCCCCC;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  li:hover {
    background: rgba(217, 217, 216, 0.25);
  }

  li div {
    display: flex;
    align-items: center;
  }

  li span {
    display: block;
    padding: 0 10px;
    ${props => props.theme.layout.text.small};
  }

  li img:first-child {
    margin:0 0 0 5px;
  }

  li img:last-child {
    margin:0 5px 0 0;
  }
  @media (max-width: 768px) {
    width: calc(50% - 20px);
    left: 50%;
  }
`

export const HoverArrow = styled.div<IProps>`
 margin: 0 20px 0 10px;
 cursor: pointer;
 img {
  transition: all .5s ease;
  transform-origin: center center;
  transform: ${props => props.showUserModal ? `rotate(180deg)` : `rotate(0)`};
  margin-right: 0;
 }
`

export const ContentHeaderLogout = styled.div`
  cursor: pointer;
  display:flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none
  }
`

export const ContentPortalMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
