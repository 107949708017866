import { PageTitleStyled } from './page-title.style';
import { Props } from './page-title.type';

export default function PageTitle({children}: Props ) {
  return <>
    <PageTitleStyled>
      {children}
    </PageTitleStyled>
    </>;
}
