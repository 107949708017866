import React, { useEffect, useState } from 'react';
import { BarSVG, BarTrailSVG } from './total-progress.style';
import { Props } from './total-progress.type';

export default function TotalProgress({ progress }: Props) {
  const [value, setValue] = useState(0);

  const temp = progress;
  // const temp = progress / 2;

  useEffect(() => {
    setTimeout(() => {
      const inter = setInterval(() => {
        setValue((v) => {
          if (v < progress) {
            return v + 1;
          } else if (v > progress) {
            return v - 1;
          } else {
            clearInterval(inter);
            return v;
          }
        });
      }, temp);
    }, 800);
  }, [progress]);

  return (
    <>
      <BarTrailSVG width={'100%'} height={33}>
        <defs>
          <linearGradient id="gradientBackground">
            <stop offset="0%" stopColor="#48AF77" />
            <stop offset="100%" stopColor="#2E80C3" />
          </linearGradient>
        </defs>
        <BarSVG progress={value} fill={'url(#gradientBackground'} />
      </BarTrailSVG>
      <p style={{margin: '-24px 0 0 20px'}}>Total finalizado: {value}%</p>
    </>
  );
}
