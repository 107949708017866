import { ArrowsIcon, BuildingIcon, CalendarIcon, CopyIcon, DollarIcon, DownloadIcon, SingularIcon, ToolIcon } from "../assets/menu_icons"
import HouseIcon from '../assets/menu_icons/house-icon.svg'
import KeyIcon from '../assets/menu_icons/key-icon.svg';
import PencilIcon from '../assets/menu_icons/pencil-icon.svg';


interface ObjectProps {
    id: number;
    id_empreendimento: number;
    descricao: string;
    acessa: boolean;
  }

interface page {
    name: string
    icon: string
}

export const getPermissions = (input: ObjectProps[]) => {
    const output: number[] = []
    input.map(element =>{
        if (!output.includes(element.id)){
            output.push(element.id)
        }
    })
    return output
}

export const getMenuItens = (NumbersList: number[]) => {

    const pages: {[key:string]: page} = {
        '/': {
        name: "Página Inicial",
        icon: HouseIcon
        }
    }

    if (NumbersList.includes(101)) {
        pages['/ficha-financeira'] =  {
            name: "Ficha Financeira",
            icon: DollarIcon
        }
    }
    if (NumbersList.includes(102)) {
        pages['/pagamento'] =  {
            name: "Pagamento por Período",
            icon: CalendarIcon
        }
    }
    if (NumbersList.includes(103)) {
        pages['/boletos'] =  {
            name: "Boletos",
            icon: CopyIcon
        }
    }
    if (NumbersList.includes(104)) {
        pages['/documentos'] =  {
            name: "Documentos",
            icon: DownloadIcon
        }
    }
    if (NumbersList.includes(105)) {
        pages['/assistencia'] =  {
            name: "Assistência Técnica",
            icon: ToolIcon
        }
    }
    if (NumbersList.includes(106)) {
        pages['/meu-perfil'] =  {
            name: "Meu Perfil",
            icon: PencilIcon
        }
    }
    if (NumbersList.includes(107)) {
        pages['/singular'] =  {
            name: "Singular",
            icon: SingularIcon
        }
    }
    if (NumbersList.includes(108)) {
        pages['/indices'] =  {
            name: "Índices",
            icon: ArrowsIcon
        }
    }
    if (NumbersList.includes(109)) {
        pages['/acompanhamento-da-obra'] =  {
            name: "Acompanhamento de Obras",
            icon: BuildingIcon
        }
    }

    pages['/alterar-senha'] =  {
        name: "Alterar Senha",
        icon: KeyIcon
    }

    return pages
}
