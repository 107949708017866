import React, { ReactNode } from 'react';

export default function ErrorMessage({ children }: { children: ReactNode }) {
  return (
    <p
      style={{
        color: '#B00020',
        fontSize: '12px',
        fontWeight: '600',
        marginLeft: '8px',
        marginTop:'20px',
      }}
    >
      <span style={{ fontWeight: '700' }}>X</span> {children}
    </p>
  );
}
