import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export type IBloqueioFichaFinanceira = {
  bloqueado: boolean;
  dtFinal: string;
};

export default async function IsUserBlocked(
  access_token: string,
): Promise<{ isBlocked: boolean; status: number }> {
  try {
    const res: AxiosResponse<IBloqueioFichaFinanceira> =
      await axiosInstance.get('ClienteVenda/BuscaBloqueioFichaFinanceira', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    return { isBlocked: res.data.bloqueado, status: res.status };
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.status == 401) {
      return { isBlocked: true, status: 401 };
    }

    return { isBlocked: true, status: 0 };
  }
}
