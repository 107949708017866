import styled from 'styled-components';
import { ItemOnGrid } from '../../components/divider_grid/divider_grid.type';

export const DivDateInputs = styled.div<ItemOnGrid>`
${(props) => props.gridArea && "grid-area:" + props.gridArea};

@media screen and (max-width:780px){
  ${(props) => props.gridAreaMobile && "grid-area:" + props.gridAreaMobile};
}
`
export const DadosUsuario = styled.div`
  .dados{
    display: flex;
    justify-content: space-between;

    div {
      width: 32%;
    }

    div.icones {
      text-align: right;
    }
  }
/* display: grid;
grid-template-columns: 50% 50%;
grid-template-rows: auto;
grid-template-areas: "_cliente _empreendimento" "_conjuge _bloco" ". _unidade" "_periodo ." "_valor .";
/* grid-gap: 5px; */

/* ._cliente{
  grid-area: _cliente;
} */
/* ._empreendimento{
  grid-area: _empreendimento;
} */
/* ._conjuge{
  margin:0 0 30px 0;
} */
/* ._bloco{
  grid-area: _bloco;
} */
/* ._unidade{
  grid-area: _unidade;
} */
/* ._periodo{
  grid-area: _periodo;
}  */

/* ._valor{
  grid-area: _valor;
}  */

p{
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  span{
    font-weight: 600;
  }
}

@media screen and (max-width:768px){
  .dados {
    display: flex;
    flex-direction: column;
    padding:40px 0 0 0;

    div {
      width: 100% !important;
    }
  }
  .icones {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .data_e_valor {
    margin-top: 38px;
  }
}

@media print{
  .dados{
    flex-direction: column;

    div {
      width: 100%;
    }

    div.icones {
      text-align: right;
    }
  }
}

`

// export const Instructions = styled.p`
// Subtítulo 1/Montserrat/Medium/16px
// `

export const SubTitle = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
  margin: 38px 0 25px;
`
