import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IParcela {
  empreendimento: string;
  idlan: number;
  numVenda: number;
  dataVencimento: string;
  dataVencimentoDiaUtil: string;
  valorPrincipal: number;
  valorAtual: number;
  valordevido: number;
  valorpago: number;
  valorEncargo: number;
  valorDescapitalizacao: number;
  dataPagamento: string | null;
  status: string;
  idBaixa: number;
  codcoligada: number;
}

export type IBuscaParcela = IParcela[];

export type IFichaFinanceira = {
  atrasadas: IBuscaParcela | [];
  emAberto: IBuscaParcela | [];
  quitadas: IBuscaParcela | [];
};

export default async function BuscaParcelasUnidade(
  codcolcfo: string,
  codcfo: string,
  numVenda: number,
  access_token: string,
): Promise<IFichaFinanceira> {
  try {
    const urlParcelas = `ClienteVenda/BuscaParcelas?_codcolcfo2=${codcolcfo}&_codcfo2=${codcfo}&_numVenda2=${numVenda}`;

    const parcelasAPI: AxiosResponse<IBuscaParcela | []> =
      await axiosInstance.get(urlParcelas, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    return {
      atrasadas: parcelasAPI.data.filter((e) => e.status === 'Atrasada'),
      emAberto: parcelasAPI.data.filter((e) => e.status === 'Em Aberto'),
      quitadas: parcelasAPI.data.filter((e) => e.status === 'Quitada'),
    };
  } catch (e) {
    return {
      atrasadas: [],
      emAberto: [],
      quitadas: [],
    };
  }
}
