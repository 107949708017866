// imports defaults
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// imports components
import AppButton from '../../components/button/button';
import PageTitle from "../../components/page's_title/page-title";
import { LoginLayoutStyled, SitesButtons } from './bem-vindo.style';

// imports images and icons
import soterLogoMobile from '../../assets/logo_mobile.svg';
import soter_logo from '../../assets/logo_login_branca.svg';
import lineLogin from '../../assets/line-login.svg';
import personIcon from '../../assets/menu_icons/person-icon.svg';
import toolIcon from '../../assets/menu_icons/tool-icon.svg';
import ArrowLogin from '../../assets/buttons-icons/arrow-login.svg';
import { useAuth } from "../../Contexts/AuthContext";

export function BemVindo() {
  const navigate = useNavigate();
  const { getUserIp } = useAuth();

  useEffect(() => {
    getUserIp();
  }, []);

  return (
    <LoginLayoutStyled>
      <div>
        <img
          width="173"
          className="logomobile"
          src={soterLogoMobile}
          alt="logo"
        />
        <div className="space" style={{ marginTop: '100px' }}></div>
        <PageTitle>Bem-vindo(a)</PageTitle>

        <div>
          <SitesButtons>
            <div className="boxMeu">
              <img src={personIcon} alt="meu Soter" />
              <span className="nameButton">Meu Soter</span>
            </div>
            <div className="buttonMobile">
              <Link to={'/login'} rel="noopener noreferrer" target="_blank">
                <AppButton login text="entrar" animation="moving">
                  <img src={ArrowLogin} alt="seta para direita" />
                </AppButton>
              </Link>
            </div>
            <p>
              Acompanhe a evolução da obra do seu Soter, sua situação
              financeira, acesse a segunda via do boleto, entre outros.
            </p>
          </SitesButtons>
          <SitesButtons>
            <div className="boxMeu">
              <img src={toolIcon} alt="assistencia tecnica" />
              <span className="nameButton">Assistência Técnica</span>
            </div>
            <div className="buttonMobile">
              <Link
                to={'/assistencia'}
                rel="noopener noreferrer"
                target="_blank"
              >
                <AppButton login text="entrar" animation="moving">
                  <img src={ArrowLogin} alt="seta para direita" />
                </AppButton>
              </Link>
            </div>
            <p>
              Canal desenvolvido para proprietários, administradores e
              inquilinos, que buscam auxílio técnico na unidade Soter pronta e
              acabada.
            </p>
          </SitesButtons>
        </div>

      </div>
      <div className="right">
        <img className="lineLogin" src={lineLogin} />
        <img className="logoDesktop" src={soter_logo} alt="logo" />
      </div>
    </LoginLayoutStyled>
  );
}
