import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../header/header';
import MenuMobile from '../menu_mobile/menu_mobile';
import Nav from '../nav/nav';
import { ContentDiv, PageLayoutStyled } from './page_layout.style';
import { Props } from './page_layout.type';

export default function PageLayout({ children }: Props) {
  const url = useLocation();

  return (
    <PageLayoutStyled>
      {url.pathname == '/login' ||
      url.pathname == '/primeiro-acesso' ||
      url.pathname == '/impressao' ||
      url.pathname == '/bem-vindo' ||
      url.pathname == '/assistencia' ? (
        <>{children}</>
      ) : (
        <>
          <Nav small={false} />
          <ContentDiv>
            <Header />
            {children}
          </ContentDiv>
          <MenuMobile />
        </>
      )}
    </PageLayoutStyled>
  );
}
