import styled from 'styled-components';
import { Quantity, Size } from './divider_grid.type';

interface IProp {
  quantity: Quantity
  size?: Size
  children: React.ReactNode
}

export const DividerGridStyled = styled.div<IProp>`
  padding: 0 5%;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  padding-bottom: 100px;

  grid-template-columns: ${(props) => props.quantity == Quantity.three ? "1fr 1fr 1fr" : props.quantity == Quantity.two ? "1fr 1fr" : "1fr" };
  grid-template-rows: auto;
  grid-auto-rows: auto;

  gap: 27px 4%;

  .homeTitle h2{
    font-size: 34px;
    padding-bottom:40px;
  }

  .atStart{
    justify-self: start;
  }

  .atEnd{
    justify-self: end;
  }

  .buttonsPerfil {
    display: flex;
    grid-column: 1/4;
  }

  .marginButtonsPerfil {
    margin-right: 20px;
  }

  @media screen and (max-width:768px){
    grid-template-columns: 1fr 1fr;
    .homeTitle h2{
      font-size: 24px;
      text-align: center;
      padding-bottom:30px;
    }
    .buttonsPerfil {
      flex-direction: column;
    }
    .marginButtonsPerfil {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .atStartMob {
      justify-self: start !important;
    }
  }

  @media print {
    padding: 0 0;
    width: 100%;
    position: relative;

    div.gridButton {
      display: none ;
    }
  }

`;


