import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  #root {
    /* height: 100%; */
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input, select {
    border: none;
    outline: none;
  }
  body,html {
    width:100%;
    height:auto;
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    background-color: ${(props) =>
      props.theme.layout.color.complementares.gray_200};
    a:link { text-decoration: none; };
  };

  button{
    border: none;
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
  }

  h2 {
    font-weight: 500;
    font-size: 34px;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
  }

  button {
    font-size: 14px;
    font-weight: 400
    }


  .gridButton {
    display: flex;
    justify-content: flex-end;
  }
  .gridDadosImovel {
    grid-area: 3 / 3 / 5 / 4;
  }

  .linkBoxImageAcompObra{
    width: 17%;
    min-width: 160px;
    height: 130px;
    margin: 0 3% 15px 0;
    border-radius: 0 15px;
    overflow: hidden;
  }

  @media(max-width: 375px) {
    .linkBoxImageAcompObra{
      width: 100%;
    margin: 0 0% 20px 0;
    }
  }


    @media(max-width: 768px) {
      .climatempo{
        display: none;
      }
      .gridDadosImovel {
        grid-area: 3 / 1 / 4 / 4;
      }
    }


    @media all {
  .page-break {
    display: none;
  }

}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    .break-to-next-page{
    page-break-after:always;
}
  }

  .disapear-for-print {
    display: none;
  }
}

  @media print {
  .page-break {
    display: block;
    break-before: always;
  }
  @page {
    size: auto;
    margin-top: 1.5cm;
    margin-bottom: 1.5cm;
  }
  /* @page :first {
    margin-top: 0;
  } */
  @page :footer {
    display: none !important;
    }
  @page :header {
     display: none !important;
  }
  header, footer {
    display: none;
  }

}

@page {
  size: auto;
  /* margin: 1cm 15mm; */
}

.break-to-next-page{
  break-after:page;
}
`;
