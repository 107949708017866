import axios, { AxiosResponse } from 'axios';
import { createContext, useCallback, useContext, useState } from 'react';
import axiosInstance from '../module/axios.module';
import qs from 'qs';
import { getMenuItens, getPermissions } from '../util/getMenuItens';
import {
  AuthContextData,
  AuthProviderProps,
  AuthState,
  Empreendimento,
  functionalitiesDTO,
  IToken,
  page,
  SignInCredentials,
  User,
  UserData,
} from './AuthContext.type';
import md5 from 'md5';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [users, setUsers] = useState<User[]>(() => {
    const users = localStorage.getItem('@Soter:Users');
    return users ? (JSON.parse(users) as User[]) : ([] as User[]);
  });
  const [data, setData] = useState<AuthState>(() => {
    const access_token: string | null =
      localStorage.getItem('@Soter:AccessToken');
    const validate_token: string | null = localStorage.getItem(
      '@Soter:ValidateToken',
    );
    const user: string | null = localStorage.getItem('@Soter:User');
    const userData: string | null = localStorage.getItem('@Soter:UserData');

    if (validate_token) {
      const now = new Date();
      const tokenDate = new Date(validate_token);
      const msBetweenDates = Math.abs(tokenDate.getTime() - now.getTime());
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates >= 24) {
        localStorage.removeItem('@Soter:AccessToken');
        localStorage.removeItem('@Soter:ValidateToken');
        localStorage.removeItem('@Soter:User');
        localStorage.removeItem('@Soter:MenuItens');
        return {} as AuthState;
      }
    }

    if (access_token && user && userData) {
      return {
        access_token,
        user: JSON.parse(user) as User,
        userData: JSON.parse(userData) as UserData,
      };
    }

    return {} as AuthState;
  });

  const [menuList, setMenuList] = useState<number[]>(() => {
    const menu: string | null = localStorage.getItem('@Soter:MenuList');

    if (menu) {
      return JSON.parse(menu) as number[];
    }

    return [];
  });

  const [menuItens, setMenuItens] = useState<{ [key: string]: page }>(() => {
    const menu: string | null = localStorage.getItem('@Soter:MenuItens');

    if (menu) {
      return JSON.parse(menu) as { [key: string]: page };
    }

    return {};
  });

  const [assistenceToken, setAssistenceToken] = useState<IToken | null>(null);
  const [userIp, setUserIp] = useState(
    localStorage.getItem('@Soter:UserIp') ?? '',
  );

  const salt_key: string = process.env.REACT_APP_SALT_KEY || '';

  const reqUserId = useCallback(async () => {
    const options = {
      url: 'https://api.ipify.org',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    try {
      const ip: AxiosResponse<string, Record<string, never>> = await axios(
        options,
      );
      return ip.data;
    } catch (error) {
      return '';
    }
  }, []);

  const getUserIp = useCallback(() => {
    reqUserId()
      .then((ip) => {
        setUserIp(() => ip);
        localStorage.setItem('@Soter:UserIp', ip);
      })
      .catch((e: string) => setUserIp(e));

    return userIp;
  }, []);

  const signIn = useCallback(async ({ email, senha }: SignInCredentials) => {
    const _senha = md5(senha + salt_key);

    const ip = localStorage.getItem('@Soter:UserIo') || userIp || getUserIp();

    const DTO = {
      grant_type: 'password',
      _ip: ip,
      _tokenAPI: process.env.REACT_APP_TOKEN_ASSISTENCIA,
    };

    try {
      const token: AxiosResponse<IToken> = await axiosInstance.post(
        'token',
        qs.stringify(DTO),
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
      );

      const access_token = token?.data.access_token;

      const response: { data: User[] } = await axiosInstance.get(
        `Cliente/Login?_email=${email}&_senha=${_senha}&_ip=${DTO._ip}`,
        {
          headers: {
            Authorization: `Bearer ${String(access_token)}`,
          },
        },
      );

      setUsers(response.data);

      const functionalities: functionalitiesDTO = await axiosInstance.get(
        `Cliente/BuscaFuncionalidadesAcesso?_codcolcfo=${response.data[0].codcolcfo}&_codcfo=${response.data[0].codcfo}`,
        {
          headers: {
            Authorization: `Bearer ${String(access_token)}`,
          },
        },
      );

      const valuesList: number[] = getPermissions(functionalities.data);

      setMenuList(valuesList);

      const menu = getMenuItens(valuesList);

      setMenuItens(menu);

      const userData: { data: UserData } = await axiosInstance.get(
        `Cliente/DadosCadastrais?_codcolcfo=${response.data[0].codcolcfo}&_codcfo=${response.data[0].codcfo}`,
        {
          headers: {
            Authorization: `Bearer ${String(access_token)}`,
          },
        },
      );

      setData((prev) => {
        return {
          access_token,
          user: response.data[0],
          userData: userData.data,
        };
      });
      console.log('data', data, '\n\n\n');

      localStorage.setItem('@Soter:Users', JSON.stringify(response.data));
      localStorage.setItem('@Soter:AccessToken', access_token);
      localStorage.setItem('@Soter:User', JSON.stringify(response.data[0]));
      localStorage.setItem('@Soter:UserData', JSON.stringify(userData.data));
      localStorage.setItem('@Soter:MenuList', JSON.stringify(valuesList));
      localStorage.setItem('@Soter:MenuItens', JSON.stringify(menu));
      localStorage.setItem('@Soter:ValidateToken', new Date().toUTCString());

      return response.data;
    } catch {
      //não retornando erro
      return 'error';
    }
  }, []);

  const ChangePerfil = useCallback(
    async (userCodcolcfo: string, userCodcfo: string) => {
      const functionalities: functionalitiesDTO = await axiosInstance.get(
        `Cliente/BuscaFuncionalidadesAcesso?_codcolcfo=${userCodcolcfo}&_codcfo=${userCodcfo}`,
        {
          headers: {
            Authorization: `Bearer ${String(data.access_token)}`,
          },
        },
      );

      const valuesList: number[] = getPermissions(functionalities.data);

      setMenuList(valuesList);

      const menu = getMenuItens(valuesList);

      setMenuItens(menu);

      const userData: { data: UserData } = await axiosInstance.get(
        `Cliente/DadosCadastrais?_codcolcfo=${userCodcolcfo}&_codcfo=${userCodcfo}`,
        {
          headers: {
            Authorization: `Bearer ${String(data.access_token)}`,
          },
        },
      );
      const choosenUserIndex = users.findIndex(
        (user) =>
          user.codcolcfo === userCodcolcfo && user.codcfo === userCodcfo,
      );

      localStorage.removeItem('@Soter:User');
      localStorage.removeItem('@Soter:MenuItens');
      localStorage.removeItem('@Soter:MenuList');
      localStorage.setItem(
        '@Soter:User',
        JSON.stringify(users[choosenUserIndex]),
      );
      localStorage.setItem('@Soter:MenuList', JSON.stringify(valuesList));
      localStorage.setItem('@Soter:MenuItens', JSON.stringify(menu));

      setData({
        ...data,
        user: users[choosenUserIndex],
        userData: userData.data,
      });
    }, [data]);

  const firstAccess = useCallback(async (email: string) => {
    const password = await axiosInstance.get<string>(
      `Cliente/ResetarSenha?_email=${email}`,
    );
    return password;
  }, []);

  const updatePassword = useCallback(async (email: string, senha: string) => {
    const _senha = md5(senha + salt_key);

    await axiosInstance
      .get(`/Cliente/AlterarSenha?_email=${email}&_novaSenha=${_senha}`, {
        headers: {
          Authorization: `Bearer ${String(data.access_token)}`,
        },
      })
      .then(() => {
        setData((prev) => {
          return {
            ...prev,
            user: { ...prev.user, primeiroAcesso: false },
          };
        });
        localStorage.removeItem('@Soter:User');
        localStorage.setItem(
          '@Soter:User',
          JSON.stringify({ ...data.user, primeiroAcesso: false }),
        );
      })
      .catch(() => {
        throw new Error('Erro ao tentar alterar senha.');
      });
  }, [data]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Soter:AccessToken');
    localStorage.removeItem('@Soter:User');
    localStorage.removeItem('@Soter:Users');
    localStorage.removeItem('@Soter:MenuItens');
    localStorage.removeItem('@Soter:MenuList');
    localStorage.removeItem('@Soter:UserData');
    localStorage.removeItem('@Soter:ValidateToken');

    setData({} as AuthState);
    setMenuItens({} as { [key: string]: page });
  }, []);

  const getAssistenciaDados = useCallback(async () => {
    const ip = localStorage.getItem('@Soter:UserIo') || userIp || getUserIp();

    const DTO = {
      grant_type: 'password',
      _ip: ip,
      _tokenAPI: process.env.REACT_APP_TOKEN_ASSISTENCIA,
    };

    try {
      const token: AxiosResponse<IToken> = await axiosInstance.post(
        '/token',
        qs.stringify(DTO),
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        },
      );
      const { access_token } = token.data;
      setAssistenceToken(token.data);

      const response: { data: Empreendimento[] } = await axiosInstance.get(
        '/Empreendimento/DadosUnidades',
        {
          headers: {
            Authorization: `Bearer ${String(access_token)}`,
          },
        },
      );

      return response.data;
    } catch (erro) {
      console.error(erro);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        access_token: data.access_token,
        user: data.user,
        users,
        userData: data.userData,
        menuList,
        menuItens,
        signIn,
        signOut,
        firstAccess,
        updatePassword,
        ChangePerfil,
        getAssistenciaDados,
        assistenceToken,
        userIp,
        getUserIp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
