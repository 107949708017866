import React, { ReactNode } from 'react';
import { SubtituloStyled } from './subheaders.style';

export type SubTitulo1Props = {
  children: ReactNode
  color?: string
  gridArea?: string
  gridAreaMobile?: string

}
export default function SubTitulo1({children, color, gridArea, gridAreaMobile}: SubTitulo1Props ){
  return (
    <SubtituloStyled color={color} gridArea={gridArea} gridAreaMobile={gridAreaMobile}>
      {children}
    </SubtituloStyled>
  )
}
