import styled from 'styled-components';

export const LoadingIconStyled = styled.img`
  width: 15px;
  height: 15px;

  -webkit-animation: spin 0.8s linear infinite !important;
  -moz-animation: spin 0.8s linear infinite !important;
  animation: spin 0.8s linear infinite !important;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const ConfirmedIconStyled = styled.img`
  width: 15px;
  height: 15px;

  -webkit-animation: showUp 0.8s linear 1 !important;
  -moz-animation: showUp 0.8s linear 1 !important;
  animation: showUp 0.8s linear 1 !important;

  @-moz-keyframes showUp {
    from {
      -moz-transform: scale(0);
    }
    to {
      -moz-transform: scale(1);
    }
  }
  @-webkit-keyframes showUp {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  @keyframes showUp {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const NextStepStyled = styled.img`
  width: 15px;
  height: 15px;

  /* -webkit-animation: moving 1.8s linear infinte;
  -moz-animation: moving 1.8s linear infinte;
  animation: moving 4s linear infinite;
  animation-play-state: ${(props) => props ? 'running' : 'paused'};
  animation-delay: 2s;

  @-moz-keyframes moving {
    0%, 10%, 25%, 40%, 55%, 80%, 100% {-moz-transform: translateX(0);}
    18% {-moz-transform: translateX(10px);}
    32% {-moz-transform: translateX(5px);}
  }
  @-webkit-keyframes moving {
    0%, 10%, 25%, 40%, 55%, 80%, 100% {-webkit-transform: translateX(0);}
    18% {-webkit-transform: translateX(10px);}
    32% {-webkit-transform: translateX(5px);}
  }
  @keyframes moving {
    0%, 10%, 25%, 40%, 55%, 80%, 100% {transform: translateX(0);}
    18% {transform: translateX(10px);}
    32% {transform: translateX(5px);}
  } */
`;


export const DeniedIconStyled = styled.img`
  width: 14px;
  height: 13px;

  /* &:hover{
  -webkit-animation: rotateAnim 0.8s linear 1 !important;
  -moz-animation: rotateAnim 0.8s linear 1 !important;
  animation: rotateAnim 0.8s linear 1 !important;
}

  @-moz-keyframes rotateAnim {
    from {
      -moz-transform: rotate(0);
    }
    to {
      -moz-transform: rotate(1turn);
    }
  }
  @-webkit-keyframes rotateAnim {
    from {
      -webkit-transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(1turn);
    }
  }
  @keyframes rotateAnim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  } */
`;
