import React, { ForwardedRef, forwardRef } from "react";
import { BasicBoxStyled } from './box-card.styled';
import { Props } from './box-card.type';

const BasicBox = ({children, gridArea, gridAreaMobile, blue = false, gradient = false, toPrint = false} : Props, ref : ForwardedRef<HTMLDivElement | null> ) => {
  return (
  <BasicBoxStyled toPrint={toPrint} ref={ref} blue={blue} gradient={gradient} gridArea={gridArea} gridAreaMobile={gridAreaMobile}>
  {children}
  </BasicBoxStyled> 
  )
}

export default forwardRef(BasicBox);

