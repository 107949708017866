import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IPagamento {
  empreendimento: string;
  idlan: number;
  numVenda: number;
  dataVencimento: string;
  dataVencimentoDiaUtil: string;
  valorPrincipal: number;
  valorAtual: number;
  valordevido: number;
  valorpago: number;
  dataPagamento: string;
  status: string;
  idBaixa: number;
  codcoligada: number;
}

export type IPagamentoPorPeriodo = IPagamento[];

interface Argumentos {
  codcolcfo: string;
  codcfo: string;
  numVenda: number;
  dataInicio: string;
  dataFim: string;
  access_token: string;
}

export default async function BuscaPagamentoPorPeriodo({
  codcolcfo,
  codcfo,
  numVenda,
  dataInicio,
  dataFim,
  access_token,
}: Argumentos): Promise<IPagamentoPorPeriodo | []> {
  try {
    const urlParcelas = `ClienteVenda/BuscaParcelasPeriodo?_codcolcfo3=${codcolcfo}&_codcfo3=${codcfo}&_numVenda3=${numVenda}&_dataInicio=${dataInicio}&_dataFim=${dataFim}`;

    const parcelasAPI: AxiosResponse<IPagamentoPorPeriodo | []> =
      await axiosInstance.get(urlParcelas, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    return parcelasAPI.data;
  } catch (e) {
    return [];
  }
}
