import styled from 'styled-components';
import { TDiv } from './box-card.type';

export const BasicBoxStyled = styled.div<TDiv>`
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.blue ? '20px 0' : '30px')};
  border-radius: 0 15px;
  background: ${(props) =>
    props.blue ? props.theme.layout.color.background.dark_blue : props.gradient ? props.theme.layout.color.background.green : '#fff'};
  box-sizing: border-box;
  /* overflow: hidden; */
  ${(props) => props.gridArea && 'grid-area:' + props.gridArea + ';'}
  -webkit-box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.03);
  position: relative;
  display: ${(props) => props.toPrint ? 'block' : 'flex'};
  flex-direction: column;
  color: ${(props) => props.gradient ? '#FFF' : props.theme.layout.color.complementares.gray_800};

  .perfil-content {
    margin-bottom: 20px ;
  }

  .perfil-content p {
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => props.theme.layout.color.complementares.gray_800};
    span {
      font-weight: 600;
    }
  }

  .onlyForPrint {
      display: none;
    }

  @media screen and (max-width: 768px) {
    padding: ${(props) => (props.blue ? '10px' : '16px')};
    ${(props) => props.gridAreaMobile && 'grid-area:' + props.gridAreaMobile};
    .perfil-content{
      margin-bottom: 20px;
    }
  }

  @media Print {
    height: auto;
    display: ${(props) => props.blue ? 'none' : 'block'};
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
    grid-area:  1/ 1/ -1/-1;
    padding: 0 20mm !important ;
    box-shadow: unset;
    -webkit-box-shadow: unset;
    border-radius: unset ;

    .icones {
      display: none !important;
    }
    .onlyForPrint {
      display: flex !important;
    }
    .headerPrint {
      width: 100%;
      align-items: center;
      margin:0 0 40px 0;
      padding:0 0 20px 0;
      border-bottom: 1px solid #1C4584;
      div {
        width: 33%;
      }
      div:nth-child(2) {
        font-size: 1rem;
        text-align: center;
        font-weight: 500;
        color: #1C4584;
    	}
    }
  }

`;
