import { InputStyled, LabelStyled } from './input.style';
import { Props } from './input.type';

// imports images and icons
import passhide from '../../assets/passhide.svg';
import passview from '../../assets/passview.svg';

export default function Input({
  type,
  name,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  icon,
  errorText,
  value,
  editable = true,
  eyes,
  setEyes,
}: Props) {

  return (
    <LabelStyled errorText={errorText} editable={editable}>
      <>
        {icon ?
        icon :
        <p className='label'>{placeholder}</p>
        }
        <InputStyled
          type={type}
          name={name}
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          disabled={!editable}
          value={value}
        />
        <p className="errorText">{errorText}</p>
      
        { setEyes ? ( 
            <img style={{cursor: "pointer", marginRight: "8px",   position: "absolute",
            right: 0}} onClick={() => setEyes(!eyes)} src={eyes? passhide : passview } alt="olho aberto" />
          ) : null
        }
        
      </>
    </LabelStyled>
  );
}
