import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';


export default async function BuscaIP(): Promise<string> {
  try {
    const urlOpenSourceFunction = 'https://api.ipify.org';

    const resIP: AxiosResponse<string> = await axiosInstance.get(
      urlOpenSourceFunction,
      {
        headers: {
          'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        },
      },
    );

    return resIP.data;
  } catch (e) {
    return '';
  }
}
