import styled from 'styled-components'

export const Personalize = styled.div`
  p.personalize{
    font-size: 1rem;
    line-height: 21px; 
  }

  .singularLogo {
    margin: -10px 0 20px 0;
  }

`