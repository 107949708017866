import styled from 'styled-components';

export const SubTitleOneStyle = styled.h5`
${(props) => {
  const { theme: { layout: {color, text}}} = props;

  return `
  font-size: ${text.large};
  font-weight: 600;
  line-height: 25px;
  color: ${color.main_colors.main};
  // background-color: green;
 `
 }
}
`
