import React from 'react'
import { LoadingIconStyled } from './icon-animations.styles'
import Loading from '../../../assets/buttons-icons/loading.svg'

export default function LoadingIcon(){
  return (
    <LoadingIconStyled alt='Ícone de carregamento da informação' src={Loading}>
    </LoadingIconStyled>
  )
}
