import React from 'react'
import { DeniedIconStyled } from './icon-animations.styles'
import Denied from '../../../assets/buttons-icons/error-icon.svg'

export default function DeniedIcon(){
  return (
    <DeniedIconStyled alt='Ícone de carregamento da informação' src={Denied}>
    </DeniedIconStyled>
  )
}
