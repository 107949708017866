export const head = {
  xSmall: '20px',
  small: '24px',
  medium: '34px',
  large: '48px',
  headline1: 'font-size: 48px; line-height: 36px; font-weight: 400;',
  headline2: 'font-size: 34px; line-height: 36px; font-weight: 600;',
  headline3: `
  color: #1C4584;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  `,
  headline4: 'font-size: 20px; line-height: 36px; font-weight: 700;',
};

export const text = {
  xSmall: '10px',
  small: 'font-size: 12px; line-height: 16px;',
  medium: 'font-size: 14px; line-height: 21px;',
  large: '16px',
};

export const color = {
  white: '#FFFFFF',
  black: '#000',
  main_colors: {
    main: '#1C4584',
    blue_800: '#07233E',
    blue_700: '#0F2E4E',
    blue_500: '#1C4584',
    blue_300: '#2E80C3',
  },
  complementares: {
    green: '#48AF77',
    gray_200: '#F4F4F3',
    gray_400: '#A0A4A5',
    gray_600: '#707070',
    gray_800: '#363D40',
  },
  alert: {
    red_alert: '#B00020',
  },
  background: {
    gray: 'rgba(217,217,216,0.2)',
    dark_blue:
      'transparent linear-gradient(91deg, #07233E 0%, #092541 19%, #0F2E4E 100%) 0% 0% no-repeat padding-box',
    blue: 'transparent linear-gradient(124deg, #0F2E4E 0%, #1C4584 100%) 0% 0% no-repeat padding-box',
    blueSecondary:
      'transparent linear-gradient(236deg, #0F2E4E 0%, #1C4584 100%) 0% 0% no-repeat padding-box',
    green:
      'linear-gradient(135deg, rgba(46,128,195,1) 0%, rgba(72,175,119,1) 180%);',
  },
};

export const layout = {
  head,
  text,
  color,
};
