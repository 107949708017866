import styled from 'styled-components';

interface IProp {
  small: boolean
}

export const ButtonStyled = styled.div<IProp>`
  width: 100%;
  height: 60px;
  padding-left: 8%;
  text-align: left;
  border: none;
 
  color: ${props => props.theme.layout.color.white};
  font-size: ${props => props.theme.layout.text.large};
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all .3s ease;

  ${props => props.small ? `span{display:none}` : `span{display:inline-block}`};

  .buttonContainer {
    max-width: 370px; 
    width: 100%;
    display: flex;
    align-items: center;
  }

  img{
    max-height: 25px;
    // width: 100%;
    // max-width: 100%;
  }
  &:hover {
    border-right: 10px solid ${(props) => props.theme.layout.color.main_colors.blue_300};
    background: transparent linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  };
  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.layout.color.white};
  };
  span {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    margin-left: 20px;
  }
  @media screen and (max-width:1024px){
    justify-content: center;
    padding-left: 0;
    &:hover {
    padding-left: 5px;
    border-right-width: 5px;
    }
    span {
      display: none;
    }
    text-align: center;
  }

  @media (min-width: 1280px) {
    // width: 370px;
  }
`;
