import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import BuildingIcon from '../../assets/menu_icons/building-icon.svg';
import SubTitulo1 from '../../components/fonts_components/sub-headers/subtitulo1';
import { layout } from '../../themes/layout';
import BasicBox from '../../components/box_card/box-card';
import AcompanhamentoObra from '../../components/acompanhamento_obra/acompanhamento-obra';
import {
  DadosImovel,
  PageTitleStyled,
  SelectStyled,
  TituloGaleriaDeFotos,
} from './acompanhamento-obra.style';
import {
  ContainerTable,
  HeaderTable,
} from '../../components/inputs/input.style';
import RadioUnits from '../../components/inputs/input-radio';
import BuscaClienteSubunidade, {
  IBuscaUnidade,
  IUnidade,
} from '../../util/buscaClienteSubunidade';
import { useAuth } from '../../Contexts/AuthContext';
import AcompanhamentoDeObra, {
  IAcompanhamentoObra,
} from '../../util/acompanhamentoDeObra';
import { formatoData } from '../../util/formatoData';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import AppButton from '../../components/button/button';
import NextStepIcon from '../../components/button/buttons_icons_animations/next-step';
import PageTitle from "../../components/page's_title/page-title";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';
import Youtube from '../../components/acompanhamento_obra/video/video';

export function BuildingProgress() {
  const [data, setData] = useState<IUnidade | Record<string, never>>({});
  const [error, setError] = useState({
    unidadeSelecionada: '',
  });
  const [valores, setValores] = useState<IAcompanhamentoObra>({
    id: 0,
    codColigada: 0,
    codEmpreendimento: 0,
    Acabamento: 0.0,
    Revestimento: 0.0,
    Alvenaria: 0.0,
    Estrutura: 0.0,
    Fundacao: 0.0,
    PreparoTerreno: 0.0,
    TotalFinalizado: 0,
    DataAlteracao: '',
    PrevEntrega: '',
    UsuarioAlteracao: '',
    Embed: '',
    Links: [{ Links: [], Periodo: '' }],
  });
  const [periodo, setperiodo] = useState<number>(0);

  const { apartamentosAcompanhamento, setApartamentosAcompanhamento } =
    useClienteSubunidade();
  const [errorValores, setErrorValores] = useState(false);

  const [waitingReq, setWaitingReq] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [loadingValues, setLoadingValues] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);

  const [index, setIndex] = useState(-1);

  const images: { Periodo: string; Links: string[] } = valores.Links[periodo];

  const currentImage = !images ? '' : images.Links[index];
  const nextIndex = !images ? 0 : (index + 1) % images.Links.length;
  const nextImage = !images ? '' : images.Links[nextIndex] || currentImage;
  const prevIndex = !images
    ? 0
    : (index + images.Links.length - 1) % images.Links.length;
  const prevImage = !images ? '' : images.Links[prevIndex] || currentImage;

  const handleClick = (index: number, image: string) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const { access_token, user } = useAuth();

  useEffect(() => {
    setLoadingUnits(true);
    BuscaClienteSubunidade(
      user.codcolcfo ?? '0',
      user.codcfo ?? '0',
      109,
      access_token,
    )
      .then((res) => {
        if (res.length > 0) {
          setLoadingUnits(false);
          return setApartamentosAcompanhamento(res as IBuscaUnidade);
        }

        throw new Error();
      })
      .catch(() => {
        setLoadingUnits(false);
        return setApartamentosAcompanhamento([]);
      });
  }, []);

  const handleOpenForm = useCallback(async () => {
    try {
      setLoadingReq(true);
      await yup
        .object()
        .shape({
          numVenda: yup
            .number()
            .moreThan(0)
            .required('Selecione um empreendimento.'),
        })
        .validate(data, { abortEarly: false });

      setLoadingReq(false);
      setWaitingReq(false);
      setperiodo(0);
    } catch (yupError: unknown) {
      setLoadingReq(false);
      setWaitingReq(true);

      if (yupError instanceof yup.ValidationError) {
        yupError.inner.forEach((error: yup.ValidationError) => {
          const key = error.path;
          const value = error.message;
          if (key) {
            setError((prevData) => ({
              ...prevData,
              unidadeSelecionada: value,
            }));
          }
        });
      }
    }
  }, [data]);

  const handleOptions = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    setperiodo(Number(target.value));
  };

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setWaitingReq(true);
      setError((prevData) => ({
        ...prevData,
        [target.name]: '',
      }));

      const res = apartamentosAcompanhamento.find(
        (item) => item.numVenda === parseInt(target.value),
      );

      if (res) {
        setData(res);
        setLoadingValues(true);
        AcompanhamentoDeObra(res.idEmpreendimento, access_token)
          .then((res) => {
            const newRes = { ...res, valores: res.Links.reverse() };
            setLoadingValues(false);

            return setValores(newRes);
          })
          .catch(() => {
            setLoadingValues(false);

            return res.numVenda && setErrorValores(true);
          });
      }
    },
    [
      apartamentosAcompanhamento,
      setErrorValores,
      setData,
      data,
      errorValores,
      valores,
      setValores,
    ],
  );

  return (
    <DividerGrid quantity={Quantity.three}>
      <PageTitle>
        <span>Acompanhamento de Obras</span>
        <img src={BuildingIcon} alt="Ícone de um prédio" />
      </PageTitle>
      <SubTitulo1
        color={
          error.unidadeSelecionada == ''
            ? layout.color.complementares.gray_600
            : layout.color.alert.red_alert
        }
        gridArea={'2 / 1 / 3 / 2'}
        gridAreaMobile={'1 / 1 / 2 / 4'}
      >
        Selecione o empreendimento
      </SubTitulo1>

      <div>
        {currentImage && (
          <Lightbox
            mainSrc={currentImage}
            mainSrcThumbnail={currentImage}
            nextSrc={nextImage}
            nextSrcThumbnail={nextImage}
            prevSrc={prevImage}
            prevSrcThumbnail={prevImage}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>

      <BasicBox
        blue
        gridArea={'3 / 1 / 4 / 3'}
        gridAreaMobile={'2 / 1 / 3 / 4'}
      >
        {!apartamentosAcompanhamento.length ? (
          loadingUnits ? (
            <p style={{ color: '#fff', padding: '0 0 0 15px' }}>
              Carregando unidades{' '}
              <span style={{ marginLeft: '15px' }}>
                <LoadingIcon />
              </span>
            </p>
          ) : (
            <p style={{ color: 'white', marginLeft: '15px' }}>
              Não há unidades disponíveis no momento.
            </p>
          )
        ) : (
          <>
            <ContainerTable>
              <HeaderTable>
                <p className="header">Empreendimento</p>
                <p className="header">Bloco</p>
                <p className="header">Unidade</p>
              </HeaderTable>

              {apartamentosAcompanhamento.map((unit) => (
                <RadioUnits
                  key={unit.numVenda}
                  name="unidadeSelecionada"
                  value={unit.numVenda}
                  onChange={handleChange}
                >
                  <p>{unit.nomeEmpreendimento}</p>
                  <p>{unit.unidade}</p>
                  <p>{unit.subUnidade}</p>
                </RadioUnits>
              ))}
            </ContainerTable>
          </>
        )}
      </BasicBox>
      <div
        className="gridButton"
        style={{
          gridArea: '4 / 3 / 5 / 4',
        }}
      >
        <AppButton text="atualizar" onClick={handleOpenForm} className="atEnd">
          <NextStepIcon />
        </AppButton>
      </div>

      {loadingReq ? (
        <p style={{ gridArea: '5 / 1 / 6 / 4' }}>
          Carregando
          <span style={{ marginLeft: '15px' }}>
            <LoadingIcon />
          </span>
        </p>
      ) : null}

      {waitingReq ? null : loadingValues ? (
        <BasicBox gridArea="5 / 1 / 6 / 4" gridAreaMobile={'5 / 1 / 6 / 4'}>
          <p>
            Carregando
            <span style={{ marginLeft: '15px' }}>
              <LoadingIcon />
            </span>
          </p>
        </BasicBox>
      ) : (
        <>
          <BasicBox gridArea="5 / 1 / 6 / 4" gridAreaMobile={'5 / 1 / 6 / 4'}>
            <AcompanhamentoObra
              terrenoProgress={valores.PreparoTerreno}
              fundacaoProgress={valores.Fundacao}
              estruturaProgress={valores.Estrutura}
              alvenariaProgress={valores.Alvenaria}
              revestimentoProgress={valores.Revestimento}
              acabamentoProgress={valores.Acabamento}
              totalProgress={valores.TotalFinalizado}
            ></AcompanhamentoObra>
          </BasicBox>
          <div className="gridDadosImovel">
            <DadosImovel>
              <p>
                <span>Empreendimento:</span> {data.nomeEmpreendimento}
              </p>
              <div></div>
              <p>
                <span>Bloco:</span> {data.unidade}
              </p>
              <div></div>
              <p>
                <span>Unidade:</span> {data.subUnidade}
              </p>
              <div></div>
              <p>
                <span>Previsão de entrega:</span>{' '}
                {valores.PrevEntrega ? formatoData(valores.PrevEntrega) : ''}
              </p>
            </DadosImovel>
          </div>
          {valores.Links[0].Links.length > 0 && (
            <BasicBox gridArea="6 / 1 / 7 / 4" gridAreaMobile={'6 / 1 / 7 / 4'}>
              <TituloGaleriaDeFotos>
                <div className="titulo">
                  <PageTitleStyled>
                    <span>Galeria de fotos</span>
                  </PageTitleStyled>
                </div>
                <div className="select">
                  <p>Selecione um período</p>
                  <SelectStyled name="periodo" onChange={handleOptions}>
                    {valores.Links.map(
                      (e: { Periodo: string; Links: string[] }, n) => (
                        <option key={n} value={n} selected={n === 0}>
                          {e.Periodo}
                        </option>
                      ),
                    )}
                  </SelectStyled>
                </div>
              </TituloGaleriaDeFotos>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexWrap: 'wrap',
                  justifyContent: 'start',
                }}
              >
                
                {valores.Embed ? <Youtube srcVideoIframe={valores.Embed}/> : null}

                {valores.Links[periodo].Links.map((e, n) => (
                  <a
                    onClick={() => handleClick(n, e)}
                    key={n}
                    className="linkBoxImageAcompObra"
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: '#dedede',
                        backgroundImage: `url(${e})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        cursor: 'pointer',
                      }}
                    ></div>
                  </a>
                ))}
              </div>
            </BasicBox>
          )}
        </>
      )}
    </DividerGrid>
  );
}
