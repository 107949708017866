import React from 'react';
import { LogoContainer, NavStyled } from './nav.style';
import { Props } from './nav.type';
import Button_nav from '../button_nav/button_nav';
import soter_logo from '../../assets/logo_menu_branca.svg';
import soter_red from '../../assets/logo_reduzida_branca.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

export default function Nav({ small }: Props) {

  const { menuItens } = useAuth()
  
  return (
    <NavStyled small={small}>
        <div className='menuContainer'>
          <LogoContainer className='logoGrande'>

          <Link to='/'><img src={soter_logo} alt="logo soter" style={{maxHeight: '76px', maxWidth: '180px'}}/></Link>
          </LogoContainer>
          <LogoContainer className='logoReduzida'>
          <img src={soter_red} alt="logo soter" style={{maxHeight: '37px'}}/>
          </LogoContainer>

          { Object.keys(menuItens).map((element, n) => {

            if (n>=1 && element!= '/meu-perfil' && element!= '/alterar-senha') {
              return(
                <Button_nav key={n}
                small={false}
                href={element}
                text={menuItens[element]?.name}
                >
                <img src={menuItens[element]?.icon} alt="Ícone" />
              </Button_nav>
              )
            }
            return
            })
          }
      </div>
    </NavStyled>
  );
}
