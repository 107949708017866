import { SelectStyled, LabelStyled } from './input.style';
import { TSelect } from './input.type';

export default function Select({
  name,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  errorText,
  options,
  value,
  ...restProps
}: TSelect) {


  return (
    <LabelStyled errorText={errorText} editable={true}>
      <>
        <p className="label">{placeholder}</p>
        <SelectStyled
          value={value}
          name={name}
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          {...restProps}
          // editable={true}
        >
          <option defaultValue={value}>{name === "empreendimento" ? "Escolha o empreendimento" : name === "bloco" ? "Escolha o bloco" : '000000'}</option>
          {options?.map((opt, indx) => (
            <option key={indx}>
              {opt}
            </option>
          ))}
        </SelectStyled>

        <p className="errorText">{errorText}</p>


      </>
    </LabelStyled>
  );
}
