import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';
import BuscaClienteSubunidade from './buscaClienteSubunidade';
import { formatoMesAno } from './formatoData';

export interface IIndice {
  Data: string;
  Indice: string;
  Valor: number;
}

export type IBuscaIndices = IIndice[];

async function BuscaValores(indice: string, access_token: string) {
  const urlIndices = `ClienteVenda/BuscaIndice?_Indice=${indice}`;

  try {
    const indicesAPI: AxiosResponse<IBuscaIndices | []> =
      await axiosInstance.get(urlIndices, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      indicesAPI.data.forEach((e) => e.Data = formatoMesAno(e.Data));

    if (indicesAPI.data.length <= 0)
      throw new Error('Nenhum valor foi encontrado.');

    return indicesAPI.data;
  } catch (e) {
    return [];
  }
}

export interface ResBuscaIndices {
  igpm: IBuscaIndices;
  incc: IBuscaIndices;
}

export default async function BuscaIndices(
  codcolcfo: string,
  codcfo: string,
  access_token: string,
): Promise<ResBuscaIndices | Record<string, never>> {
  try {
    const apartamentos = await BuscaClienteSubunidade(
      codcolcfo,
      codcfo,
      108,
      access_token,
    );
    if (apartamentos.length === 0)
      throw new Error('Nenhum imóvel para essa função.');

    const indiceIGPM = await BuscaValores('IGPM', access_token);

    const indiceINCC = await BuscaValores('INCC', access_token);

    return { igpm: indiceIGPM, incc: indiceINCC };
  } catch (e) {
    return {};
  }
}
