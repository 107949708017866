import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

export type Props = {
    children: JSX.Element
  };

export default function Guard({ children }: Props) {
  const {access_token} = useAuth()
  const navigate = useNavigate();

  useEffect(
        () => {
            if (!access_token) {
                navigate("/bem-vindo");
            }
        },
        [access_token],
    );

  return children;
}
