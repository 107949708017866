import styled from "styled-components";

export const VideoContainer = styled.div`
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

   iframe {
    position: absolute;
    width: 100%; 
    height: 100%; 
    border-radius: 10px;
    border: none;
    }

    @media (max-width: 1020px){
    margin: 60px auto;
    width: 100%
  }
`