import React, { ChangeEvent, useCallback, useState } from 'react';
import AppButton from '../button/button';
import DividerGrid from '../divider_grid/divider_grid';
import { Quantity } from '../divider_grid/divider_grid.type';
import SubTitulo1 from '../fonts_components/sub-headers/subtitulo1';
import Input from '../inputs/input';
import TextArea from '../inputs/input-textarea';
import PageTitle from "../page's_title/page-title";
import { Props } from './form-meu-perfil.type';
import PencilIcon from '../../assets/menu_icons/pencil-icon.svg';
import { useAuth } from '../../Contexts/AuthContext';
import { updateUserDataSchema } from './form-meu-perfil.shape';
import { ValidationError } from 'yup';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import { useNavigate } from 'react-router-dom';
import { FormGrid } from './form-meu-perfil.style';
import NextStepIcon from '../button/buttons_icons_animations/next-step';
import DeniedIcon from '../button/buttons_icons_animations/denied-icon';
import axiosInstance from '../../module/axios.module';
import { AxiosError } from 'axios';
import { formatoData } from '../../util/formatoData';

export default function FormMeuPerfil({ setEdit }: Props) {
  const { userData } = useAuth();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [worked, setWorked] = useState('');
  const [error, setError] = useState('');
  const [errorApi, setErrorApi] = useState('');

  const [data, setData] = useState(userData);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setWorked('');
      setError('');
      setData((prevData) => ({
        ...prevData,
        [target.name]: target.value,
      }));
    },
    [setData],
  );

  const sendEmail = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setErrorApi(() => '');
    setLoading(true);
    e.preventDefault();
    try {
      const validated = await updateUserDataSchema.validate(data);
      const compareDatas = {
        oldData: {...userData, dataNascimento: formatoData(userData.dataNascimento)},
        newData: {...validated, dataNascimento: ""},
      };

      const res = await axiosInstance.post(
        'https://soter-six.vercel.app/api/cliente/alteracaoMeuPerfil',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(compareDatas),
        },
      );

      setLoading(false);
      if (res.status === 200) {
        setWorked(
          'Dados enviados com sucesso. Em breve seus dados serão atualizados.',
        );
        return setTimeout(() => {
          setEdit(false);
          navigate('/');
        }, 3000);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        setError(error.errors[0]);
      }
      if (error instanceof AxiosError) {
        setErrorApi(
          `Dados não enviados, por favor entre em contato no e-mail contato@soter.com.br`,
        );
      }
      setLoading(false);
    }
  };

  return (
    <DividerGrid quantity={Quantity.two}>
      <PageTitle>
        <span>Alterar Perfil</span>
        <img src={PencilIcon} alt="Ícone de uma caneta" />
      </PageTitle>
      <div style={{ gridColumn: '1/3' }}>
        <SubTitulo1>Dados pessoais:</SubTitulo1>
      </div>
      <FormGrid>
        <div style={{ gridColumn: '1/5' }}>
          <Input
            name="identidade"
            type="text"
            placeholder="RG"
            onChange={handleChange}
            errorText=""
            value={data.identidade}
          />
        </div>
        <div style={{ gridColumn: '5/9' }}>
          <Input
            name="estadoCivil"
            type="text"
            placeholder="Estado civil"
            onChange={handleChange}
            errorText=""
            value={data.estadoCivil}
          />
        </div>
        <div style={{ gridColumn: '1/3' }}>
          <Input
            name="orgaoEmissor"
            type="text"
            placeholder="Órgão Emissor"
            onChange={handleChange}
            errorText=""
            value={data.orgaoEmissor}
          />
        </div>
        <div style={{ gridColumn: '3/5' }}>
          <Input
            name="ufEmissor"
            type="text"
            placeholder="UF emissora"
            onChange={handleChange}
            errorText=""
            value={data.ufEmissor}
          />
        </div>
      </FormGrid>

      <FormGrid>
        <div className="logradouro">
          <Input
            name="logradouro"
            type="text"
            placeholder="Logradouro"
            onChange={handleChange}
            errorText=""
            value={data.logradouro}
          />
        </div>
        <div className="numero">
          <Input
            name="numero"
            type="text"
            placeholder="Número"
            onChange={handleChange}
            errorText=""
            value={String(data.numero)}
          />
        </div>
        <div className="complemento">
          <Input
            name="complemento"
            type="text"
            placeholder="Complemento"
            onChange={handleChange}
            errorText=""
            value={String(data.complemento)}
          />
        </div>
        <div className="bairro">
          <Input
            name="bairro"
            type="text"
            placeholder="Bairro"
            onChange={handleChange}
            errorText=""
            value={data.bairro}
          />
        </div>
        <div className="cidade">
          <Input
            name="cidade"
            type="text"
            placeholder="Cidade"
            onChange={handleChange}
            errorText=""
            value={data.cidade}
          />
        </div>
        <div className="estado">
          <Input
            name="uf"
            type="text"
            placeholder="UF"
            onChange={handleChange}
            errorText=""
            value={data.uf}
          />
        </div>
        <div className="cep">
          <Input
            name="cep"
            type="text"
            placeholder="CEP"
            onChange={handleChange}
            errorText=""
            value={data.cep}
          />
        </div>
        <div className="pais">
          <Input
            name="pais"
            type="text"
            placeholder="País"
            onChange={handleChange}
            errorText=""
            value={data.pais}
          />
        </div>
      </FormGrid>
      <div style={{ gridColumn: '1/3' }}>
        <SubTitulo1>Contato:</SubTitulo1>
      </div>

      <FormGrid>
        <div className="telresidencial">
          <Input
            name="telefoneResidencial"
            type="text"
            placeholder="Telefone Residencial"
            onChange={handleChange}
            errorText=""
            value={data.telefoneResidencial}
          />
        </div>
        <div className="telcomercial">
          <Input
            name="telefoneComercial"
            type="text"
            placeholder="Telefone Comercial"
            onChange={handleChange}
            errorText=""
            value={data.telefoneComercial}
          />
        </div>
        <div className="telcelular">
          <Input
            name="telefoneCelular"
            type="text"
            placeholder="Telefone Celular"
            onChange={handleChange}
            errorText=""
            value={data.telefoneCelular}
          />
        </div>
        <div className="email">
          <Input
            name="email"
            type="text"
            placeholder="E-mail"
            onChange={handleChange}
            errorText=""
            value={data.email}
          />
        </div>

        <div className="observacao">
          <TextArea
            name="obs"
            placeholder="Observação"
            onChange={handleChange}
            errorText=""
          />
        </div>
      </FormGrid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gridColumn: '1/3',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: '-60px' }}>
          {loading ? (
            <span style={{ marginLeft: '15px' }}>
              <LoadingIcon />
            </span>
          ) : null}
          {error ? (
            <p style={{ color: '#d00020', padding: '10px 0' }}>{error}</p>
          ) : errorApi ? (
            <p style={{ color: '#d00020', padding: '10px 0' }}>{errorApi}</p>
          ) : worked ? (
            <p style={{ color: '#48af77', padding: '10px 0' }}>{worked}</p>
          ) : null}
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <AppButton
            text="cancelar"
            onClick={() => setEdit(false)}
            animation="rotateAnim"
          >
            <DeniedIcon />
          </AppButton>
          <div style={{ width: '5%' }}></div>
          <AppButton text="enviar" onClick={sendEmail}>
            <NextStepIcon />
          </AppButton>
        </div>
      </div>
    </DividerGrid>
  );
}
