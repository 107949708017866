import * as yup from 'yup';

const updateUserDataSchema = yup.object().shape({
	logradouro: yup.string().nullable(),
	numero: yup.string(),
	bairro: yup.string().nullable(),
	uf: yup.string().nullable(),
	cep: yup.string().nullable(),
	complemento: yup.string().nullable(),
	cidade: yup.string().nullable(),
	pais: yup.string().nullable(),
	telefoneCelular: yup.string().nullable(),
	telefoneResidencial: yup.string().nullable(),
	telefoneComercial: yup.string().nullable(),
	email: yup.string().email('Insira um e-mail válido.'),
	obs: yup.string().nullable()
});

export { updateUserDataSchema };