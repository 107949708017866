import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IBoleto {
  empreendimento: string;
  idlan: number;
  numVenda: number;
  dataVencimento: string;
  dataVencimentoDiaUtil: string;
  valorPrincipal: number;
  valorAtual: number;
  valordevido: number;
  valorpago: number;
  dataPagamento: string;
  status: string;
  idBaixa: number;
  codcoligada: number;
}

export type IBuscaBoletos = IBoleto[];

export default async function BuscaBoletos(
  codcolcfo: string,
  codcfo: string,
  idFuncionalidade: number,
  access_token: string,
): Promise<IBuscaBoletos | []> {
  try {
    const urlBoletos = `ClienteVenda/BuscaBoletos?_codcolcfo2=${codcolcfo}&_codcfo2=${codcfo}&_id_funcionalidade2=${idFuncionalidade}`;

    const boletosAPI: AxiosResponse<IBuscaBoletos | []> =
      await axiosInstance.get(urlBoletos, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    return boletosAPI.data;
  } catch (e) {
    return [];
  }
}
