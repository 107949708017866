import React from 'react';
import { ButtonStyled } from './button_nav.style';
import { Props } from './button_nav.type';
import { Link } from 'react-router-dom'

export default function Button_nav({ children, text, small, href }: Props) {
  return (
    <Link to={href}>
      <ButtonStyled small={small}>
        <div className='buttonContainer'>
          {children}
          <span>{text}</span>
        </div>
      </ButtonStyled>
    </Link>
  );
}
