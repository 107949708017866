import React from 'react';
import { LabelStyled, TextAreaStyled } from './input.style';
import { TTextArea } from './input.type';

export default function TextArea({name, placeholder, errorText, onChange, editable = true}: TTextArea){
  return (
    <LabelStyled errorText={errorText} editable={editable} style={{borderBottom: "none"}}>
        <p className='label'>{placeholder}</p>
        <TextAreaStyled
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={!editable}
          style={{borderBottom: "1px solid #A0A4A5"}}
        />
        <p className="errorText">{errorText}</p>
    </LabelStyled>
  )
}
