import React from 'react'
import { NextStepStyled } from './icon-animations.styles'
import ArrowRight from '../../../assets/buttons-icons/arrow-right.svg'

export default function NextStepIcon(){
  return (
    <NextStepStyled alt='Ícone de carregamento da informação' src={ArrowRight}>
    </NextStepStyled>
  )
}
