// imports defaults
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';
import axiosInstance from '../../module/axios.module';

// imports components
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import CardOne from '../../components/box_card_one/card_one';
import SubtitleOne from '../../components/Titles/subtitle-one/subtitle-one';
import IconSubTitle from '../../components/Titles/icon-subtitle/icon-subtitle';
import BasicBox from '../../components/box_card/box-card';
import Table from '../../components/table/table';
import AppButton from '../../components/button/button';
import TempoWidget from '../../components/tempo-widget/tempo-widget';

// imports icons and images
import { CopyIcon, DollarIcon } from '../../assets/menu_icons';
import { CloudCloudIcon, PerfilCardIcon } from '../../assets/card_icons';
import NextStepIcon from '../../components/button/buttons_icons_animations/next-step';
import DownloadArrow from '../../assets/download_arrow.svg';
import BuscaBoletos from '../../util/buscaBoletos';
import BuscaBoletoAvulso from '../../util/buscaBoletosAvulsos';
import DownloadDocumentos, {
  IContrato,
  IDocumento,
} from '../../util/downloadDocumentos';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import InstanceOfDocument from '../../util/instanceOfDocumento';

export function Home() {
  const [boletosCount, setBoletosCount] = useState(0);
  const [loadingBoletos, setLoadingBoletos] = useState(false);
  const { access_token, user, userData, menuList } = useAuth();
  const navigate = useNavigate();
  const [errorDocs, setErrorDocs] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);

  const [documentos, setDocumentos] = useState<
    Array<IDocumento | IContrato> | []
  >([]);
  const [invoice, setInvoice] = useState({
    title: '',
    base64pdf: '',
  });
  const [loadingContratoPDF, setLoadingContratoPDF] = useState(false);
  const DonwloadButtonRef = useRef<HTMLAnchorElement>(null);

  const handleDownload = async (binDocumento: string, title: string) => {
    setLoadingContratoPDF(true);
    try {
      setInvoice({ title: title.replaceAll(' ','_'), base64pdf: binDocumento });

      const res = await fetch(`data:application/pdf;base64,${binDocumento}`);
      const blob = await res.blob();
      const newUrl = window.URL.createObjectURL(blob);

      DonwloadButtonRef.current?.setAttribute(
        'href',
        newUrl,
      );
      btoa;
      DonwloadButtonRef.current?.setAttribute('target', '_blank');
      DonwloadButtonRef.current?.setAttribute('rel', 'noopener noreferrer');
      DonwloadButtonRef.current?.click();

      setLoadingContratoPDF(false);
    } catch (e) {
      setLoadingContratoPDF(false);
    }
  };

  useEffect(() => {
    if (user) {
      setLoadingBoletos(true);
      setLoadingDocs(true);
      BuscaBoletos(user.codcolcfo ?? '0', user.codcfo ?? '0', 103, access_token)
        .then((res) => {
          setBoletosCount((prev) => prev + res.length);
        })
        .catch(() => setBoletosCount((prev) => prev));

      BuscaBoletoAvulso(user.codcolcfo ?? '0', user.codcfo ?? '0', access_token)
        .then((res) => {
          setBoletosCount((prev) => prev + res.length);
          setLoadingBoletos(false);
        })
        .catch(() => {
          setBoletosCount((prev) => prev);
          setLoadingBoletos(false);
        });

      DownloadDocumentos(
        user.codcolcfo ?? '0',
        user.codcfo ?? '0',
        access_token,
      )
        .then((res) => {
          if (res.length > 0) {
            setLoadingDocs(false);
            return setDocumentos(res);
          }

          throw new Error();
        })
        .catch(() => {
          setLoadingDocs(false);
          return setErrorDocs(true);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!access_token) {
      navigate('/login');
    }

    if (user && user.primeiroAcesso) {
      navigate('/alterar-senha');
    }
  }, [access_token]);

  return (
    <>
      <DividerGrid quantity={Quantity.three}>
        <div
          className="homeTitle"
          style={{ width: '100%', gridArea: '1 / 1 / 2 / -1' }}
        >
          <h2>Bem vindo, {user?.nome.split(' ')[0]}</h2>
        </div>

        {menuList.includes(103) && menuList.includes(101) ? (
          <>
            <Link to={'/boletos'}>
              <CardOne>
                {loadingBoletos ? (
                  <div className="loadingAlert">
                    <span>
                      <LoadingIcon />
                    </span>
                  </div>
                ) : boletosCount > 0 ? (
                  <div className="boletosAlert">
                    <span>{boletosCount}</span>
                  </div>
                ) : null}
                <img src={CopyIcon} alt="Ícone" />
                <div>
                  <SubtitleOne>Boletos</SubtitleOne>
                  <p>Acesse aqui os seus boletos disponíveis</p>
                </div>
              </CardOne>
            </Link>
            <Link to="/ficha-financeira">
              <CardOne>
                <img src={DollarIcon} alt="Ícone" />
                <div>
                  <SubtitleOne>Ficha financeira</SubtitleOne>
                  <p>Acompanhe o pagamento das suas parcelas</p>
                </div>
              </CardOne>
            </Link>
          </>
        ) : menuList.includes(103) ? (
          <div style={{ gridArea: '2/1/3/3' }}>
            <Link to={'/boletos'}>
              <CardOne>
                {boletosCount > 0 ? (
                  <div className="boletosAlert">
                    <span>{boletosCount}</span>
                  </div>
                ) : null}
                <img src={CopyIcon} alt="Ícone" />
                <div>
                  <SubtitleOne>Boletos</SubtitleOne>
                  <p>Acesse aqui os seus boletos disponíveis</p>
                </div>
              </CardOne>
            </Link>
          </div>
        ) : menuList.includes(101) ? (
          <div style={{ gridArea: '2/1/3/3' }}>
            <Link to="/ficha-financeira">
              <CardOne>
                <img src={DollarIcon} alt="Ícone" />
                <div>
                  <SubtitleOne>Ficha financeira</SubtitleOne>
                  <p>Acompanhe o pagamento das suas parcelas</p>
                </div>
              </CardOne>
            </Link>
          </div>
        ) : (
          <div style={{ gridArea: '2/1/3/3' }}></div>
        )}

        <BasicBox gradient gridAreaMobile="5 / 1 / 6 / 3">
          <TempoWidget />
        </BasicBox>

        {menuList.includes(104) ? (
          <BasicBox gridArea="3 / 1 / 4 / 3">
            <div
              style={{
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <IconSubTitle>
                  <img src={CloudCloudIcon} alt="Ícone" />
                  <SubtitleOne>Downloads de documentos</SubtitleOne>
                </IconSubTitle>
                {loadingDocs ? (
                  <LoadingIcon />
                ) : errorDocs ? (
                  <p>Não há itens disponíveis no momento.</p>
                ) : (
                  <Table
                    download={true}
                    headers={['Download', 'Empreendimento', ' ', 'Arquivo']}
                    values={
                      documentos
                        ? documentos.map((e) => {
                            if (InstanceOfDocument(e)) {
                              return [
                                loadingContratoPDF ? (
                                  <LoadingIcon />
                                ) : (
                                  <a
                                    style={{
                                      cursor: 'pointer',
                                      display: 'block',
                                      width: '100%',
                                      color: '#363D40',
                                      height: '100%',
                                    }}
                                    onClick={() =>
                                      handleDownload(e.binDocumento, e.nome)
                                    }
                                  >
                                    <img src={DownloadArrow} />
                                  </a>
                                ),
                                <a
                                  style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    width: '100%',
                                    color: '#363D40',
                                    height: '100%',
                                  }}
                                  onClick={() =>
                                    handleDownload(e.binDocumento, e.nome)
                                  }
                                >
                                  {e.empreendimento}
                                </a>,
                                <a>&nbsp;</a>,
                                <a
                                  style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    width: '100%',
                                    color: '#363D40',
                                    height: '100%',
                                  }}
                                  onClick={() =>
                                    handleDownload(e.binDocumento, e.nome)
                                  }
                                >
                                  {e.nome + '.pdf'}
                                </a>,
                              ];
                            } else {
                              if (e.descricao.length > 50) {
                                e.descricao =
                                  e.descricao.substring(0, 50) + '...';
                              }
                              const baseUrl = axiosInstance.getUri();
                              const Url = baseUrl.slice(0, baseUrl.length - 1);
                              const loading = e.nomeArquivo ? (
                                <a
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    color: '#363D40',
                                    height: '100%',
                                  }}
                                  href={Url + e.nomeArquivo}
                                  target={'_blank'}
                                >
                                  <img src={DownloadArrow} />
                                </a>
                              ) : (
                                <LoadingIcon />
                              );

                              return [
                                loading,
                                <a
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    color: '#363D40',
                                    height: '100%',
                                  }}
                                  href={Url + e.nomeArquivo}
                                  target={'_blank'}
                                >
                                  {e.empreendimento}
                                </a>,
                                <a>&nbsp;</a>,
                                <a
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    color: '#363D40',
                                    height: '100%',
                                  }}
                                  href={Url + e.nomeArquivo}
                                  target={'_blank'}
                                >
                                  {e.nomeArquivoExibicao}
                                </a>,
                              ];
                            }
                          })
                        : [['']]
                    }
                  />
                )}
              </div>
              {errorDocs ? null : (
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AppButton
                    onClick={() => navigate('/documentos')}
                    text="ver todos"
                    animation="moving"
                  >
                    <NextStepIcon />
                  </AppButton>
                </div>
              )}
            </div>
          </BasicBox>
        ) : (
          <div style={{ gridArea: '3 / 1 / 4 / 3' }}></div>
        )}

        <BasicBox gridAreaMobile="4 / 1 / 5 / 3">
          <IconSubTitle>
            <img src={PerfilCardIcon} alt="Ícone" />
            <SubtitleOne>Meu Perfil</SubtitleOne>
          </IconSubTitle>
          <div className="perfil-content">
            <p>
              <span>Nome:</span> {user?.nome}
            </p>
            <p>
              <span>E-mail:</span> {user?.email}
            </p>
            <p>
              <span>CPF:</span> {userData?.cpf}
            </p>
            <p>
              <span>Nacionalidade:</span> {userData?.nacionalidade}
            </p>
          </div>
          <div
            style={{
              marginTop: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <AppButton
              onClick={() => navigate('/meu-perfil')}
              text="acessar"
              animation="moving"
            >
              {/* {animated ? <LoadingIcon /> : <NextStepIcon/>} */}
              <NextStepIcon />
            </AppButton>
          </div>
        </BasicBox>
        <a
          ref={DonwloadButtonRef}
          download={invoice.title}
          href={`data:application/pdf;base64,${invoice.base64pdf}`}
          title="Download pdf document"
        />
      </DividerGrid>
    </>
  );
}
