import { createContext, useContext, useState } from "react";
import { IBuscaUnidade } from "../util/buscaClienteSubunidade";
import { ClienteSubunidadeContextData, ClienteSubunidadeProviderProps } from "./ClienteSubunidadeContext.type";


const ClienteSubunidadeContext = createContext<ClienteSubunidadeContextData>({} as ClienteSubunidadeContextData);

const useClienteSubunidade = () => {
  const context = useContext(ClienteSubunidadeContext);

  if (!context) {
    throw new Error('useClienteSubunidade must be used within an ClienteSubunidadeProvider');
  }

  return context;
};

const ClienteSubunidadeProvider = ({ children }: ClienteSubunidadeProviderProps) => {

    const [ apartamentosFicha, setApartamentosFicha ] = useState<IBuscaUnidade>([])
    const [ apartamentosPagamento, setApartamentosPagamento ] = useState<IBuscaUnidade>([])
    const [ apartamentosAssistencia, setApartamentosAssistencia ] = useState<IBuscaUnidade>([])
    const [ apartamentosPersonalize, setApartamentosPersonalize ] = useState<IBuscaUnidade>([])
    const [ apartamentosAcompanhamento, setApartamentosAcompanhamento ] = useState<IBuscaUnidade>([])
    const [ open, setOpen ] = useState(false)


    return ( 
        <ClienteSubunidadeContext.Provider
            value={{
                apartamentosFicha,
                setApartamentosFicha,
                apartamentosPagamento, 
                setApartamentosPagamento,
                apartamentosAssistencia, 
                setApartamentosAssistencia,
                apartamentosPersonalize, 
                setApartamentosPersonalize,
                apartamentosAcompanhamento, 
                setApartamentosAcompanhamento,
                open,
                setOpen
            }}
            >
                {children}
            </ClienteSubunidadeContext.Provider>
    )
}

export { ClienteSubunidadeProvider, useClienteSubunidade}