import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';
import BuscaClienteSubunidade from './buscaClienteSubunidade';

export interface IDocumento {
  id: number;
  id_empreendimento: number;
  descricao: string;
  nomeArquivoExibicao: string;
  nomeArquivo: string;
  dtInicial: string;
  dtFinal: string;
  empreendimento: string;
  unidade: string;
  subunidade: string;
}

export interface IContrato {
  nome: string;
  descricao: string;
  empreendimento: string;
  binDocumento: string;
}

export type IDownloadDocumentos = IDocumento[];
export type IDownloadContratos = IContrato[];

async function BuscaContratos(
  codcolcfo: string,
  codcfo: string,
  access_token: string,
) {
  const urlContratos = `Cliente/buscaContrato?_codcolcfo2=${codcolcfo}&_codcfo2=${codcfo}`;

  try {
    const contratosAPI: AxiosResponse<IDownloadContratos, []> =
      await axiosInstance.get(urlContratos, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    if (contratosAPI.data.length <= 0) throw new Error();

    return contratosAPI.data;
  } catch (e) {
    return [];
  }
}

async function BuscaDocumentos(idEmpreendimento: number, access_token: string) {
  const urlDocumentos = `ClienteVenda/BuscarDownloadsEmpreendimento?_idempreendimento=${idEmpreendimento}`;

  try {
    const documentosAPI: AxiosResponse<IDownloadDocumentos | []> =
      await axiosInstance.get(urlDocumentos, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    if (documentosAPI.data.length <= 0) throw new Error();

    return documentosAPI.data;
  } catch (e) {
    return [];
  }
}

export default async function DownloadDocumentos(
  codcolcfo: string,
  codcfo: string,
  access_token: string,
): Promise<Array<IDocumento | IContrato> | []> {
  try {
    const apartamentos = await BuscaClienteSubunidade(
      codcolcfo,
      codcfo,
      104,
      access_token,
    );

    let res = [] as Array<IDocumento | IContrato>;

    for (let i = 0; i < apartamentos.length; i++) {
      const docsPerUnits = await BuscaDocumentos(
        apartamentos[i].idEmpreendimento,
        access_token,
      );
      docsPerUnits.map((doc) => {
        doc.empreendimento = apartamentos[i].nomeEmpreendimento;
        doc.subunidade = apartamentos[i].subUnidade;
        doc.unidade = apartamentos[i].unidade;
      });

      res = [...res, ...docsPerUnits];
    }

    const contratos = await BuscaContratos(codcolcfo, codcfo, access_token);

    res = [...contratos, ...res];

    return res;
  } catch (e) {
    return [];
  }
}
