import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';

export interface IUnidade {
  nomeEmpreendimento: string;
  codEmpreendimento: string;
  idEmpreendimento: number;
  codUnidade: string;
  unidade: string;
  subUnidade: string;
  numVenda: number;
  urlEmpreendimento: string;
  urlImg: string;
}

export type IBuscaUnidade = Array<IUnidade>;

export default async function BuscaClienteSubunidade(
  codcolcfo: string,
  codcfo: string,
  id_funcionalidade: number,
  access_token: string,
): Promise<IBuscaUnidade | []> {
  try {
    const urlUnidades = `ClienteVenda/BuscaClienteSubunidade?_codcolcfo=${codcolcfo}&_codcfo=${codcfo}&_id_funcionalidade=${id_funcionalidade}`;

    const unidadesAPI: AxiosResponse<IBuscaUnidade | []> =
      await axiosInstance.get(urlUnidades, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

    if (unidadesAPI.data.length <= 0)
      throw new Error('Nenhuma unidade foi encontrada.');
      
    return unidadesAPI.data;
  } catch (e) {
    return [];
  }
}
