import styled from 'styled-components';
import { TSVG } from './circular-progress.type';

export const CanvasSvg = styled.svg`
`

export const CircularTrailSVG = styled.circle<TSVG>`
${(props) => {

  const { widthRef } = props;
  const radius = (widthRef - 6) /2 - 4;
  const circumference = radius * Math.PI * 2;
  const center = widthRef / 2;

  return `
  stroke-width: 6px;
  stroke-linecap: round;
  stroke: ${props.theme.layout.color.complementares.gray_200};
  r: ${radius};
  cx: ${center};
  cy: ${center};
  stroke-dasharray: ${circumference};
  stroke-dashoffset: 0;
  transform-origin: center;
  transform: rotate(270deg);
  fill: none;
  `
}}
`


export const CircularSVG = styled.circle<TSVG>`
${(props) => {

  const { widthRef, completed } = props;
  const radius = (widthRef - 6) /2 - 4;
  const circumference = radius * Math.PI * 2;
  const center = widthRef / 2;

  const prog = (circumference * (completed ? (completed - 100) * -1 : 100) / 100);

  return `
  stroke-width: 6px;
  stroke-linecap: round;

  r: ${radius};
  cx: ${center};
  cy: ${center};
  stroke-dasharray: ${circumference };
  stroke-dashoffset: ${prog};
  transform-origin: center;
  transform: rotate(270deg);
  fill: none;
  `
}}

`

export const Percentage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.layout.color.main_colors.blue_300};
  // background-color: pink;
  display: block;
  text-align: center;

`
