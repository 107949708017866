import styled from 'styled-components';
import { ItemOnGrid } from '../divider_grid/divider_grid.type';

export const PageTitleStyled = styled.h2<ItemOnGrid>`
  // padding: 3% 0;
  width: 100%;
  grid-area: 1 / 1 / 2 / -1;


  ${(props) => {
    const {
      theme: { layout },
    } = props;

    return `
  display: flex;
  justify-content: space-between;
  ${layout.head.headline3}
  svg{
    color: ${layout.color.main_colors.blue_300};
  }

  @media screen and (max-width:768px){
  display: none;
  }

  @media print and (max-width: 768px) {
    display: none;
  }
`;
  }}
`;
