import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: 'https://api.soter.com.br/',
  // baseURL: 'http://localhost:3000',
  // baseURL: 'http://apihg.soter.com.br/',
 });

export default axiosInstance;
