import React, { useState } from 'react';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import PageTitle from "../../components/page's_title/page-title";
import PencilIcon from '../../assets/menu_icons/pencil-icon.svg';
import { DadosUsuario } from './meu-perfil.style';
import AppButton from '../../components/button/button';
import KeyIcon from '../../assets/buttons-icons/key-icon.svg';
import PencilBIcon from '../../assets/buttons-icons/pencil-icon.svg';
import FormMeuPerfil from '../../components/form_meu_perfil/form-meu-perfil';
import { useAuth } from '../../Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { formatoData } from '../../util/formatoData';


export function UserProfile() {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false);

  const { userData } = useAuth();

  const handleClick = () => {
    navigate('/alterar-senha');
  }

  return (
    <>
      {edit ? (
        <FormMeuPerfil setEdit={setEdit} />
      ) : (
        <>
          <DividerGrid quantity={Quantity.three}>
            <PageTitle>
              <span>Meu Perfil</span>
              <img src={PencilIcon} alt="Ícone de uma caneta" />
            </PageTitle>
            <DadosUsuario>
              <p>
                <span>Categoria:</span> {userData?.categoria}
              </p>
              <div></div>
              <p>
                <span>Nome:</span> {userData?.nome}
              </p>
              <p>
                <span>CPF:</span> {userData?.cpf}
              </p>
              <p>
                <span>Data de Nascimento:</span> {userData ? formatoData(userData?.dataNascimento) : ''}
              </p>
              <p>
                <span>Identidade:</span> {userData?.identidade}
              </p>
              <p>
                <span>Estado Civil:</span> {userData?.estadoCivil}
              </p>
              <p>
                <span>Nacionalidade:</span> {userData?.nacionalidade}
              </p>
              <div></div>
              <div></div>
              <p>
                <span>Endereço:</span>
              </p>
              <div></div>

              <p>
                <span>Cep:</span> {userData?.cep}
              </p>
              <p>
                <span>Logradouro:</span> {userData?.logradouro}
              </p>
              <p>
                <span>Bairro:</span> {userData?.bairro}
              </p>
              <p>
                <span>Cidade:</span> {userData?.cidade}
              </p>
              <p>
                <span>Estado:</span> {userData?.uf}
              </p>
              <p>
                <span>País:</span> {userData?.pais}
              </p>
              <div></div>
              <div></div>
              <p>
                <span>Contatos:</span>
              </p>
              <div></div>
              <p>
                <span>Telefone Residencial:</span>{' '}
                {userData?.telefoneResidencial}
              </p>
              <p>
                <span>Telefone Celular:</span> {userData?.telefoneCelular}
              </p>
              <p>
                <span>Telefone Comercial:</span> {userData?.telefoneComercial}
              </p>
              <p>
                <span>E-mail:</span> {userData?.email}
              </p>
            </DadosUsuario>
          </DividerGrid>
          <DividerGrid quantity={Quantity.three}>
            <div className="atEnd atStartMob buttonsPerfil">
              <AppButton
                text="alterar senha"
                onClick={handleClick}
                animation="moving"
                className="atEnd marginButtonsPerfil atStartMob"
              >
                <img src={KeyIcon} alt="ícone de uma chave" />
              </AppButton>
              <AppButton
                text="editar perfil"
                onClick={() => setEdit(!edit)}
                animation="flip"
                className="atEnd atStartMob"
              >
                <img src={PencilBIcon} alt="ícone de uma caneta" />
              </AppButton>
            </div>
          </DividerGrid>
        </>
      )}
    </>
  );
}
