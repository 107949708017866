import {  useEffect, useState } from 'react';
import { TempoWidgetStyle } from './tempo-widget.style';
import axios from 'axios';

interface Iclima {
  temperature: number,
  wind_direction: string,
  wind_velocity: number,
  humidity: number,
  condition: string,
  pressure: number,
  icon: string,
  sensation: number,
  date: string
}
interface IclimaDTO {
    id: number,
    name: string,
    state: string,
    country: string,
    data: Iclima
}

export default function SubtitleOne(){

  const semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
  const date = new Date()

  const [ clima, setClima ] = useState<Iclima>( {
    temperature: 0,
    wind_direction: '',
    wind_velocity: 0,
    humidity: 0,
    condition: '',
    pressure: 0,
    icon: '',
    sensation: 0,
    date: ''
  })

  useEffect(() => {
    axios.get<IclimaDTO>('https://apiadvisor.climatempo.com.br/api/v1/weather/locale/6149/current?token=d26a412a1de443ff3a0d312d052e2574')
      .then(res => {
        const data = res.data.data
        setClima(data)
      }).catch(() => setClima({
        temperature: 0,
        wind_direction: '',
        wind_velocity: 0,
        humidity: 0,
        condition: '',
        pressure: 0,
        icon: '',
        sensation: 0,
        date: ''
      }) )
  }, []);

  return(
    <TempoWidgetStyle>
      <div className='weather-box'>
        <h5>{clima.temperature}<span>ºC</span></h5>
        <div className='weather-icon'>
          <img src={'weather_icons/2.svg'} alt='weather-icon' style={{width: "40px", height: "40px"}}/>
        </div>
      </div>
      <div className='date-box'>
        <h4 style={{marginBottom:"10px"}}>Niterói</h4>
        <h4>{date.toLocaleDateString()}</h4>
        <h5>{semana[date.getDay()]}</h5>
      </div>
    </TempoWidgetStyle>
  )
}
