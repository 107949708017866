import React from 'react';
import { DividerGridStyled } from './divider_grid.style';
import { Props } from './divider_grid.type';

export default function DividerGrid({ children, quantity, size }: Props) {
  return (
    <DividerGridStyled quantity={quantity} size={size}>
      {children}
    </DividerGridStyled>
  );
}
