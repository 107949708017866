import React, { useEffect, useRef, useState } from 'react';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import PageTitle from "../../components/page's_title/page-title";
import DownlaodIcon from '../../assets/menu_icons/download-icon.svg';
import BasicBox from '../../components/box_card/box-card';
import Table from '../../components/table/table';
import { useAuth } from '../../Contexts/AuthContext';
import DownloadDocumentos, {
  IContrato,
  IDocumento,
} from '../../util/downloadDocumentos';
import DownloadArrow from '../../assets/download_arrow.svg';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import axiosInstance from '../../module/axios.module';
import InstanceOfDocument from '../../util/instanceOfDocumento';

export function Documentos() {
  const [documentos, setDocumentos] = useState<
    Array<IDocumento | IContrato> | []
  >([]);
  const [baixandoDocs, setBaixandoDocs] = useState(false);
  const [invoice, setInvoice] = useState({
    title: '',
    base64pdf: '',
  });
  const [loadingContratoPDF, setLoadingContratoPDF] = useState(false);

  const { access_token, user } = useAuth();
  const DonwloadButtonRef = useRef<HTMLAnchorElement>(null);

  //exportar função para arquivo é usada em boletos, documentos e na página home
  const handleDownload = async (binDocumento: string, title: string) => {
    setLoadingContratoPDF(true);
    try {
      setInvoice({ title: title.replaceAll(' ', '_'), base64pdf: binDocumento });

      const res = await fetch(`data:application/pdf;base64,${binDocumento}`);
      const blob = await res.blob();
      const newUrl = window.URL.createObjectURL(blob);

      DonwloadButtonRef.current?.setAttribute(
        'href',
        newUrl,
      );
      btoa;
      DonwloadButtonRef.current?.setAttribute('target', '_blank');
      DonwloadButtonRef.current?.setAttribute('rel', 'noopener noreferrer');
      // DonwloadButtonRef.current?.setAttribute('download', title);
      DonwloadButtonRef.current?.click();

      setLoadingContratoPDF(false);
    } catch (e) {
      setLoadingContratoPDF(false);
    }
  };

  useEffect(() => {
    setBaixandoDocs(() => true);
    DownloadDocumentos(user.codcolcfo ?? '0', user.codcfo ?? '0', access_token)
      .then((res) => {
        if (res.length > 0) {
          setBaixandoDocs(() => false);

          return setDocumentos(res);
        }

        throw new Error();
      })
      .catch(() => {
        setBaixandoDocs(() => false);
        return setDocumentos([]);
      });
  }, [user]);

  return (
    <DividerGrid quantity={Quantity.three}>
      <PageTitle>
        <span>Documentos</span>
        <img src={DownlaodIcon} alt="ícone de uma folha de papel" />
      </PageTitle>
      {documentos.length ? (
        <BasicBox gridArea="2 / 1 / 3 / 4" gridAreaMobile="2 / 1 / 3/ -1">
          <Table
            headers={['Download', 'Empreendimento', 'Arquivo', 'Descrição']}
            values={
              documentos
                ? documentos.map((e) => {
                    if (InstanceOfDocument(e)) {
                      return [
                        loadingContratoPDF ? (
                          <LoadingIcon />
                        ) : (
                          <a
                            style={{
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%',
                              color: '#363D40',
                              height: '100%',
                            }}
                            onClick={() =>
                              handleDownload(e.binDocumento, e.nome)
                            }
                          >
                            <img src={DownloadArrow} />
                          </a>
                        ),
                        <a
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          onClick={() => handleDownload(e.binDocumento, e.nome)}
                        >
                          {e.empreendimento}
                        </a>,
                        <a
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          onClick={() => handleDownload(e.binDocumento, e.nome)}
                        >
                          {e.nome + '.pdf'}
                        </a>,
                        <a
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          onClick={() => handleDownload(e.binDocumento, e.nome)}
                        >
                          {e.descricao}
                        </a>,
                      ];
                    } else {
                      const baseUrl = axiosInstance.getUri();
                      const Url = baseUrl.slice(0, baseUrl.length - 1);
                      const loading = e.nomeArquivo ? (
                        <a
                          style={{
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          href={Url + e.nomeArquivo}
                          target={'_blank'}
                        >
                          <img src={DownloadArrow} />
                        </a>
                      ) : (
                        <LoadingIcon />
                      );

                      return [
                        loading,
                        <a
                          style={{
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          href={Url + e.nomeArquivo}
                          target={'_blank'}
                        >
                          {e.empreendimento}
                        </a>,
                        <a
                          style={{
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          href={Url + e.nomeArquivo}
                          target={'_blank'}
                        >
                          {e.nomeArquivoExibicao}
                        </a>,
                        <a
                          dangerouslySetInnerHTML={{ __html: e.descricao }}
                          style={{
                            display: 'block',
                            width: '100%',
                            color: '#363D40',
                            height: '100%',
                          }}
                          href={Url + e.nomeArquivo}
                          target={'_blank'}
                        >
                          {/* {e.descricao} */}
                          {/* <span dangerouslySetInnerHTML={{ __html: e.descricao }} /> */}
                        </a>,
                      ];
                    }
                  })
                : [['']]
            }
          />
        </BasicBox>
      ) : baixandoDocs ? (
        <p>
          Carregando{' '}
          <span style={{ marginLeft: '15px' }}>
            <LoadingIcon />
          </span>
        </p>
      ) : (
        <div style={{ gridColumn: '1/-1' }}>
          {' '}
          <p>Não há itens disponíveis no momento.</p>{' '}
        </div>
      )}
      <a
        ref={DonwloadButtonRef}
        download={invoice.title}
        href={`data:application/pdf;base64,${invoice.base64pdf}`}
        title="Download pdf document"
      />
    </DividerGrid>
  );
}
