import { Navigate, Route, Routes } from 'react-router-dom';
import Guard from '../components/guard/guard';
import { BuildingProgress } from '../screens/acompanhamento-obra/acompanhamento-obra';
import { PasswordChange } from '../screens/alteracao-senha';
import AssistenciaTecnica from '../screens/assistencia-tecnica/assistencia-tecnica';
import { BoletosScreen } from '../screens/boletos';
import { Documentos } from '../screens/documentos/documentos';
import { FinancialStatement } from '../screens/ficha-financeira/ficha-financeira';
import { Home } from '../screens/home';
import { IndicesReajuste } from '../screens/indices-reajuste';
import { Login } from '../screens/login';
import { PeriodPayment } from '../screens/pagamento-por-periodo/pagamento-por-periodo';
import { UserProfile } from '../screens/perfil/meu-perfil';
import { PersonalizeImovel } from '../screens/personalize-imovel';
import { PrimeiroAcesso } from '../screens/primeiro acesso';
import { BemVindo } from "../screens/bem-vindo";

export function Router() {
  return (
    <Routes>
      <Route element={<Guard><Home /></Guard>} path="/" />
      <Route element={<Guard><FinancialStatement /></Guard>} path="/ficha-financeira" />
      <Route element={<Guard><BoletosScreen /></Guard>} path="/boletos" />
      <Route element={<BemVindo />} path="/bem-vindo" />
      <Route element={<Login />} path="/login" />
      <Route element={<PrimeiroAcesso />} path="/primeiro-acesso" />
      <Route element={<AssistenciaTecnica />} path="/assistencia" />
      <Route element={<Guard><Documentos /></Guard>} path="/documentos" />
      <Route element={<Guard><IndicesReajuste /></Guard>} path="/indices" />
      <Route element={<Guard><BuildingProgress /></Guard>} path="/acompanhamento-da-obra" />
      <Route element={<Guard><PersonalizeImovel /></Guard>} path="/singular" />
      <Route element={<Guard><UserProfile /></Guard>} path="/meu-perfil" />
      <Route element={<Guard><PeriodPayment /></Guard>} path="/pagamento" />
      <Route element={<Guard><PasswordChange /></Guard>} path="/alterar-senha" />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  );
}
