import { AdmAcessBadgetStyled } from './adm_acess_badget.styled';
import { Props } from './adm_acess_badget.type';
import AdmIcon from '../../assets/buttons-icons/adm-acess.svg';

export default function admAcessBadget({children} : Props) {
  return (
  <AdmAcessBadgetStyled>
    <div className="badget">
      <img src={AdmIcon} alt="Ícone" />
      {children}
    </div>
  </AdmAcessBadgetStyled>
  )
}