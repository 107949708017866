// imports defaults
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// imports components
import AppButton from '../../components/button/button';
import Input from '../../components/inputs/input';
import PageTitle from "../../components/page's_title/page-title";
import {
  ContentTextStyled,
  LoginLayoutStyled,
  OptinalUserList,
} from './login.style';
import { loginSchema } from './login.schema';
import { useAuth } from '../../Contexts/AuthContext';
import CheckBox from '../../components/inputs/input-checkbox';

// imports images and icons
import soter_logo from '../../assets/logo_login_branca.svg';
import lineLogin from '../../assets/line-login.svg';
import ArrowLogin from '../../assets/buttons-icons/arrow-login.svg';
import soterLogoMobile from '../../assets/logo_mobile.svg';
import { EmailLogin, SenhaLogin } from '../../assets/login_icons/index';
import { ValidationError } from 'yup';
import { AxiosError } from 'axios';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import user_icon from '../../assets/user_icons/user.svg';
import arrow_right from '../../assets/user_icons/arrow_right.svg';
import { User } from '../../Contexts/AuthContext.type';

interface SignInData {
  email: string;
  senha: string;
}


export function Login() {
  const navigate = useNavigate();
  const { signIn, users, ChangePerfil, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [preChecked, setPreChecked] = useState(true);
  const [eyes, setEyes] = useState(false);
  const [multipleUsers, setMultipleUsers] = useState(false);

  const [data, setData] = useState<SignInData>({
    email: '',
    senha: '',
  });

  const [error, setError] = useState({
    email: '',
    senha: '',
  });

  const [errorApi, setErrorApi] = useState({generico: ''})

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setError({ email: '', senha: '' });

      if(!checked) setPreChecked(false);

      setData((prevData) => ({
        ...prevData,
        [target.name]: target.value,
      }));
    },
    [setData],
  );

  const HandleSignOut = () => {
    signOut();
  };

  const handleSend = async () => {
    setErrorApi({generico: ''})
    setError({ email: '', senha: '' });
    try {
      await loginSchema.validate(data, { abortEarly: false });
      setLoading(true);
      const res = await signIn(data);
      if (res === 'error') throw new AxiosError();

      setLoading(false);

      if (res.length <= 1) {
        navigate('/');
      } else setMultipleUsers(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((error: ValidationError) => {
          const key = error.path;
          const value = error.message;
          if (key) {
            setErrorApi((prevData) => ({
              ...prevData,
              [key]: value,
            }));
          }
        });
      }
      if (error instanceof AxiosError) {
        setErrorApi((prev) => ({
          ...prev,
          generico: 'Usuário ou senha inválidos.',
        }));
      }
      setLoading(false);
    }
  };

  const handlePerfil = async (userCodcolcfo: string, userCodcfo: string) => {
    try {
      await ChangePerfil(userCodcolcfo, userCodcfo);
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/');
    }
  };

  useEffect(() => {
    setError({ email: '', senha: '' });
  }, [checked]);

  useEffect(() => {
    const token = localStorage.getItem('@Soter:AccessToken');
    if (token) {
      navigate('/');
    }
  }, []);


  return (
    <LoginLayoutStyled>
        <div>
          <img
            width="173"
            className="logomobile"
            src={soterLogoMobile}
            alt="logo"
          />
          <div className="space" style={{ marginTop: '100px' }}></div>
          <div>
            {!multipleUsers ? (
              <>
                <PageTitle> Meu Soter </PageTitle>
                <Input
                  type={'email'}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  icon={<img src={EmailLogin} alt="login" />}
                  errorText={error.email}
                  editable
                ></Input>
                <Input
                  type={eyes ? 'text' : 'password'}
                  eyes={eyes}
                  setEyes={setEyes}
                  name="senha"
                  onChange={handleChange}
                  placeholder="Senha"
                  icon={<img src={SenhaLogin} alt="login" />}
                  errorText={error.senha}
                  editable
                ></Input>


                <CheckBox checked={checked} setChecked={setChecked}>
                  Li e aceito a política de privacidade da Soter.{' '}
                  <a
                    target="_blank"
                    href="https://www.soter.com.br/politica-de-privacidade-2"
                  >
                    <span style={{ color: '#2E80C3', fontWeight: '600' }}>
                      (Leia-me)
                    </span>
                  </a>
                </CheckBox>

                {errorApi?.generico ? (
                <p
                  style={{
                    color: '#d00020',
                    padding: '10px 0',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  {errorApi.generico}
                </p>
              ): null }

                <div style={{ height: '30px' }}>
                  {loading ? <LoadingIcon /> : null}
                </div>

                {checked || preChecked ? (
                  <div className="button">
                    <AppButton
                      onClick={handleSend}
                      login
                      text="entrar"
                      animation="moving"
                    >
                      <img src={ArrowLogin} alt="seta para direita" />
                    </AppButton>
                  </div>
                ) : (
                  <div
                    style={{ filter: 'grayscale(.7)', opacity: '.5' }}
                    className="button"
                  >
                    <AppButton
                      login
                      text="entrar"
                      animation="moving"
                      disabled
                    >
                      <img src={ArrowLogin} alt="seta para direita" />
                    </AppButton>
                  </div>
                )}
              </>
            ) : (
              <div style={{ minHeight: '435px', maxHeight: '451px' }}>
                <PageTitle> Meu Soter </PageTitle>
                <OptinalUserList>
                  <p
                    style={{
                      margin: '0 0 5px ',
                      color: '#1C4584',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                  >
                    Contas
                  </p>
                  <p
                    className="acessoSenha"
                    style={{ margin: '0', lineHeight: '16px' }}
                  >
                    Você possui mais de uma conta cadastrada neste email.
                  </p>
                  <p
                    className="acessoSenha"
                    style={{ margin: '0 0 20px ', lineHeight: '16px' }}
                  >
                    Escolha o perfil que deseja acessar.
                  </p>

                  {users.map((user: User) => (
                    <li
                      key={user.id}
                      onClick={() => handlePerfil(user.codcolcfo, user.codcfo)}
                    >
                      <div>
                        <img src={user_icon} />
                        <span>{user.nome}</span>
                      </div>
                      <div>
                        <img src={arrow_right} />
                      </div>
                    </li>
                  ))}
                </OptinalUserList>
              </div>
            )}
          </div>

          {!multipleUsers ? (
            <>
              <p className="acessoSenha">
                Primeiro acesso ou esqueceu sua senha?{' '}
                <Link to={'/primeiro-acesso'}>Clique aqui</Link>
              </p>{' '}
            </>
          ) : (
            <>
              <p className="acessoSenha" onClick={HandleSignOut}>
                Sair <Link to={'/'}>Clique aqui</Link>
              </p>
            </>
          )}
        </div>
      <div className="right">
        <img className="lineLogin" src={lineLogin} />
        <img className="logoDesktop" src={soter_logo} alt="logo" />
        <ContentTextStyled>
          <p>
            Bem-vindo ao <span className="destaque">Meu Soter.</span>
          </p>
          <p>
            Esse canal foi desenvolvido para estarmos cada vez mais próximos de
            vocês, nossos clientes.
          </p>
          <p>
            Por aqui, buscamos agilizar o atendimento, estruturar, modernizar e
            aperfeiçoar cada vez mais nossos processos e meios de comunicação.
          </p>
        </ContentTextStyled>
      </div>
    </LoginLayoutStyled>
  );
}
