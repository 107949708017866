import React, { useEffect, useState } from 'react';
import { SingularIcon } from '../../assets/menu_icons';
import  SingularLogo  from '../../assets/singular_logo.svg';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import PageTitle from "../../components/page's_title/page-title";
import { useAuth } from '../../Contexts/AuthContext';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';
import BuscaClienteSubunidade, { IBuscaUnidade } from '../../util/buscaClienteSubunidade';
import { Personalize } from './personalize.style';

export function PersonalizeImovel() {
  const { apartamentosPersonalize, setApartamentosPersonalize } = useClienteSubunidade()
  const [errorApartamentosPersonalize, setErrorApartamentosPersonalize] = useState(false);
  const [loadingReq, setLoadingReq] = useState(true);

  const { access_token, user } = useAuth();

  useEffect(() => {
    setLoadingReq(true);
    BuscaClienteSubunidade(
      user.codcolcfo ?? '0',
      user.codcfo ?? '0',
      107,
      access_token,
    )
      .then((res) => {
        if (res.length > 0) {
          setLoadingReq(false);
          return setApartamentosPersonalize(res as IBuscaUnidade);
        }

        throw new Error();
      })
      .catch(() => {
        setErrorApartamentosPersonalize(true);
        setLoadingReq(false);
      });
  }, []);
  return (
    <>
      <Personalize>
        <DividerGrid quantity={Quantity.three}>
          <PageTitle>
            <span></span>
            <img src={SingularIcon} alt="Singular" />
          </PageTitle>

          {errorApartamentosPersonalize ? (
            <div style={{gridColumn: '1/-1'}}>
              <p className='personalize'>
                Não há itens disponíveis no momento.
              </p>
            </div>
          ) : (
            <> 
              <img className="singularLogo" src={SingularLogo} alt="Singular" style={{ gridArea: '2 / 1 / 3 / 3' }} />   
              <p className='personalize' style={{ gridArea: '3 / 1 / 4 / 3' }}>
              Reforçando o conceito de exclusividade, desenvolvemos este programa, onde os nossos clientes encontram espaço para agregar a sua singularidade ao nosso acabamento Padrão Soter. Assim, cumprindo as especificidades de cada empreendimento, construímos juntos um projeto com a sua assinatura e a nossa qualidade construtiva.
              <br />
              <br />
              O login é o CPF ou e-mail e a senha os primeiros 6 dígitos do CPF (somente números).
              <br />
              <br />
              Clique abaixo e acesse:
              </p>
                
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  gridArea: '4 / 1 / 5/ 3',
                }}
              >
                {loadingReq ? (
                  <p style={{ gridArea: '2 / 1 / 3 / 2' }}>
                    Carregando
                    <span style={{ marginLeft: '15px' }}>
                      <LoadingIcon />
                    </span>
                  </p>
                ) : null}
                {apartamentosPersonalize
                  ? apartamentosPersonalize.map((unit) => {
                      if (unit.urlImg.length > 0)
                        return (
                          <a
                            href={unit.urlEmpreendimento}
                            style={{ textDecoration: 'none', color: 'unset' }}
                            target={'_blank'}
                            key={unit.idEmpreendimento}
                          >
                            <div
                              style={{
                                width: '295px',
                                height: '210px',
                                marginBottom: '15px',
                                borderRadius: '0 15px',
                                background: '#ffffff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                alt="Logo do empreendimento"
                                src={unit.urlImg}
                                style={{ width: '80%', maxWidth: '153px' }}
                              />
                            </div>
                            {/* <p style={{ fontSize: '12px' }}>
                              {unit.nomeEmpreendimento}
                            </p> */}
                          </a>
                        );
                    })
                  : null}
              </div>
            </>
          )}
        </DividerGrid>
      </Personalize> 
    </>
  );
}
