import styled from 'styled-components';
import { ItemOnGrid } from '../../divider_grid/divider_grid.type';

export type ColorType = {
  color?: string
 } & ItemOnGrid

export const SubtituloStyled = styled.p<ColorType>`
font-size: 1rem;
line-height: 1.75rem;
font-weight: 500;
color: ${(props) => props.color ? props.color : "#000000DE"};
${(props) => props.gridArea && "grid-area:" + props.gridArea};
/* display: block ; */
/* page-break-before: avoid; */


@media screen and (max-width:768px){
  ${(props) => props.gridAreaMobile && "grid-area:" + props.gridAreaMobile};
}
@media print and (max-width: 768px) {
    /* display: none; */
  }
`
