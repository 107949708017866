import React from 'react';
import { AppButtonStyled } from './button.styled';
import { Props } from './button.type';

export default function AppButton({
  onClick,
  children,
  text,
  animation,
  className,
  login = false,
  disabled = false,
}: Props) {
  return (
    <AppButtonStyled
      onClick={onClick}
      login={login}
      className={className}
      animation={animation}
      disabled={disabled}
    >
      <span>{text}</span>
      {children}
    </AppButtonStyled>
  );
}
