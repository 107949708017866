import styled from "styled-components";

interface PropMenuMobile {
  open?: boolean;
}


export const MenuMobileHamb = styled.div`
    display: none;
    width: 84%;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left:0;
    bottom: 0;
    padding: 0 8% 25px 8%;
    z-index: 99;
    color: white;

    @media (max-width: 768px) {
        display: flex;
    }

    .menuHamburguer {
      margin-left: auto;
    }

    .hamburguer {
      width: 24px;
      height: 18.534px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .hamburguer div {
      width: 100%;
      background: #fff;
      border-radius: 3px;
      height: 3px;
      transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    }
    .hamburguer div:nth-child(1),
    .hamburguer div:nth-child(3) {
      width: 85%;
      align-self: flex-end;
    }
    .hamburguer div:nth-child(2) {
      width: 85%;
      align-self: flex-start;
    }
    .hamburguer div:nth-child(3) {
      margin: 0 0 0.1px 0;
    }
    .hamburguerOpen div:nth-child(1) {
      transform-origin: bottom;
      width: 50%;
      transform: rotatez(45deg) translate(-6px, 10px);
    }
    .hamburguerOpen div:nth-child(2) {
      transform-origin: top;
      width: 100%;
      transform: rotatez(-45deg);
    }
    .hamburguerOpen div:nth-child(3) {
      transform-origin: bottom;
      width: 50%;
      transform: translate(-2px, -4px) rotatez(45deg);
    }

    @media print and (max-width: 768px){
    display: none;
  }
`

export const MenuMobileStyled = styled.div<PropMenuMobile>`
    display: none;
    button {
        background-color: transparent;
        color: white;
        font-size: 24px;
        transform: rotate(90deg);
    }

    @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    padding: 0 8%;
    width: 84%;
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    border-radius: 0 20px 0 0;

    background: transparent linear-gradient(124deg,#0F2E4E 0%,#1C4584 100%) 0% 0% no-repeat padding-box;
    color: white;
    /* height: 70px; */
    height: ${(props) => props.open ? "100%" : "70px"};
    transition: height 0.5s;
    z-index: 97;
  }

  @media print and (max-width: 768px){
    display: none;
  }
`

export const ContentStyled = styled.div<PropMenuMobile>`
    display: ${(props) => props.open ? "none" : "flex"};
    align-items: center;
    img {
        margin-right: 16px;
        height: 24px;
        width: 24px
    }

    @media print and (max-width: 768px){
    display: none;
  }
`

export const ModalStyled = styled.div<PropMenuMobile>`
    /* overflow-y: hidden; */
    overflow: auto;
    position: fixed;
    top: 0;
    padding: 170px 10% 80px 10%;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 98;
    display: flex;
    flex-direction: column;
    height: auto;
    animation: ${(props) => props.open ? "fadeIn .8s" : "fadeOut 0.8s"};
    @keyframes fadeIn {
        0% {  
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }   
        100% {
            opacity: 0;
        }
    }

    @media print and (max-width: 768px){
    display: none;
  }
`

export const ButtonStyled = styled.div`
  width: 100%;
  height: 50px;
  /* padding-left: 8%; */
  text-align: left;
  border: none;
  text-align: left;

  color: ${props => props.theme.layout.color.white};
  font-size: ${props => props.theme.layout.text.large};
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  transition: all .3s ease;


  .buttonContainer {
    max-width: 370px;
    width: 100%;
  }

  img{
    max-height: 25px;
    // width: 100%;
    // max-width: 100%;
  }
  &:hover {
    border-right: 10px solid ${(props) => props.theme.layout.color.main_colors.blue_300};
    background: transparent linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  };
  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.layout.color.white};
  };
  span {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    margin-left: 20px;
  }

`

export const LogoContainerStyled = styled.a`
height: 15%;
max-height: 160px;
display: none;
justify-content: center;
align-items: center;
padding: 30px 0 20px;
`

export const ButtonOutStyled = styled.button`
    background-color: transparent;
    color: white;
    font-size: 30px;
    width: 100%;
    text-align: right;
    position: fixed;
    bottom: 20px;
    right: 18px;

`
