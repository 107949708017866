import styled from 'styled-components';

export const AcompanhamentoStyled = styled.section`
  width: 100%;
  display: grid;

  grid-template:
  1fr auto / minmax(50px,100px) minmax(50px,100px) minmax(50px,100px) minmax(50px,100px) minmax(50px,100px) minmax(50px,100px);
  justify-content: space-between;
  box-sizing: border-box;
  column-gap: 5px;
  row-gap: 25px;
  padding-bottom: 10px; 


  @media screen and (max-width:768px){
    grid-template:1fr 1fr / minmax(50px,100px) minmax(50px,100px) minmax(50px,100px)
  }

`;
