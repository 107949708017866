
import { AxiosResponse } from 'axios';
import axiosInstance from '../module/axios.module';


export default async function LogBoletos(
  idCliente: number,
  ip: string,
  acao: string,
  access_token: string,
): Promise<boolean> {
  try {
    const urlRegistraLog = `ClienteVenda/RegistraLog?_idCliente=${idCliente}&_ip=${ip}&_acao=${acao}`;

    const resLogAPI: AxiosResponse<boolean> = await axiosInstance.get(
      urlRegistraLog,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );

    return resLogAPI.data;
  } catch (e) {
    return false;
  }
}
