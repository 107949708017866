import styled from 'styled-components';
import { Props } from './total-progress.type';

export const BarTrailSVG = styled.svg`
background-color: ${(props) => props.theme.layout.color.complementares.gray_200};
grid-column: 1 /7;
// position: relative;
p{
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 12px;
  color: ${(props) => props.theme.layout.color.main_colors.blue_800};
  z-index: 3;
}
`


export const BarSVG = styled.rect<Props>`
${(props) => {

  const { progress } = props;

  return `
  width: calc(100% * ${progress / 100});
  height: 100%;
  transition: all 0.3s linear;
  `
}}
`
