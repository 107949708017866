import React from 'react';
import { GlobalStyles } from './themes/global-style';
import { ThemeProvider } from 'styled-components';
import { Theme } from './themes/theme';
import { Router } from './routes';
import PageLayout from './components/page_layout/page_layout';

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <PageLayout>
          <Router />
        </PageLayout>
      </ThemeProvider>
    </>
  );
}

export default App;
