import { VideoContainer } from "./video.style";
import { Props } from "./video.type";

export default function Youtube({srcVideoIframe}: Props) {
    return(
      <VideoContainer>
        <iframe
            src={srcVideoIframe} 
            allowFullScreen
        />
      </VideoContainer>
    )
  }