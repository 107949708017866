import {
  ButtonStyled,
  ContentStyled,
  LogoContainerStyled,
  MenuMobileStyled,
  MenuMobileHamb,
  ModalStyled,
} from './menu_mobile.style';
import soter_logo from '../../assets/logo_menu_branca.svg';
import LogOutIcon from '../../assets/menu_icons/signout-icon.svg';
import { useAuth } from '../../Contexts/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useClienteSubunidade } from '../../Contexts/ClienteSubunidadeContext';

export default function MenuMobile() {
  const { open, setOpen } = useClienteSubunidade();
  const { menuItens, signOut } = useAuth();
  const navigate = useNavigate();

  const url = useLocation();

  const HandleSignOut = () => {
    navigate('/login');
    signOut();
  };

  return (
    <>
      {!menuItens ||
      url.pathname == '/login' ||
      url.pathname == '/primeiro-acesso' ||
      url.pathname == '/impressao' ||
      url.pathname == '/bem-vindo' ||
      url.pathname == '/assistencia' ? (
        <></>
      ) : (
        <>
          <MenuMobileStyled open={open}></MenuMobileStyled>
          <MenuMobileHamb className="footerMenuMob">
            <ContentStyled open={open}>
              <img src={menuItens[url.pathname]?.icon} alt="Ícone" />
              <p>{menuItens[url.pathname]?.name}</p>
            </ContentStyled>
            <div className="menuHamburguer" onClick={() => setOpen(!open)}>
              <div
                className={`${open ? 'hamburguerOpen' : ''} ${'hamburguer'}`}
              >
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
            </div>
          </MenuMobileHamb>
        </>
      )}
      {open && (
        <ModalStyled open={open}>
          <div className="menuContainer">
            <LogoContainerStyled className="logoGrande" href="/">
              <img
                src={soter_logo}
                alt="logo soter"
                style={{ maxHeight: '76px', maxWidth: '180px' }}
              />
            </LogoContainerStyled>

            {Object.keys(menuItens).map((element, n) => {
              if (element != '/meu-perfil' && element != '/alterar-senha') {
                return (
                  <Link key={n} to={element} onClick={() => setOpen(!open)}>
                    <ButtonStyled>
                      <img src={menuItens[element].icon} alt="Ícone" />
                      <span>{menuItens[element].name}</span>
                    </ButtonStyled>
                  </Link>
                );
              }
            })}

            <ButtonStyled onClick={HandleSignOut}>
              <img src={LogOutIcon} alt="Ícone" />
              <span>Sair</span>
            </ButtonStyled>
            {/* <ButtonOutStyled onClick={() => setOpen(!open)}>
              {' '}
              x{' '}
            </ButtonOutStyled> */}
          </div>
        </ModalStyled>
      )}
    </>
  );
}
