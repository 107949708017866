import React from 'react';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, TextCheckBox } from './input.style';
import { CheckboxProps } from './input.type';
import checkedIcon from '../../assets/checkedIcon.svg';

export default function CheckBox({children, checked, setChecked}: CheckboxProps) {

  function handleCheckboxChange(){
    setChecked(!checked);
  }


  return (
    <CheckboxContainer
    onClick={handleCheckboxChange}>
      <HiddenCheckbox
      checked={checked}
      // onClick={handleCheckboxChange}/>
      onChange={handleCheckboxChange}/>
      <StyledCheckbox checked={checked}>
      <img
            alt="tick icon"
            style={{width: '15px'}}
            src={checkedIcon}
         />
      </StyledCheckbox>
      <TextCheckBox checked={checked}>{children}</TextCheckBox>
    </CheckboxContainer>
  )
}
