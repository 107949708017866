import { useEffect, useState, useRef } from 'react';

import { layout } from '../../themes/layout';

import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import PageTitle from "../../components/page's_title/page-title";
import BasicBox from '../../components/box_card/box-card';
import Table from '../../components/table/table';
import SubTitulo1 from '../../components/fonts_components/sub-headers/subtitulo1';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';

import CopyIcon from '../../assets/menu_icons/copy-icon.svg';
import DownloadArrow from '../../assets/download_arrow.svg';

import { useAuth } from '../../Contexts/AuthContext';

import BuscaBoletos, { IBuscaBoletos } from '../../util/buscaBoletos';
import BuscaBoletoAvulso, { IBuscaBoletosAvulsos } from '../../util/buscaBoletosAvulsos';
import BoletoPDF from '../../util/boletoPDF';
import LogBoletos from '../../util/logBoletos';
import BuscaIP from '../../util/buscaIP';
import { formatoData } from '../../util/formatoData';
import ReadableValue from '../../util/transformaEmMoeda';

export function BoletosScreen() {
  const [boletos, setBoletos] = useState<IBuscaBoletos | []>([]);
  const [boletosAvulsos, setBoletosAvulsos] = useState<IBuscaBoletosAvulsos | []>([]);
  const [baixandoBoletosAvulsos, setBaixandoBoletosAvulsos] = useState(false);
  const [baixandoBoletos, setBaixandoBoletos] = useState(false);
  const [invoice, setInvoice] = useState({
    title: '',
    base64pdf: '',
  });
  const [loadingReq, setLoadingReq] = useState(false);

  const { access_token, user } = useAuth();

  const DonwloadButtonRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setBaixandoBoletos(() => true);
    setBaixandoBoletosAvulsos(() => true);

    BuscaBoletos(user?.codcolcfo ?? '0', user?.codcfo ?? '0', 103, access_token)
      .then((res) => {
        setBoletos(res);
        setBaixandoBoletos(() => false);
      })
      .catch(() => {
        setBoletos([]);
        setBaixandoBoletos(() => false);
      });

    BuscaBoletoAvulso(user.codcolcfo ?? '0', user.codcfo ?? '0', access_token)
      .then((res) => {
        setBoletosAvulsos(res);
        setBaixandoBoletosAvulsos(() => false);
      })
      .catch(() => {
        setBoletosAvulsos([]);
        setBaixandoBoletosAvulsos(() => false);
      });
  }, [user]);

  const handleDownload = async (
    codColigada: number,
    idlan: number,
    title: string,
  ) => {
    setLoadingReq(true);
    try {
      const ip = await BuscaIP();

      const today = new Date();
      const acao = `O usuário ${user.nome}, de id ${
        user.id
      }, sob o IP: ${ip} baixou o boleto de codColigada: ${codColigada} e idLan: ${idlan}.
      ${today.toString()}`;

      const downloadPDF = await BoletoPDF(codColigada, idlan, access_token);

      if (downloadPDF == '') throw new Error();

      await LogBoletos(user.id, ip, acao, access_token);

      setInvoice({ title: title, base64pdf: downloadPDF });

      const res = await fetch(`data:application/pdf;base64,${downloadPDF}`);
      const blob = await res.blob();
      const newUrl = window.URL.createObjectURL(blob);

      DonwloadButtonRef.current?.setAttribute(
        'href',
        // `data:application/pdf;base64,${downloadPDF}`,
        newUrl,
      );
      btoa;
      DonwloadButtonRef.current?.setAttribute('target', '_blank');
      DonwloadButtonRef.current?.setAttribute('rel', 'noopener noreferrer');
      // DonwloadButtonRef.current?.setAttribute('download', title);
      DonwloadButtonRef.current?.click();

      setLoadingReq(false);
    } catch (e) {
      setLoadingReq(false);
    }
  };

  return (
    <>
      <DividerGrid quantity={Quantity.one}>
        <PageTitle>
          <span>Boletos</span>
          <img src={CopyIcon} alt="ícone de uma folha de papel" />
        </PageTitle>

        {baixandoBoletos || baixandoBoletosAvulsos ? (
          <p style={{ gridArea: '2 / 1 / 3 / 2' }}>
            Carregando
            <span style={{ marginLeft: '15px' }}>
              <LoadingIcon />
            </span>
          </p>
        ) : null}

        {loadingReq ? (
          <p style={{ gridArea: '2 / 1 / 3 / 2' }}>
            Baixando boleto
            <span style={{ marginLeft: '15px' }}>
              <LoadingIcon />
            </span>
          </p>
        ) : null}

        {!boletos.length &&
        !boletosAvulsos.length &&
        !loadingReq &&
        !baixandoBoletos &&
        !baixandoBoletosAvulsos ? (
          <div style={{ gridColumn: '1/-1' }}>
            <p>Não há itens disponíveis no momento.</p>
          </div>
        ) : null}

        <>
          {boletosAvulsos.length ? (
            <BasicBox gridArea="3 / 1 / 4 / 2" gridAreaMobile="2 / 1 / 3 / 3">
              <div style={{ marginBottom: '25px' }}>
                <SubTitulo1 color={layout.color.main_colors.main}>
                  Boletos avulsos
                </SubTitulo1>
              </div>
              <Table
                download={true}
                headers={['Download', 'Unidade', ' ', 'Nome do arquivo']}
                values={boletosAvulsos.map((bol) => {
                  return [
                    <a
                      style={{
                        display: 'block',
                        width: '100%',
                        color: '#363D40',
                        height: '100%',
                      }}
                      download={bol.nomeArquivoExibicao.replace(' ', '_')}
                      href={'https://api.soter.com.br' + bol.nomeArquivo}
                      target="_SEJ"
                      rel="noopener noreferrer"
                    >
                      <img src={DownloadArrow} />
                    </a>,
                    <a
                      style={{
                        display: 'block',
                        width: '100%',
                        color: '#363D40',
                        height: '100%',
                      }}
                      download={bol.nomeArquivoExibicao.replace(' ', '_')}
                      href={'https://api.soter.com.br' + bol.nomeArquivo}
                      target="_SEJ"
                      rel="noopener noreferrer"
                    >
                      {bol.empreendimento +
                        '-' +
                        bol.unidade +
                        '-' +
                        bol.subunidade}
                    </a>,
                    <span>&nbsp;</span>,
                    <a
                      style={{
                        display: 'block',
                        width: '100%',
                        color: '#363D40',
                        height: '100%',
                      }}
                      download={bol.nomeArquivoExibicao.replace(' ', '_')}
                      href={'https://api.soter.com.br' + bol.nomeArquivo}
                      target="_SEJ"
                      rel="noopener noreferrer"
                    >
                      {bol.nomeArquivoExibicao}
                    </a>,
                  ];
                })}
              />
            </BasicBox>
          ) : null}

          {boletos.length ? (
            <BasicBox gridArea="4 / 1 / 5 / 2" gridAreaMobile="3/1/4/3">
              <div style={{ marginBottom: '25px' }}>
                <SubTitulo1 color={layout.color.main_colors.main}>
                  Boletos
                </SubTitulo1>
              </div>
              <Table
                headers={['Download', 'Unidade', 'Vencimento', 'Valor(R$)']}
                download={true}
                values={
                  boletos
                    ? boletos.map((e) => {
                        const arr = [
                          <a
                            style={{
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%',
                              color: '#363D40',
                              height: '100%',
                            }}
                            onClick={() =>
                              handleDownload(
                                e.codcoligada,
                                e.idlan,
                                e.empreendimento +
                                  '-' +
                                  formatoData(e.dataVencimento),
                              )
                            }
                          >
                            <img src={DownloadArrow} />
                          </a>,
                          <a
                            style={{
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%',
                              color: '#363D40',
                              height: '100%',
                            }}
                            onClick={() =>
                              handleDownload(
                                e.codcoligada,
                                e.idlan,
                                e.empreendimento +
                                  '-' +
                                  formatoData(e.dataVencimento),
                              )
                            }
                          >
                            {e.empreendimento}
                          </a>,
                          <a
                            style={{
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%',
                              color: '#363D40',
                              height: '100%',
                            }}
                            onClick={() =>
                              handleDownload(
                                e.codcoligada,
                                e.idlan,
                                e.empreendimento +
                                  '-' +
                                  formatoData(e.dataVencimento),
                              )
                            }
                          >
                            {formatoData(e.dataVencimento)}
                          </a>,
                          <a
                            style={{
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%',
                              color: '#363D40',
                              height: '100%',
                            }}
                            onClick={() =>
                              handleDownload(
                                e.codcoligada,
                                e.idlan,
                                e.empreendimento +
                                  '-' +
                                  formatoData(e.dataVencimento),
                              )
                            }
                          >
                            {ReadableValue(e.valorAtual)}
                          </a>,
                        ];

                        return arr;
                      })
                    : [['']]
                }
              />
            </BasicBox>
          ) : null}
        </>

        <a
          ref={DonwloadButtonRef}
          download={invoice.title}
          href={`data:application/pdf;base64,${invoice.base64pdf}`}
          title="Download pdf document"
        />
      </DividerGrid>
    </>
  );
}
