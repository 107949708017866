import styled from 'styled-components';
import { PropLoginButton } from './button.type';

export const AppButtonStyled = styled.button<PropLoginButton>`
  ${(props) => {
    const {
      theme: {
        layout: { color, text },
      },
      login,
      animation,
    } = props;

    const loginStyle = `
  background-color: ${color.main_colors.blue_300};
  background: ${color.background.green};
  color: #fff;
  font-size: 0.875rem;
  padding: 20px 20px 20px 30px;
  border-radius: 0 15px;
    span{
      margin-right: 20px;
    }
  `;

    const normalButton = `
  background: transparent;
  color: ${color.complementares.green};
  border: 1px solid ${color.main_colors.blue_500};
  padding: 14px 10px 14px 15px;
  border-radius: 10px;
    span{
      margin-right: 15px;
      color: ${color.main_colors.blue_500};
    }
   &:hover{
    background: linear-gradient(124deg, rgba(15, 46, 78, 0) 0%, rgba(28, 69, 132, 0) 100%);
    span{
      color: #fff;
    }
    animation: color .2s forwards ease;
  }

  @keyframes color {
    0%   { background: linear-gradient(124deg, rgba(15, 46, 78, 0) 0%, rgba(28, 69, 132, 0) 100%); }
    20%  { background: linear-gradient(124deg, rgba(15, 46, 78, 0.2) 0%, rgba(28, 69, 132, 0.4) 100%); }
    40%  { background: linear-gradient(124deg, rgba(15, 46, 78, 0.4) 0%, rgba(28, 69, 132, 0.4) 100%); }
    60%  { background: linear-gradient(124deg, rgba(15, 46, 78, 0.6) 0%, rgba(28, 69, 132, 0.6) 100%);  }
    80%  {  background: linear-gradient(124deg, rgba(15, 46, 78, 0.8) 0%, rgba(28, 69, 132, 0.8) 100%);  }
    100% { background: linear-gradient(124deg, rgba(15, 46, 78, 1) 0%, rgba(28, 69, 132, 1) 100%); }
  }
  `;

    return (
      (login ? loginStyle : normalButton) +
      `
  font-size: ${text.medium};
  line-height: 18px;
  letter-spacing: 0.84px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  SVG{
    width: 12px;
    height: 12px;
    }

    img{
      animation-play-state: paused;
    }
    &:hover{
      img{
        -webkit-animation: ${animation ? animation : 'moving'} 2s forwards ease;
        -moz-animation: ${animation ? animation : 'moving'} 2s forwards ease;
        animation: ${animation ? animation : 'moving'} 2s linear forwards ease;
        animation-play-state: running;

        @-moz-keyframes moving {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {-moz-transform: translateX(4px);}

        }
        @-webkit-keyframes moving {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {-webkit-transform: translateX(4px);}

        }
        @keyframes moving {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {transform: translateX(4px);}
        }

        @-moz-keyframes flip {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {transform: scaleX(-1);}
        }

        @-webkit-keyframes flip {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {transform: scaleX(-1);}
        }

        @keyframes flip {
          0%, 10%, 25%, 40%, 55%, 80%, 100% {transform: scaleX(-1);}
        }

        @-moz-keyframes rotateAnim {
          from {
            -moz-transform: rotate(0);
          }
          to {
            -moz-transform: rotate(1turn);
          }
        }
        @-webkit-keyframes rotateAnim {
          from {
            -webkit-transform: rotate(0);
          }
          to {
            -webkit-transform: rotate(1turn);
          }
        }
        @keyframes rotateAnim {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(1turn);
          }
        }
      }
  `
    );
  }}
`;
