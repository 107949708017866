import React, { useCallback, useState, ChangeEvent } from 'react';
import AppButton from '../../components/button/button';
import DividerGrid from '../../components/divider_grid/divider_grid';
import { Quantity } from '../../components/divider_grid/divider_grid.type';
import Input from '../../components/inputs/input';
import PageTitle from "../../components/page's_title/page-title";
import KeyIcon from '../../assets/menu_icons/key-icon.svg';
import { Div } from './index.style';
import SubTitulo1 from '../../components/fonts_components/sub-headers/subtitulo1';
import { layout } from '../../themes/layout';
import { assistanceSchema } from './alreração-senha.schema';
import { useAuth } from '../../Contexts/AuthContext';
import { ValidationError } from 'yup';
import NextStepIcon from '../../components/button/buttons_icons_animations/next-step';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';
import { useNavigate } from 'react-router-dom';

export function PasswordChange() {
  const { updatePassword, user } = useAuth()

  const [ loading, setLoading ] = useState(false)
  const [ worked, setWorked ] = useState('')
  const [error, setError] = useState('');
  const [eyesOld, setEyesOld] = useState(false);
  const [eyesNew, setEyesNew] = useState(false);
  const [eyesConfirm, setEyesConfirm] = useState(false);

  const navigate = useNavigate()

  const [data, setData] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setError('');
      setData((prevData) => ({
        ...prevData,
        [target.name]: target.value,
      }));
    },
    [setData],
  );

  const handleSubmit = async() =>{
    setLoading(true)
    try {
      await assistanceSchema.validate(data);
      await updatePassword(user.email, data.new_password)
      setWorked('Senha alterada com sucesso.')
      setLoading(false)
      setTimeout(()=> {
        navigate('/')
      }, 3000)

    } catch (error) {
      setLoading(false);
      if(error instanceof ValidationError){
        setError(error.errors[0])
        setLoading(false)
      }
      if(error instanceof Error && error.message === 'Erro ao tentar alterar senha.'){
        setError('Erro ao tentar alterar senha.')
        setLoading(false)
      }
    }
  }

  return (
    <>
      <DividerGrid quantity={Quantity.three}>
        <PageTitle>
          <span>Alteração de Senha</span>
          <img src={KeyIcon} alt="ícone de uma chave" />
        </PageTitle>

        <Div>
          <SubTitulo1 color={layout.color.complementares.gray_600}>
            Para a sua segurança, é recomendado que você cadastre uma senha
            personalizada.
          </SubTitulo1>
          <Input
            type={eyesOld ? 'text' : 'password'}
            name="old_password"
            placeholder="Senha atual"
            onChange={handleChange}
            icon={<img src={KeyIcon} alt="ícone de uma chave" />}
            errorText={''}
            eyes={eyesOld}
            setEyes={setEyesOld}
          />
          <Input
            type={eyesNew ? 'text' : 'password'}
            name="new_password"
            placeholder="Nova senha"
            onChange={handleChange}
            icon={<img src={KeyIcon} alt="ícone de uma chave" />}
            errorText={''}
            eyes={eyesNew}
            setEyes={setEyesNew}
          />
          <Input
            type={eyesConfirm ? 'text' : 'password'}
            name="confirm_new_password"
            placeholder="Repita a nova senha"
            onChange={handleChange}
            icon={<img src={KeyIcon} alt="ícone de uma chave" />}
            errorText={''}
            eyes={eyesConfirm}
            setEyes={setEyesConfirm}
          />
          {error ? <p style={{color:'#d00020', padding: '10px 0'}}>{error}</p> : null}
          {worked ? <p style={{color:'#48af77', padding: '10px 0'}}>{worked}</p> : null}


          <span className="instructions">
          A senha precisa ter no mínimo 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caracter especial.
          </span>

          {loading ? <span><LoadingIcon/></span> : null}

          <AppButton
            text="enviar"
            onClick={handleSubmit}
          >
            <NextStepIcon />
          </AppButton>
        </Div>
      </DividerGrid>
    </>
  );
}
