import styled from 'styled-components';

export const FormGrid = styled.div`
  grid-area: auto / 1 / auto/ 3;
  width: 100%;
  box-sizing: border-box;
  display: grid;

  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;

  gap: 0px 4%;

  .logradouro {
    grid-column: 1 /4;
  }
  .numero {
    grid-column: 4 /5;
  }
  .complemento {
    grid-column: 5 /9;
  }
  .bairro {
    grid-column: 1 /5;
  }
  .cidade {
    grid-column: 5 /9;
  }
  .estado {
    grid-column: 1 /3;
  }
  .cep {
    grid-column: 3 /5;
  }
  .pais {
    grid-column: 5 /9;
  }

  .telresidencial{
    grid-column: 1 /5;
  }
    .telcomercial{
    grid-column: 1 /5;
  }
    .telcelular{
    grid-column: 1 /5;
  }
    .email{
    grid-column: 5 /9;
    grid-row: 1/2;
  }
    .observacao{
    grid-column: 1 /5;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    .logradouro,
    .numero,
    .complemento,
    .bairro,
    .cidade,
    .estado,
    .cep,
    .pais,
    .telresidencial,
    .telcomercial,
    .telcelular,
    .email,
    .observacao {
      grid-column: 1/2;
    }

  }
`;
