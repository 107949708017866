import styled from 'styled-components';
import { TCheckBox, TLabel, TSelect } from './input.type';
import arrowDown from '../../assets/buttons-icons/arrow-down.png';
import { ChangeEvent } from "react";

export const InputStyled = styled.input`
  width: 100%;
  height: 30px;
  background: transparent;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.layout.text.large};
  line-height: 22px;
  font-weight: 500;
  color: ${(props) => props.theme.layout.color.complementares.gray_600};
`;

export const LabelStyled = styled.label<TLabel>`
  ${(props) => {
    const {
      theme: {
        layout: { color, text },
      },
    } = props;

    return `
    display: flex;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid ${
      props.errorText ? color.alert.red_alert : color.complementares.gray_400
    };
    padding: 15px 8px 8px;
    position: relative;
    margin-bottom: 30px;
    background-color: transparent;
    svg{
      margin-right: 18px;
      color: ${
        props.errorText !== ''
          ? color.alert.red_alert
          : color.main_colors.blue_300
      };
      font-size: 20px;
    }
    p.label{
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${text.xSmall};
      line-height: 10px;
      color: ${color.complementares.green};
    }
    p.errorText{
      position: absolute;
      bottom: -16px;
      ${text.small};
      line-height: 14px;
      color: ${color.alert.red_alert};
    }
    img{
      margin-right: 18px;
    }

    `;
  }}
`;

export const SelectStyled = styled.select<Partial<TSelect>>`
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.layout.text.large};
  line-height: 22px;
  font-weight: 500;
  color: ${(props) => props.theme.layout.color.complementares.gray_600};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${arrowDown});
  background-position: right 0px top 50%;
  background-repeat: no-repeat;
  background-color: transparent ;
`;



export const CheckboxContainer = styled.div`
  height: auto;
  padding-left: 1px;
  //margin: 0px 4px;
  display: flex;
  align-items: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const TextCheckBox = styled.span<TCheckBox>`
  color: ${(props): string =>
    props.error
      ? props.theme.layout.color.alert.red_alert
      : props.theme.layout.color.complementares.gray_600};
  font-size: 0.8rem;
  line-height: 1.2rem;
  display: inline-block;
  margin-left: 5px;

  a {
    text-decoration: none;
    transition: all 0.4s ease;
    color: ${(props) => props.theme.layout.color.main_colors.blue_300};

    :visited {
      color: ${(props) => props.theme.layout.color.main_colors.blue_300};
    }

    :hover {
      font-weight: 600;
    }
  }
`;

export const StyledCheckbox = styled.label<TCheckBox>`
  width: 1rem;
  height: 1rem;
  border-radius: 0 3px;

  border: 1.5px solid
    ${(props) => props.theme.layout.color.complementares.gray_400};
  margin-right: 6px;
  background: #f6f6f6;
  display: flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 0;

  img {
    display: ${(props) => (props.checked ? 'flex' : 'none')};
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 180px;
  margin: 0 -8px;
  padding: 8px;
  border: 1.5px solid
    ${(props) => props.theme.layout.color.complementares.gray_400};
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  :focus {
    outline: none;
  }
  border-bottom: none;
`;

export const LabelRadio = styled.label`
  display: grid;
  /* width: fit-content;
  min-width: 100%; */
  position: relative;
  padding: 10px 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;

  grid-template-columns: minmax(110px, 1fr) minmax(100px, 1fr) minmax(60px, 1fr);
  column-gap: 10px;
  p {
    font-size: 0.75rem;
    color: white;
    line-height: 1.06rem;
    position: relative;
    z-index: 2;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span {
    background: transparent
      linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, #ffffff00 100%) 0% 0%
      no-repeat padding-box;
  }

  input:checked ~ span {
    box-sizing: border-box;
    background: transparent
      linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, #ffffff00 100%) 0% 0%
      no-repeat padding-box;
    border-right: 5px solid
      ${(props) => props.theme.layout.color.main_colors.blue_300};
  }
`;

export const CheckmarkRadio = styled.span`
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 0;

  transition: all 0.3s ease;
`;

export const HeaderTable = styled.div`
  display: grid;
  position: relative;
  padding: 10px 2px 15px;
  margin: 0 18px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;

  grid-template-columns: minmax(110px, 1fr) minmax(100px, 1fr) minmax(60px, 1fr);
  column-gap: 10px;

  p.header {
    font-size: 0.75rem;
    color: white;
    line-height: 1.06rem;
    font-weight: 500;
    position: relative;
    z-index: 2;
  }

  /* @media screen and (max-width: 768px){
    width: fit-content;
    min-width: 100%;
  } */
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: auto;
  overflow-x: auto;
`;

export const DatepickerToggleButton = styled.span<{ img: string }>`
  position: relative;
  left: 0;
  top: 0;
  background-image: url(${(props) => props.img});
`;

export const DateInvisible = styled.input`
  position: absolute;
  left: 42px;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 3;

  ::-webkit-calendar-picker-indicator {
    position: absolute;
    z-index: 3;
    left: -42px;
    top: 0;
    width: 50%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

export const ButtonEye = styled.button`
  display: inline-block;
`;
