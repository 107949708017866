import styled from 'styled-components';

export const AdmAcessBadgetStyled = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 5.4% 10px 20px;
  background-color: ${props => props.theme.layout.color.alert.red_alert};
  color: #fff;
  border-radius: 0 5px;
  ${props => props.theme.layout.text.small};

  .badget {
    position: relative;
    display: flex;
    
    img {
      margin: 0 8px 0 0;
    }
  }
`;
