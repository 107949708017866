import styled from 'styled-components';

export const CardEtapaStyled = styled.div`
width: 100%;
min-width: 57px;
padding: 10px;
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
img {
  width: 75%;
  height: auto;
  margin: 20px auto;

}

background: white;


`

export const Title = styled.span`
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #000000DE;

`
