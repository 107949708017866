import BasicBox from '../box_card/box-card';
import { CardOneStyled } from './card_one.style';
import { Props } from './card_one.type';

export default function CardOne({ children }: Props) {
  return (
    <BasicBox>
      <CardOneStyled>{children}</CardOneStyled>
    </BasicBox>
  );
}
