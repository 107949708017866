import styled from 'styled-components';
import { ItemOnGrid } from '../divider_grid/divider_grid.type';

export const CardOneStyled = styled.div<ItemOnGrid>`
width: 100%;
height: 100%;
display: grid;
grid-template-columns: minmax(36px, 36px) auto;
grid-template-rows: 1fr;
gap: 0 30px;
box-sizing: border-box;
align-items: center;
position: relative;

svg{
 width: 100%;
//  max-width: 50px;
 height: 100%;
//  max-height: 50px;
 color: ${(props) => props.theme.layout.color.main_colors.blue_300};
 grid-row: 1/3;
  // background-color: pink;
  align-self: center;
  justify-self: center;
}

img{
  width: 100%;
  max-width: 36px;
  height: 100%;
  color: ${(props) => props.theme.layout.color.main_colors.blue_300};
  grid-row: 1/3;
  // background-color: pink;
  align-self: center;
  justify-self: center;
  // margin-right: 30px;
 }

 h5 {
  margin:0 0 8px 0;
 }

p{
  font-size: ${(props) => props.theme.layout.text.medium};
  line-height: 17px;
  color: ${(props) => props.theme.layout.color.complementares.gray_800};
  // background-color: orange;
}

.boletosAlert {
  position: absolute;
  border-radius: 50%;
  background-color: #B00020;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10% 0 0 8%;

  span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
.loadingAlert {
  position: absolute;
  border-radius: 50%;
  background-color: #F4F4F3;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10% 0 0 8%;

  span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
  }
}


@media screen and (max-width: 768px){
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  justify-items: center;

  svg{
    grid-row: 1/2;
  }

  h5{
    margin:6px 0 8px 0;
    text-align: center;
  }

  p{
    font-size: ${(props) => props.theme.layout.text.small};
    color: ${(props) => props.theme.layout.color.complementares.gray_600};
    text-align: center;
  }

  .boletosAlert {
   margin: -105px 0 0 42px;
  }
}
@media screen and (max-width: 460px){
  .boletosAlert {
   margin: -130px 0 0 42px;
  }
}

`
