import * as yup from 'yup';

const assistanceSchema = yup.object().shape({
  old_password: yup.string().required('Senha atual obrigatória.'),
  new_password: yup.string().required('Senha obrigatória.').min(6, 'Mínimo de 6 caracteres.').matches(
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{6,}$).*$/,
    'A senha precisa ter no mínimo 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caracter especial.'
),
  confirm_new_password: yup.string()
  .oneOf([yup.ref('new_password'), null], 'Senhas não coincidem.'),
});

export { assistanceSchema };