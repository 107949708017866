import { ReactNode } from 'react';
import { SubTitleOneStyle } from './subtitle-one.style';

type Props = {
  children: ReactNode
}

export default function SubtitleOne({children}: Props){
  return(
    <SubTitleOneStyle>
      {children}
    </SubTitleOneStyle>
  )
}
