// imports defaults
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

// imports libs
import { AxiosError } from 'axios';
import * as yup from 'yup';
// import emailjs from '@emailjs/browser';

// imports components
import AppButton from '../../components/button/button';
import Input from '../../components/inputs/input';
import PageTitle from "../../components/page's_title/page-title";
import {
  ContentTextStyled,
  LoginLayoutStyled,
} from '../login/login.style';
import { primeiroAcessoSchema } from './primeiro_acesso.schema';

// imports icons and images
import ArrowLogin from '../../assets/buttons-icons/arrow-login.svg';
import soter_logo from '../../assets/logo_login_branca.svg';
import soterLogoMobile from '../../assets/logo_mobile.svg';
import lineLogin from '../../assets/line-login.svg';
import { EmailLogin } from '../../assets/login_icons/index';
import axiosInstance from '../../module/axios.module';
import LoadingIcon from '../../components/button/buttons_icons_animations/loading';

interface SignInData {
  email: string;
}

export function PrimeiroAcesso() {
  const [loading, setLoading] = useState(false);

  const { firstAccess } = useAuth();

  const [data, setData] = useState<SignInData>({
    email: '',
  });

  const form = useRef<HTMLFormElement>(null);

  const [error, setError] = useState('');
  const [error2, setError2] = useState('');
  const [worked, setWorked] = useState('');

  const navigate = useNavigate();

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setError('');
      setError2('');
      setWorked('');
      setData((prevData) => ({
        ...prevData,
        [target.name]: target.value,
      }));
    },
    [setData],
  );

  const sendEmail = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setWorked('');
    try {
      const validated = await primeiroAcessoSchema.validate(data);
      const _senha = await firstAccess(data.email);

      const newBody = { email: validated?.email, senha: _senha?.data };

      const res = await axiosInstance.post(
        'https://soter-six.vercel.app/api/cliente/primeiroAcesso',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newBody),
        },
      );

      setLoading(false);
      if (res.status === 200) {
        setWorked('A senha temporária foi enviada para o email de cadastro.');
        return setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof yup.ValidationError) {
        setError(error.errors[0]);
      }
      if (error instanceof AxiosError) {
        return setError2(
          `Erro ao enviar nova senha para o e-mail indicado. Por favor entre em contato com o e-mail contato@soter.com.br`,
        );
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      navigate('/home');
    }
  }, []);

  return (
    <LoginLayoutStyled>
      <form ref={form}>
        <img
          width="173"
          className="logomobile"
          src={soterLogoMobile}
          alt="logo"
        />
        <div className="space" style={{ marginTop: '100px' }}></div>
        <div>
          <PageTitle> Primeiro acesso / Esqueci a senha </PageTitle>
          <p className="primeiroInst">
            Digite o e-mail de cadastro e uma senha temporária será enviada para
            você.
          </p>
          <Input
            type={'email'}
            name="email"
            onChange={handleChange}
            placeholder="Email"
            icon={<img src={EmailLogin} alt="login" />}
            errorText={error}
            editable
          ></Input>
          <div style={{ height: '30px' }}>
            {loading ? <LoadingIcon /> : null}
          </div>
          <p style={{ fontSize: '13px', color: '#d00020', padding: '10px 0' }}>
            {error2 ? (
              <>
                <span style={{ fontWeight: '700' }}>X</span> {error2}
              </>
            ) : null}
          </p>
          {worked ? (
            <p style={{ fontSize: '13px', color: '#48af77', padding: '10px 0' }}>{worked}</p>
          ) : null}
          <div className="button" style={{ margin: '40px 0 0 0' }}>
            <AppButton onClick={(e) => sendEmail(e)} login text="enviar">
              <img src={ArrowLogin} alt="ícone" />
            </AppButton>
          </div>
        </div>
        <p className="acessoSenha">
          <Link to={'/login'}>Volte para o login</Link>
        </p>
      </form>
      <div className="right">
        <img className="lineLogin" src={lineLogin} />
        <img className="logoDesktop" src={soter_logo} alt="logo" />
        <ContentTextStyled>
          <p>
            Bem-vindo ao <span className="destaque">Meu Soter.</span>
          </p>
          <p>
            Esse canal foi desenvolvido para estarmos cada vez mais próximos de
            vocês, nossos clientes.
          </p>
          <p>
            Por aqui, buscamos agilizar o atendimento, estruturar, modernizar e
            aperfeiçoar cada vez mais nossos processos e meios de comunicação.
          </p>
        </ContentTextStyled>
      </div>
    </LoginLayoutStyled>
  );
}
