import React from 'react';
import { CheckmarkRadio, LabelRadio } from './input.style';
import { TRadioUnit } from './input.type';

export default function RadioUnits({
  children,
  name,
  value,
  onChange,
}: TRadioUnit) {
  return (
    <LabelRadio>
        <input
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
        />
      {children}
      {/* <div style={{position: "relative"}}> */}

      <CheckmarkRadio></CheckmarkRadio>
      {/* </div> */}
    </LabelRadio>
  );
}
