import { TableFrame, TableStyled } from './table.style';
import { Props } from './table.type';

import Hand from '../../assets/hand.svg';
import ArrowHand from '../../assets/arrow_hand.svg';

export default function Table({
  headers,
  values,
  footers,
  blue = false,
  download = false,
}: Props) {
  return (
    <TableFrame>
      <TableStyled
        headers={headers}
        values={values}
        blue={blue}
        download={download}
        length={headers.length}
      >
        <>
          <tbody>
            <tr className="headersRow ">
              {headers.map((e, i) => (
                <th key={i}>{e}</th>
              ))}
            </tr>

            {values.map((conj, i) => (
              <tr key={i}>
                {conj.map((e, j) => (
                  <td key={j}>
                    <>{e}</>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {footers ? (
            <tfoot>
              <tr>
                {footers.map((e, i) => (
                  <td key={i}>{e}</td>
                ))}
              </tr>
            </tfoot>
          ) : null}
        </>
      </TableStyled>
      <div className="boxHandAnimation">
        <div className="boxHandAnimationIlus">
          <img className="arrowHand" src={ArrowHand} alt="table" />
          <img className="handAnimation" src={Hand} alt="table" />
        </div>
        <div className="boxHandAnimationText">
          <p>
            Deslize para <br /> navegar na tabela
          </p>
        </div>
      </div>
    </TableFrame>
  );
}
